import React, {useContext, useEffect, useState} from "react";
import "../../styles/common.css";
import "../../../node_modules/animate.css/animate.css";
import {Button, Toast} from "react-bootstrap";
import {copyOnClipboard, getContentImgSrc, getProfileImgSrc} from "../utils";
import RootContext from "./RootContext";
import {useParams} from "react-router-dom";

function Share() {
  // 공유하기
  const [showC, setShowC] = useState(false);
  const [title, setTitle] = useState('');
  const toggleShowC = (e) => {
    e.stopPropagation();
    setShowC(!showC);
  }
  const {agent, agentMultiProfiles, selectedNewsMainImage, selectedNewsTitle} = useContext(RootContext)

  const {encodedAgentNumber} = useParams();

  useEffect(() => {
    if (agent.basicInfo.businessChannelDivisionCode === '06') {
      setTitle(`메트라이프금융서비스 ${agent.basicInfo.employeeName}님의 디지털 프로필입니다.`);
    } else if (agent.basicInfo.businessChannelDivisionCode === '02') {
      setTitle(`메트라이프생명 GA 채널 ${agent.basicInfo.employeeName}님의 프로필입니다.`)
    } else {
      setTitle(`오늘부터, 메트라이프\n${agent.basicInfo.employeeName}님의 디지털 프로필입니다.`);
    }
  }, [agent]);

  const shareWithKakao = () => {

    let imgUrl;
    let description;
    const isNews = window.location.href.indexOf('/news') > -1;
    const isJobTendencyTest = window.location.href.indexOf('/fsr-job-tendency') > -1;

    if (isNews) {
      imgUrl = getContentImgSrc(selectedNewsMainImage);
      description = selectedNewsTitle;
    } else {
      const {financialConsultantChannelCode, businessPositionTypeCode} = agent?.crossAgentInfo;

      //[멀티 프로필 추가]카카오 공유하기
      //시즈널 멀티 프로필이 있는 경우에는 첫번째 화면을 공유한다.
      let fileName = "";

      if (agentMultiProfiles && 0 < agentMultiProfiles.length) {
        fileName = agentMultiProfiles?.reduce((min, current) => {
            return current?.sortSequenceNumber < min?.sortSequenceNumber ? current : min;
          },
          agentMultiProfiles[0])?.multiProfileStoredImageFileName;

        if (!fileName) {
          fileName = process.env.REACT_APP_KAKAO_IMGAE_PATH;
        }
      } else {
        fileName = agent.profile?.mainStoredImageFileName ? agent.profile.mainStoredImageFileName : process.env.REACT_APP_KAKAO_IMGAE_PATH;
      }

      if (!Object.is(process.env.REACT_APP_KAKAO_IMGAE_PATH, fileName)) {
        imgUrl = getProfileImgSrc(fileName);
      } else {
        imgUrl = fileName;
      }


      console.log("fileName : ", fileName, " / imgUrl : ", imgUrl);


      if (agent.basicInfo.businessChannelDivisionCode === '02') {
        description = '#오늘부터 #메트라이프';
      } else {
        description = '#Met-ON #디지털프로필 #상담신청 #이벤트';
      }

      // 설계사 채널 구분코드가 '0111' 이고 직책 구분코드가 '1010' 인 경우 표시
      if (Object.is('0111', financialConsultantChannelCode)
        && Object.is('1010', businessPositionTypeCode)) {
        description = description.concat(' #교차설계사');
      }
    }

    if (isJobTendencyTest) {
      window.Kakao.Share.sendCustom({
        templateId: Number(process.env.REACT_APP_JOB_TEST_START),
        templateArgs: {
          title,
          agentUrl: encodedAgentNumber
        }
      })
    } else {
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title,
          description: description,
          imageUrl: imgUrl,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          }
        },
        buttonTitle: '웹으로 보기'
      });
    }


  }

  return (
    <div className="share_area" onClick={(e) => e.stopPropagation()}>
      <Button
        className="share"
        bsPrefix="string"
        onClick={toggleShowC}
      />
      <Toast
        onClose={toggleShowC}
        show={showC}
        animation={false}
        className="share_pop"
      >
        <Toast.Header>

          <strong className="me-auto">공유하기</strong>
        </Toast.Header>
        <Toast.Body>
          <button className="share_icon share_icon01" onClick={shareWithKakao}>카카오톡</button>
          <button className="share_icon share_icon02"
                  onClick={() => window.open('http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href))}>페이스북
          </button>
          <button className="share_icon share_icon03"
                  onClick={() => copyOnClipboard(window.location.href, 'URL 주소')}>URL 복사
          </button>
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default Share;
