import UI_10_01_Start from "./UI_10_01_Start";
import Carousel from "react-bootstrap/Carousel";
import React, {useEffect, useMemo, useRef, useState} from "react";
import UI_10_01_GenderSelect from "./UI_10_01_GenderSelect";
import UI_10_01_Question from "./UI_10_01_Question";
import {useNavigate} from "react-router-dom";
import {tendencyPriority} from './TendencyPriorityData';

const UI_10_01 = ({trackingMenu}) => {

    const navigate = useNavigate();

    const carouselRef = useRef();

    const [surveyState, setSurveyState] = useState({
        step: 'start', // start, gender, question
        gender: '',
        survey: [null, null, null, null, null]
    });

    const questions = useMemo(() => [
        {
            index: 0,
            imgSrc: require(`../../assets/images/job/${surveyState.gender === 'M' ? 'man' : 'woman'}_1.png`),
        },
        {
            index: 1,
            imgSrc: require(`../../assets/images/job/${surveyState.gender === 'M' ? 'man' : 'woman'}_2.png`),
        },
        {
            index: 2,
            imgSrc: require(`../../assets/images/job/${surveyState.gender === 'M' ? 'man' : 'woman'}_3.png`),
        },
        {
            index: 3,
            imgSrc: require(`../../assets/images/job/${surveyState.gender === 'M' ? 'man' : 'woman'}_4.png`),
        },
        {
            index: 4,
            imgSrc: require(`../../assets/images/job/${surveyState.gender === 'M' ? 'man' : 'woman'}_5.png`),
        }
    ], [surveyState]);

    useEffect(() => {
        trackingMenu('A0000043');
    }, []);

    const validate = () => {
        const nullIndex = surveyState.survey.indexOf(null);

        if (nullIndex === -1) {
            return true;
        } else {
            alert(`${nullIndex + 1} 번째 문항을 선택하지 않았습니다.`)
            return false;
        }
    }

    const decideTendency = () => {
        if (surveyState.survey.every(value => value === 1)) {
            return '02';
        }

        const maxValue = Math.max(...surveyState.survey);

        const indexes = surveyState.survey.reduce((prev, cur, idx) => cur === maxValue? [...prev, idx] :  prev, [] )
        const indexesString = indexes.join()

        return tendencyPriority[indexesString];
    }

    const handleClickStart = () => {
        setSurveyState((prev) => ({...prev, step: 'question'}))
    }

    const handleClickGender = (gender) => {
        setSurveyState((prev) => ({...prev, gender: gender}))
        carouselRef.current.next();
    }

    const handleClickSurveyButton = (index, number) => {
        setSurveyState((prev) => ({...prev, survey: prev.survey.fill(number, index, index + 1)}))
        if (questions.length > index + 1) {
            carouselRef.current.next();
        } else {
            const success = validate();
            if (success) {
                const resultCode = decideTendency();
                navigate(`result?resultCode=${resultCode}&gender=${surveyState.gender}`);
            }
        }
    }

    const renderPage = () => {
        switch (surveyState.step) {
            case 'start': return (<UI_10_01_Start onClickStart={handleClickStart}/>);
            case 'question': return (renderCarousel());
            default: return null;
        }
    }

    const renderCarousel = () => {
        return (
            <Carousel ref={carouselRef} interval={null} className='today survey-carousel' controls={false}>
                <Carousel.Item>
                    <UI_10_01_GenderSelect gender={surveyState.gender} onClickGender={handleClickGender}/>
                </Carousel.Item>
                {questions.map((question) =>
                    <Carousel.Item>
                        <UI_10_01_Question value={surveyState.survey[question.index]} src={question.imgSrc} onClickButton={(value) => handleClickSurveyButton(question.index, value)}/>
                    </Carousel.Item>)}
            </Carousel>
        );
    }

    return renderPage();
}

export default UI_10_01;