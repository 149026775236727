import {Link} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";
import {formatDate, getContentImgSrc} from "../../common";
import React from "react";

const UI_01_01_MetlifeNews = ({title, contents, category = ""}) => {

    const link = category ? `news?category=${category}` : 'news';

    return (
        contents.length > 0 && (
            <div className="news news_main">
                <h2>{title}</h2>
                <Link to={link} className="more">
                    <img src={require("../../assets/images/more.gif")} alt=""/>
                </Link>
                <Row xs={2} md={2} className="g-4 news news_all">
                    {contents.map((content, idx) => (
                            content.viewTypeText === 'CARD' ?
                                <Link to={`news/card/${content.contentId}`} key={`content-link-${idx}`}>
                                    <Col key={`content-col-${idx}`}>
                                        <Card>
                                            <Card.Img
                                                variant="top"
                                                src={getContentImgSrc(content.thumbnailStoredImageFileName)}
                                                className="content-card-image"
                                            />
                                            <Card.Body>
                                                <Card.Text>{formatDate(content.contentStartDate, 'yyyy.MM.DD')}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <strong>{content.titleText}</strong>
                                        {/*<span>조회 : {content.readCount}</span>*/}
                                    </Col>
                                </Link>
                                :
                                <Link to={`news/newsletter/${content.contentId}`} key={`content-link-${idx}`}>
                                    <Col>
                                        <Card>
                                            <Card.Img
                                                variant="top"
                                                src={getContentImgSrc(content.thumbnailStoredImageFileName)}
                                                className="content-card-image"
                                            />
                                            <Card.Body>
                                                <Card.Text>{formatDate(content.contentStartDate, 'yyyy.MM.DD')}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <strong>{content.titleText}</strong>
                                        {/*<span>조회 : {content.readCount}</span>*/}
                                    </Col>
                                </Link>
                        )
                    )}
                </Row>
            </div>)
    )
}

export default UI_01_01_MetlifeNews;