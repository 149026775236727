import React, {useContext, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {AgentChecker, FetchApi, getDeviceType, MetOnModal, RootContext} from "./common";
import {UI_01_01} from "./ui_01";
import {UI_02_01} from "./ui_02";
import {UI_03_01} from "./ui_03";
import {UI_06_01, UI_06_02} from "./ui_06";
import {UI_07_01, UI_07_02_01, UI_07_02_02, UI_07_05} from "./ui_07";
import {UI_08_01} from "./ui_08";
import {
  UI_09_01,
  UI_09_02,
  UI_09_03_404,
  UI_09_03_500,
  UI_09_03_AgentNotFound,
  UI_09_03_Maintenance,
  UI_09_04
} from "./ui_09";
import {UI_10_01, UI_10_01_Result} from "./ui_10";

const RootLayout = function () {
  const navigate = useNavigate();
  const {encodedAgentNumberForRoot, menuMap} = useContext(RootContext);

  // 재정전문가-알아보기/신청하기 구분자
  const [key, setKey] = useState("profile");
  const [isCertificated, setIsCertificated] = useState(false);

  //메뉴 트래킹
  const trackingMenu = (code) => {
    if (menuMap.has(code)) {
      FetchApi.post('/api/meton/channel/menu-tracking', {
        menuId: menuMap.get(code),
        mobileOsText: getDeviceType(),
        urlText: window.location.href,
      });
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/:encodedAgentNumber"
          element={
            <>
              {/* AgentId 유효성 검사 및 전역화 */}
              <AgentChecker
                setIsCertificated={setIsCertificated}
              />
              {/*{isCertificated && (*/}
                <>
                  {/* 플로팅 버튼 */}
                  <UI_09_02/>
                  {/* 전체 메뉴 */}
                  <UI_09_01
                    setKey={setKey}
                  />
                  <div className="met_contents">
                    <Outlet/>
                  </div>
                  {/* Bottom */}
                  <UI_09_04/>
                </>
              {/*)}*/}
            </>
          }
        >
          {/*{isCertificated &&*/}
            <>
              {/* 메인 화면 */}
              <Route
                path=""
                element={<UI_01_01/>}
              />

              {/* 프로필 */}
              <Route
                path="profile"
                element={
                  <UI_02_01
                    trackingMenu={trackingMenu}
                  />
                }
              />
              {/* 상담신청 */}
              <Route
                path="counsel"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}`)}
                    title="상담신청"
                    body={<UI_03_01
                      trackingMenu={trackingMenu}
                    />}
                  />
                }
              />

              {/* 메트라이프 소식 */}
              <Route
                path="news"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}`)}
                    title="메트라이프 소식"
                    body={
                      <UI_07_01
                        trackingMenu={trackingMenu}
                      />
                    }
                  />
                }
              />

              {/* 뉴스레터 */}
              <Route
                path="news/newsletter/:contentId"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}/news`)}
                    title="메트라이프 소식"
                    body={<UI_07_02_02/>}
                    className="today"
                  />
                }
              />

              {/* 오늘의 Met세지 */}
              <Route
                path="news/card/:contentId"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}/news`)}
                    title="메트라이프 소식"
                    body={
                      <>
                        <p className="swipe fade-out">
                          <strong>좌우로 스와이프 하세요.</strong>
                        </p>
                        <UI_07_02_01/>
                      </>
                    }
                    className="today"
                  />
                }
              />

              {/*Global Sales Professional*/}
              <Route
                path="globalsalesprofessional"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}`)}
                    title="Global Sales Professional"
                    body={
                      <UI_07_05
                        trackingMenu={trackingMenu}
                      />
                    }
                    className="today"
                  />
                }
              />

              {/* 재정전문가 JOB 성향 테스트 */}
              <Route
                path="fsr-job-tendency"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}`)}
                    title="재정전문가 JOB 성향 테스트"
                    body={
                      <UI_10_01
                        trackingMenu={trackingMenu}
                      />
                    }
                  />
                }
              >

              </Route>

              <Route
                path="fsr-job-tendency/result"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}`)}
                    title="재정전문가 JOB 성향 테스트"
                    body={
                      <UI_10_01_Result/>
                    }
                  />
                }
              />

              {/* FSR 알아보기 */}
              <Route
                path="fsr"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}`)}
                    title="재정전문가(FSR) 모집"
                    body={
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        bsPrefix="string"
                        className="met_tab"
                      >
                        <Tab
                          eventKey="profile"
                          title="신청하기"
                          className="met_tab-content"
                          mountOnEnter
                        >
                          <UI_06_02
                            trackingMenu={trackingMenu}
                          />
                        </Tab>
                        <Tab eventKey="home" title="알아보기">
                          <UI_06_01
                            trackingMenu={trackingMenu}
                          />
                        </Tab>
                      </Tabs>
                    }
                  />
                }
              />

              {/* 진행 중 이벤트 */}
              <Route
                path="event"
                element={
                  <MetOnModal
                    show
                    setShow={() => navigate(`/${encodedAgentNumberForRoot}`)}
                    title="진행 중 이벤트"
                    body={<UI_08_01
                      trackingMenu={trackingMenu}
                    />}
                  />
                }
              />
            </>
          {/*}*/}
          <Route path="error_500" element={<UI_09_03_500/>}/>
        </Route>
        <Route path="/error_500" element={<UI_09_03_500/>}/>
        <Route path="/error_maintenance" element={<UI_09_03_Maintenance/>}/>
        <Route path="/" element={<UI_09_03_AgentNotFound/>}/>
        <Route path="*" element={<UI_09_03_404/>}/>
      </Routes>
    </>
  );
}

export default RootLayout;