import React from "react";
import "../../styles/common.css";

const UI_09_04_404 = function () {
    return (
        <div className="error">
            <div className="ebox ebox01">
                <strong>페이지를 찾을 수 없습니다.</strong>
                <span>
          페이지 주소가 잘못 입력 되었거나 요청하신 페이지 주소가 변경 혹은
          삭제되어 페이지를 찾을 수 없습니다.
          <br/>
          <br/>
          입력하신 주소가 정확한지 한 번 더 확인해 주세요.
        </span>
            </div>
        </div>
    );
}

export default UI_09_04_404;
