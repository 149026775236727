import React from "react";

const UI_10_01_Question = ({value, src, onClickButton}) => {

    return (
        <>
            <div className="job">
                <div className="jobbox">
                    <ul>
                        <li className="jonimage">
                            <img
                                src={src}
                                alt="남자 질문1) 불필요한 일에 낭비할 시간은 없다.핵심업무만 신속하게 처리한다"
                            />
                        </li>
                        <li className="circleB">
                            <button className={`qbtn01 ${value === 1? 'on': ''}`} onClick={() => onClickButton(1)}>저는 아닙니다</button>
                            <button className={`qbtn02 ${value === 2? 'on': ''}`} onClick={() => onClickButton(2)}>저랑 비슷합니다</button>
                            <button className={`qbtn03 ${value === 3? 'on': ''}`} onClick={() => onClickButton(3)}>바로 저입니다</button>
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )
}



export default UI_10_01_Question;

