import {useParams } from "react-router-dom";
import {Button, Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState, useContext} from "react";
import {FetchApi, formatDate, getBannerImgSrc, RootContext} from "../../common";

const UI_08_01 = function ({trackingMenu}) {

    const [banners, setBanners] = useState([]);
    const [metadata, setMetadata] = useState({offset: 0, limit: 10, total: 0});
    const [hidden, setHidden] = useState(false);
    const [searchOption, setSearchOption] = useState({
        bannerId: '',
        bannerKindText: '',
        offset: 0,
        limit: 10,
        sort: 'sortSequenceNumber:asc,createTimestamp:desc',
        dateValidYn: 'Y',
        pagingYn: 'Y'
    });

    const { encodedAgentNumber } = useParams();
    const { agent } = useContext(RootContext);
    const [encryptedAgentNumber, setEncryptedAgentNumber] = useState('');

    useEffect(() => {
        trackingMenu('A0000070');
        retrieveIngBanners();
        retrieveMd5Encoded();
    }, []);

    useEffect(() => handleSetIsAppend(), [metadata])

    const retrieveIngBanners = async () => {
        const result = await FetchApi.get('/api/meton/channel/ing-banner', searchOption, {})
        setBanners([...banners, ...result.item]);
        setSearchOption(Object.assign(searchOption, {offset: result.metadata.offset + 1}))
        setMetadata(result.metadata)
    }

    const retrieveBannerById = async (bannerId) => {
        await FetchApi.get(`/api/meton/channel/banner/${bannerId}`)
    }

    const handleSetIsAppend = () => {
        if (metadata.total === null || metadata.total > ((metadata.offset + 1) * metadata.limit)) {
            setHidden(false);
        } else {
            setHidden(true);
        }
    };

    const onClickSearchMore = () => {
        retrieveIngBanners();
    }

    const retrieveMd5Encoded = async () => {
        const { item } = await FetchApi.get(
          `/api/meton/channel/agent-encoding-md5?urlText=${encodedAgentNumber}`
        );
        const agentNumber = item?.encryptedAgentNumber ? item.encryptedAgentNumber : "";
        setEncryptedAgentNumber(agentNumber);
      };

    function handleApplyMainEvent(bannerKindText, linkText){
        const parameter = "?utm_source=meton&utm_medium=&utm_campaign=&utm_term=&utm_content="
        const agentBusinessChannelDivisionCode = agent.basicInfo.businessChannelDivisionCode;

        if(bannerKindText === 'INT'){
            return linkText + encryptedAgentNumber + parameter;
        }else if(bannerKindText === 'SEA'){
            linkText = linkText.replace("##",agentBusinessChannelDivisionCode);
            return linkText + encryptedAgentNumber + parameter ;

        }else{
            
            return linkText;

        }

    }

    return (
        <>
            <p className="total">
                전체 <strong>{metadata.total}</strong>건
            </p>
            <Row xs={1} md={1} className="g-4 event">
                {banners.length > 0 ?banners.map((banner, idx) => (
                    <a href={`${handleApplyMainEvent(banner.bannerKindText, banner.linkText)}`}  
                       onClick={() => retrieveBannerById(banner.bannerId)} 
                       target="_blank"
                       rel="noreferrer" 
                       key={`banner-${idx}`}
                    >
                        <Col >
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={getBannerImgSrc(banner.storedImageFileName)}
                                />
                            </Card>
                            <span>{`[${formatDate(banner.eventStartDate, 'yyyy-MM-DD')} ~${formatDate(banner.eventEndDate, 'yyyy-MM-DD')}]`}</span>
                            <strong>{banner.eventName}</strong>
                        </Col>
                    </a>
                )): <div>진행 중 이벤트가 없습니다.</div>}
            </Row>

            <Button className="button" bsPrefix="string" onClick={onClickSearchMore} hidden={hidden}>
                + 더보기
            </Button>
        </>
    );
};

export default UI_08_01;