import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RootContext} from "../../common";
import {Button} from "react-bootstrap";

const UI_10_01_Result = function () {

    const {encodedAgentNumberForRoot, agent} = useContext(RootContext);

    const navigate = useNavigate();
    const [params] = useSearchParams();

    const gender = params.get('gender');
    const resultCode = params.get('resultCode');

    const [title, setTitle] = useState('');


    useEffect(() => {
        if (agent.basicInfo.businessChannelDivisionCode === '06') {
            setTitle(`메트라이프금융서비스 ${agent.basicInfo.employeeName}님의 디지털 프로필입니다.`);
        } else if (agent.basicInfo.businessChannelDivisionCode === '02') {
            setTitle(`메트라이프생명 GA 채널 ${agent.basicInfo.employeeName}님의 프로필입니다.`)
        } else {
            setTitle(`오늘부터, 메트라이프\n${agent.basicInfo.employeeName}님의 디지털 프로필입니다.`);
        }
    }, [agent]);

    useEffect(() => {

        if (!['M', 'F'].includes(gender) || !['01', '02', '03'].includes(resultCode)) {
            alert('파라미터가 올바르지 않습니다. 재정전문가 JOB 성향 테스트 페이지로 이동합니다.')
            navigate(`/${encodedAgentNumberForRoot}/fsr-job-tendency`);
        }
    }, []);

    const handleClickReTest = () => {
        navigate(`/${encodedAgentNumberForRoot}/fsr-job-tendency`)
    }

    const handleClickFsr = () => {
        navigate(`/${encodedAgentNumberForRoot}/fsr`, {state: {gender, resultCode}})
    }

    const shareWithKakao = () => {

        window.Kakao.Share.sendCustom({
            templateId: Number(process.env[`REACT_APP_JOB_TEST_RESULT_${gender}${resultCode}`]),
            templateArgs: {
                title,
                agentUrl: encodedAgentNumberForRoot
            }
        })
    }

    return (
        <>
            <div className="job">
                <div className="jobbox">
                    <ul>
                        <li className="jonimage">
                            {(['M', 'F'].includes(gender) && ['01', '02', '03'].includes(resultCode)) &&
                            <img
                                src={require(`../../assets/images/job/result_${params.get('gender')}_${params.get('resultCode')}.png`)}
                                alt="이미지"
                            />
                            }
                        </li>
                        <li>
                            <div className="result-btn-area">
                                <Button className="result-btn" variant="outline-primary" size="sm" onClick={handleClickReTest}>테스트 다시하기</Button>
                            </div>
                            <div className="result-btn-area">
                                <Button className="result-btn" variant="outline-primary" size="sm" onClick={handleClickFsr}>FSR 알아보기</Button>
                            </div>
                            <div className="result-btn-area">
                                <Button className="result-btn" variant="outline-primary" size="sm" onClick={shareWithKakao}>카카오톡 공유하기</Button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default UI_10_01_Result;
