import React, { useState } from "react";

const AB = (p) => (
  <button type="button" {...p} className="rtf--ab">
    {p.children}
  </button>
);

export const MB = (p) => (
  <button type="button" className="rtf--mb" {...p}>
    {p.children}
  </button>
);

const defaultPosition = { bottom: 50, right: 24 };

const Fab = ({
  position = defaultPosition,
  alwaysShowTitle = false,
  children,
  icon,
  mainButtonStyles,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => isOpen ? close() : open();

  const actionOnClick = (userFunc) => {
    setIsOpen(false);
    setTimeout(() => {
      userFunc();
    }, 1);
  };

  const rc = () => {
    if (React.Children.count(children) > 6)
      console.warn("react-tiny-fab only supports up to 6 action buttons");
    const ariaHidden = alwaysShowTitle || !isOpen;

    return React.Children.map(
      children,
      (ch, i) =>
        ch && (
          <li className={`rtf--ab__c ${"top" in position ? "top" : ""}`}>
            {React.cloneElement(ch, {
              "data-testid": `action-button-${i}`,
              "aria-label": ch.props.text || `Menu button ${i + 1}`,
              "aria-hidden": ariaHidden,
              ...ch.props,
              onClick: () => actionOnClick(ch.props.onClick),
            })}
            {ch.props.text && (
              <span
                className={`${"right" in position ? "right" : ""} ${
                  alwaysShowTitle ? "always-show" : ""
                }`}
                aria-hidden={ariaHidden}
              >
                {ch.props.text}
              </span>
            )}
          </li>
        )
    );
  };

  return (
    <ul
      onClick={toggle}
      className={`rtf ${isOpen ? "open" : "closed"}`}
      data-testid="fab"
      style={position}
    >
      <li className="rtf--mb__c">
        <MB
          onClick={toggle}
          style={mainButtonStyles}
          data-testid="main-button"
          role="button"
          aria-label="Floating menu"
          tabIndex="0"
        >
          {icon}
        </MB>
        <ul>{rc()}</ul>
      </li>
    </ul>
  );
};

export { Fab, AB as Action };
