import Fade from "react-reveal/Fade";
import React, {useEffect} from "react";

const UI_06_01 = function ({trackingMenu}) {

    useEffect(() => {
        trackingMenu('A0000041');
    }, []);

    return (
        <div className="met_tab-content">
            오늘부터 메트라이프와 함께 전문가의 꿈을 펼쳐나갈 분을
            찾습니다.
            <br/>
            <br/>
            <strong>
                Global Sales Professional, 메트라이프 Agent, FSR에
                도전하세요!
            </strong>
            <br/>
            <br/>
            보험의 영역을 넘어 더 건강한 라이프 솔루션을 제공하는
            메트라이프 Agent, FSR(Financial Service Representative).
            고객들의 수많은 오늘을 더 든든하게 더 행복하게 만들고
            있습니다.
            <br/>
            <br/>
            더 당당하게 서는 오늘을 꿈꾸고 있다면 그 비전과 성공의 시작은
            메트라이프 FSR입니다. 차별화된 지원제도, 성공을 돕는 문화와
            성과에 비례하는 보상과 시스템, 세일즈 역량 강화 및 전문가 양성
            체계적 교육 프로그램, 영업활동 지원을 위한 디지털 플랫폼 등을
            제공하는 메트라이프의 Global Sales Professional, FSR에
            도전하세요.
            <br/>
            {/* 동영상 들어가는 자리 */}
            <div className="video-stream">
                {/*
                todo: webkit-playsinline attribute 없음,
                plays-inline => playsInline
                controls-list => controlsList로 존재하는 attr로 변경
                작동 확인 바람
                */}
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/7c8gme7PPD8" title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                {/*<video*/}
                {/*    src="https://www.youtube.com/watch?v=7c8gme7PPD8"*/}
                {/*    playsInline=""*/}
                {/*    controlsList="nodownload"*/}
                {/*    style={{width: "100%", height: "100%"}}*/}
                {/*/>*/}
            </div>
            <h3>FSR 선발과정</h3>
            <Fade bottom>
                <img src={require("../../assets/images/sub/fsr-mobile.gif")} alt=""/>
            </Fade>
            <h3>문의</h3>
            <div className="dash">
                <li>전화번호 : 02-3469-9864</li>
                <li>
                    이메일 :{" "}
                    <a href="mailto:wchoi1@metlife.com">wchoi1@metlife.com</a>
                </li>
                <li>
                    주소 : 서울특별시 강남구 테헤란로 316 메트라이프타워
                    CA L&D팀
                </li>
                <br/>
                <br/>
                자세한 사항은 유선 문의 또는 “재정전문가(FSR) 모집”을 이용해
                주시기 바랍니다.
            </div>
        </div>
    );
};

export default UI_06_01;