import React from 'react';
import Loading from '../../assets/images/semm_loading.gif'; //loading

export default function Progress(props) {
  const { progresOpen } = props;

  return (
    <>
    {progresOpen &&
      <div className="loading">
       <img src={Loading} alt="Loading..." />
      </div>
    }
    </>
  );
}