import {Link, Outlet, useNavigate, useParams} from "react-router-dom";
import Fade from "react-reveal/Fade";
import React, {useContext, useEffect, useLayoutEffect, useMemo, useState,} from "react";
import {createSemmURL, FetchApi, getBannerImgSrc, RootContext, Share,} from "../../common";
import UI_01_01_MetlifeNews from './UI_01_01_MetlifeNews'
import UI_LD_01_01_MainProfileSlide from "./UI_LD_01_01_MainProfileSlide";


const UI_01_01_CAMFS = function () {
    const navigate = useNavigate();
    const {encodedAgentNumber} = useParams();
    const {agent, agentMultiProfiles} = useContext(RootContext);

    const [semmEvent, setSemmEvent] = useState({});
    const [mainEvents, setMainEvents] = useState([]);
    const [contents, setContents] = useState([]);
    const [metlifeStoryContents, setMetlifeStoryContents] = useState([]);
    const [newYorkContents, setNewYorkContents] = useState([]);
    const [foundationContents, setFoundationContents] = useState([]);
    const [encryptedAgentNumber, setEncryptedAgentNumber] = useState('');
    const [isCrossAgent, setIsCrossAgent] = useState(false); //교차 설계서 여부

    const link = useMemo(() => createSemmURL(agent), [agent])

    useEffect(() => {
        retrieveSemmEvent();
        retrieveMainEvent();
        retrieveMd5Encoded();
        retrieveContentCategories().then((categories) => {
            retrieveContent('', setContents);

            for (const {categoryId, categoryName} of categories) {
                if (categoryName === 'MetLife STORY') {
                    retrieveContent(categoryId, setMetlifeStoryContents);
                }

                if (categoryName === '1868 NEW YORK') {
                    retrieveContent(categoryId, setNewYorkContents);
                }

                if (categoryName === '사회공헌재단 STORY') {
                    retrieveContent(categoryId, setFoundationContents);
                }
            }

        });
    }, []);

    useLayoutEffect(() => {
        const {financialConsultantChannelCode, businessPositionTypeCode} = agent?.crossAgentInfo;

        // 설계사 채널 구분코드가 '0111' 이고 직책 구분코드가 '1010' 인 경우 표시
        if (Object.is('0111', financialConsultantChannelCode)
          && Object.is('1010', businessPositionTypeCode)) {
            setIsCrossAgent(true);
        } else {
            setIsCrossAgent(false);
        }
    }, [agent]);

    const retrieveSemmEvent = async () => {
        const {item} = await FetchApi.get("/api/meton/channel/banner", {
            bannerKindText: "SEM",
        }); //SEMM
        item[0] && setSemmEvent(item[0]);
    };

    const retrieveMainEvent = async () => {
        const result = await FetchApi.get("/api/meton/channel/main-banner", {
            sort: 'sortSequenceNumber:asc',
            dateValidYn: 'Y'
        }); //메인이벤트

        setMainEvents([...mainEvents, ...result.item]);
    };

    const retrieveContent = async (categoryId = '', setValue) => {
        const {item} = await FetchApi.get(
          "/api/meton/channel/content",
          {
              offset: 0,
              limit: 4,
              sort: "contentStartDate:desc,createTimestamp:desc",
              dateValidYn: "Y",
              categoryId: categoryId
          },
          {}
        );

        setValue(item);
    };

    const retrieveMd5Encoded = async () => {
        const {item} = await FetchApi.get(
          `/api/meton/channel/agent-encoding-md5?urlText=${encodedAgentNumber}`
        );
        const agentNumber = item?.encryptedAgentNumber ? item.encryptedAgentNumber : "";
        setEncryptedAgentNumber(agentNumber);
    };

    const retrieveContentCategories = async () => {
        const result = await FetchApi.get('/api/meton/channel/category?sort=sortSequenceNumber:asc');

        return result.item
    }

    /**
     * 외부 이벤트 링크
     * @param bannerKindText
     * @param linkText
     * @returns {string|*}
     */
    function handleApplyMainEvent(bannerKindText, linkText) {
        const parameter = "?utm_source=meton&utm_medium=&utm_campaign=&utm_term=&utm_content="
        const agentBusinessChannelDivisionCode = agent.basicInfo.businessChannelDivisionCode;

        if (bannerKindText === 'INT') {
            return linkText + encryptedAgentNumber + parameter;
        } else if (bannerKindText === 'SEA') {
            linkText = linkText.replace("##", agentBusinessChannelDivisionCode);
            return linkText + encryptedAgentNumber + parameter;
        } else {
            return linkText;
        }
    }

    /**
     * 컴포넌트
     */
    return (
      <div>
          {/* 프로필 이미지 영역 */}
          <UI_LD_01_01_MainProfileSlide>
              <div className={agentMultiProfiles && 0 < agentMultiProfiles.length ? "visual_info visual_seasonal_info" : "visual_info"}>
                  <li>
                      {["5002", "4006", "4003", "1004"].includes(
                        agent.basicInfo.businessDutyCode
                      )
                        ? agent.basicInfo.headquarterOrganizationName //MD(5002), DS(4006), TS(4003), FPS(1004)
                        : `${agent.basicInfo.headquarterOrganizationName} / ${agent.basicInfo.branchOfficeOrganizationName}`}
                      <Share/>
                  </li>
                  <li>
                      <strong>{agent && `${agent.basicInfo.employeeName}`} {isCrossAgent && `교차설계사`}</strong>
                  </li>
                  <li className="btn_area">
                      <Link to="profile">
                          <button>프로필 보기</button>
                      </Link>
                      <button onClick={() => navigate(`/${encodedAgentNumber}/counsel`)}>
                          상담신청
                      </button>
                  </li>
              </div>
          </UI_LD_01_01_MainProfileSlide>
          {/* PROFILE : END */}
          <div className="met_mainarea">
              {/* 20230822 배너추가 : start */}
              <div className="mbanner">
                  <a href="https://brochure.metlife.co.kr" target="_blank">
                      <img src={require("../../assets/images/banner.png")} alt=""/>
                  </a>
              </div>

              {/* METLIFE NEWS : START */}
              <UI_01_01_MetlifeNews title='메트라이프 최신 소식' contents={contents}/>
              <UI_01_01_MetlifeNews title='MetLife STORY' contents={metlifeStoryContents} category='metlife'/>
              <UI_01_01_MetlifeNews title='1868 NEW YORK' contents={newYorkContents} category='newyork'/>
              <UI_01_01_MetlifeNews title='사회공헌재단 STORY' contents={foundationContents} category='foundation'/>

              <div className="mbanner">
                  <a href="https://www.metlife.co.kr/customer-service/service/customer-magazine/" target="_blank">
                      <img src={require("../../assets/images/customer_magazine.png")} alt=""/>
                  </a>
              </div>

              <div className="friend event main" style={{marginTop: '0px !important'}}>
                  {mainEvents.length > 0 ? <h2>진행 중 이벤트</h2> : null}
                  {mainEvents.length > 0 ? mainEvents.map((mainEvent, idx) => (
                    <a
                      href={`${handleApplyMainEvent(mainEvent.bannerKindText, mainEvent.linkText)}`}
                      className="first"
                      key={`mainbanner-${idx}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                        <img
                          src={getBannerImgSrc(mainEvent.storedImageFileName)}
                          alt=""
                        />
                    </a>
                  )) : null}
              </div>
              {/* 20230822 배너추가 : end */}
              <div className="online">
                  <h2>온라인보험</h2>
                  <p
                    style={{
                        background: `url("${getBannerImgSrc(
                          semmEvent.storedImageFileName
                        )}") no-repeat center top/cover`,
                    }}
                  />
                  <div>
                      <Fade bottom>
                          <div className="online_txt">
                              <span>PRODUCT</span>
                              <strong>
                                  미니보험 선물로
                                  <br/>
                                  마음을 전해보세요
                              </strong>
                              <a
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                  바로가기
                              </a>
                          </div>
                      </Fade>
                  </div>
              </div>
          </div>
          <Outlet/>
      </div>
    );
};

export default UI_01_01_CAMFS;