import React, {useContext} from "react";
import "../../styles/common.css";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {RootContext} from "../../common";

const UI_09_04_500 = function () {

    const {encodedAgentNumberForRoot} = useContext(RootContext);

    return (
        <div className="error">
            <div className="ebox ebox02">
                <strong>이 페이지를 표시할 수 없습니다.</strong>
                <span>
          일시적인 장애로 서비스가 중단 되었습니다.
          <br />
          <br />
          새로 고침을 하시거나, 잠시 후에 다시 시도해주세요.
        </span>
                <Link to={`/${encodedAgentNumberForRoot}`} reloadDocument>
                    <Button className="button" bsPrefix="string">
                        재시도
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default UI_09_04_500;
