import React from "react";

const UI_10_01_GenderSelect = ({gender, onClickGender}) => {
    return (
        <>
            <div className="job gender">
                <div className="jobbox">
                    <ul>
                        <li className="gender_text">
                            <img
                                src={require("../../assets/images/job/gender.png")}
                                alt="먼저 본인의 성별을 선택해 주세요. "
                            />
                        </li>

                        <li className="check">
                            <button className={`man_chcek ${gender === 'M' ? 'on' : ''}`} onClick={() => onClickGender('M')}>남</button>
                            <button className={`woman_chcek ${gender === 'F' ? 'on' : ''}`} onClick={() => onClickGender('F')}>녀</button>
                            {/* 체크하면 class "on" 추가 */}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default UI_10_01_GenderSelect;