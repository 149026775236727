import {Table} from "react-bootstrap";
import React from "react";
import {formatDate} from "../../common";

const UI_02_02 = function (items) {

    // const [showLength, setShowLength] = useState(10);

    // const handleShowLength = () => {
    //     setShowLength(() =>
    //         items.awards.length - showLength > 10
    //             ? showLength + 10
    //             : showLength + items.awards.length - showLength
    //     );
    // }

    let ellipsis = {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2
    }

    return (
        <>
            <Table className="tableList" bsPrefix="string">
                <thead>
                <tr>
                    <th>No.</th>
                    <th>수상 경력</th>
                </tr>
                </thead>
                <tbody>
                {
                    items.awards.length === 0
                        ?
                        <tr>
                            <td colSpan={2} style={{textAlign: "center"}}>등록된 수상 경력이 없습니다.</td>
                        </tr>
                        :
                        items.awards.sort((a, b) => b.awardDate.slice(0,4) - a.awardDate.slice(0,4) || b.createTimestamp - a.createTimestamp)
                            // .filter(e => items.awards.indexOf(e) < showLength)
                            .map((award, index) => {
                                return (
                                    <tr key={`award-${index}`}>
                                        <td className="num">{index + 1}</td>
                                        <td>
                                            <div style={ellipsis}>
                                                <span>{formatDate(award.awardDate, 'yyyy')}</span>{award.awardName}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                }
                </tbody>
            </Table>
            {/*<Button*/}
            {/*    className="button"*/}
            {/*    bsPrefix="string"*/}
            {/*    onClick={() => items.awards.length > 0 && handleShowLength()}*/}
            {/*>*/}
            {/*    + 더보기 ({showLength > 10 ? items.awards.length - showLength : 0})*/}
            {/*</Button>*/}
        </>
    );
};

export default UI_02_02;