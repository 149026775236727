import {createContext} from "react";

const RootContext = createContext({
    encodedAgentNumberForRoot: '',
    setEncodedAgentNumberForRoot: () => {},
    menuMap: new Map(),
    setMenuMap: () => {},
    agent: {},
    setAgent: () => {},
    selectedNewsMainImage: '',
    setSelectedNewsMainImage: () => {},
    selectedNewsTitle: '',
    setSelectedNewsTitle: () => {},
    isGAManager: () => {},
});

export default RootContext;