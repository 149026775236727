import React, { useContext } from "react";
import { RootContext } from "../../common";
import UI_09_02_GA from "./UI_09_02_GA";
import UI_09_02_CAMFS from "./UI_09_02_CAMFS";

const UI_09_02 = function () {

  const { agent, isGAManager } = useContext(RootContext);

  const render = () => {
    if (isGAManager(agent.basicInfo.businessDutyCode)) {
      return (<UI_09_02_GA/>);
    } else {
      return (<UI_09_02_CAMFS/>);
    }
  }

  return render();
};

export default UI_09_02;
