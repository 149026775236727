import {Button, Form, Modal} from "react-bootstrap";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    AgencyTerms,
    FetchApi,
    IdentifierTerms,
    MetOnModal,
    PrivacyTerms, RootContext,
    ServiceTerms
} from "../../common";
import moment from "moment";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const ONE_SEC = 1000;
const ONE_MIN = 60000;

const UI_06_02 = function ({trackingMenu}) {

    const navigate = useNavigate();
    const location = useLocation();

    const {encodedAgentNumber} = useParams();
    const { agent } = useContext(RootContext);

    const [showAlert, setShowAlert] = useState(false);

    const [termsCondition, setTermsCondition] = useState({telecom: 'SKT', type: 'Service'});
    const [termsModalShow, setTermsModalShow] = useState(false);
    const [infoDisabled, setInfoDisabled] = useState(false);
    const [certDisabled, setCertDisabled] = useState(false);
    const [time, setTime] = useState(3 * ONE_MIN);
    const [timerOn, setTimerOn] = useState(false);
    const [inviterProfile, setInviterProfile] = useState({});
    const timeText = useMemo(() => moment(time).format('m:ss'), [time]);
    const [startTime, setStartTime] = useState(moment());

    const [applyInfo, setApplyInfo] = useState({
        name: '',
        type: 'domestic',
        birth: '',
        gender: '',
        telecom: '',
        telecomAccept: {
            all: false,
            agency: false,
            identifier: false,
            privacy: false,
            service: false,
        },
        mobile: '',
        certNo: '',
        certificated: false,
        metlifePrivacy: undefined,
        inviterCode: '',
        validInviterCode: false
    });

    useEffect(() => {
        trackingMenu('A0000042');
    }, []);

    useEffect(() => {
        window.addEventListener('click', initializeIdleTime);

        return (() => {
            window.removeEventListener('click', initializeIdleTime);
        })
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            if (moment.duration(moment().diff(startTime)).asMinutes() > 20) {
                alert('20분 동안 움직임이 없어 메인화면으로 이동합니다.')
                navigate(`/${encodedAgentNumber}`)
            }
        }, ONE_SEC);

        return () => {
            clearInterval(interval)
        }

    }, [startTime])

    useEffect(() => {
        let interval
        if (timerOn) {
            interval = setInterval(() => {
                if (time > 0) {
                    setTime(time - ONE_SEC);
                }
            }, ONE_SEC)
        }
        return () => {
            clearInterval(interval);
        }
    }, [timerOn, time]);

    const initializeIdleTime = () => {
        setStartTime(moment());
    }

    const requestApply = async () => {
        let requestBody = {
            urlText: encodedAgentNumber,
            infwPathCd: '02',
            cndpNm: applyInfo.name,
            cndpTlno: [applyInfo.mobile.slice(0, 3), applyInfo.mobile.slice(3, 7), applyInfo.mobile.slice(7)].join("-"),
            cbwlArno: applyInfo.mobile.slice(0, 3),
            cbwlStno: applyInfo.mobile.slice(3, 7),
            cbwlIdno: applyInfo.mobile.slice(7),
            itdpId: applyInfo.validInviterCode ? applyInfo.inviterCode : agent.basicInfo.agentNumber,
            rgno: applyInfo.birth.slice(2),
            gndrCd: applyInfo.gender,
            rcrtStgCd: 'PS',
            csifPrcAgrmMtcd: '02',
            prsnCrinfAgdt: moment().format('YYYYMMDD'),
        };

        if (['01', '02', '03'].includes(location.state?.resultCode)) {
            requestBody.rcrtCndpQstnRtcd = location.state?.resultCode;
        }

        await FetchApi.post('/api/meton/channel/salespipeline/recruiting-candidates',
            requestBody,
            {gubun: "C"}
        );
    }

    const validate = (testers) => {
        for (let j = 0; j < testers.length; j++) {
            const tester = testers[j];

            const names = tester[0].split('.');

            let target = Object.assign({}, applyInfo);

            for (let i = 0; i < names.length; i++) {
                let name = names[i];
                target = target[name];
            }

            if (Array.isArray(tester[1])) {
                if (tester[1].map((falseValvue) => target === falseValvue).includes(true)) {
                    alert(tester[2]);
                    return false;
                }
            } else {
                if (target === tester[1]) {
                    alert(tester[2]);
                    return false;
                }
            }

        }
        return true;

    }

    const validateBirth = (value) => {
        const regex = new RegExp(/^[0-9]{8}$/)
        return regex.test(value);
    }

    const validateMobile = (value) => {
        const regex = new RegExp(/^01[0-9]([0-9]{7}|[0-9]{8})$/)
        return regex.test(value);
    }

    const validateRequestingCertification = () => {
        if (infoDisabled) {
            return true;
        }

        alert('먼저 인증번호를 받아야합니다');
        return false;
    }

    const validateApplyInfoForCertNo = () => {
        return validate([
            ['name', '', '성명을 입력하세요'],
            ['birth', '', '생년월일을 확인해주세요'],
            ['birth', validateBirth, '생년월일을 8자리를 입력해주세요 예)20170101'],
            ['gender', '', '성별을 체크하세요'],
            ['mobile', '', '휴대폰번호를 입력하세요'],
            ['mobile', validateMobile, '휴대폰번호 11자리를 입력해주세요 예)01012345678'],
            ['telecomAccept.all', false, '통신사 약관에 모두 동의해주세요'],
        ]);
    }

    const validateApplyInfoForRequest = () => {
        return validate([
            ['name', '', '성명을 입력하세요'],
            ['gender', '', '성별을 체크하세요'],
            ['mobile', '', '휴대폰번호를 입력하세요'],
            ['telecomAccept.all', false, '통신사 약관에 모두 동의해주세요'],
            ['metlifePrivacy', [false, undefined], '개인정보 수집이용 약관에 동의해주세요'],
            ['certificated', false, '휴대폰 본인인증이 필요합니다'],
        ])
    }

    const validateApplyInfoForInviterInfo = () => {
        return validate([
            ['inviterCode', '', '소개자 코드를 입력하세요.'],
        ])
    }

    const changeApplyInfo = (key, value) => {
        const names = key.split('.');
        const length = names.length;
        const result = Object.assign({}, applyInfo);
        let target = result;

        for (let i = 0; i < length; i++) {
            let name = names[i];

            if (i === length - 1) {
                target[name] = value;
            } else {
                target = target[name];
            }
        }

        setApplyInfo(Object.assign({}, result))
    }

    const checkTelecomAllAcceptedWithNewValue = (key, value) => {
        return !Object.entries(applyInfo.telecomAccept).map((entry) => {
            if (entry[0] === 'all') {
                return true;
            }
            return entry[0] === key ? value : entry[1];
        }).includes(false);
    }

    const requestCertNo = async () => {
        return FetchApi.post('/api/meton/channel/mobile-auth', {
            customerName: applyInfo.name,
            birthDate: applyInfo.birth,
            genderCode: applyInfo.gender,
            foreignerYn: applyInfo.type === 'domestic' ? '0' : '1',
            cellularPhoneNumber: applyInfo.mobile,
            cellularPhoneCompanyName: termsCondition.telecom,
        });
    }

    const certificateCertNo = async () => {
        return FetchApi.post('/api/meton/channel/mobile-auth-check', {
            customerName: applyInfo.name,
            birthDate: applyInfo.birth,
            genderCode: applyInfo.gender,
            foreignerYn: applyInfo.type === 'domestic' ? '0' : '1',
            cellularPhoneNumber: applyInfo.mobile,
            cellularPhoneCompanyName: termsCondition.telecom,
            authorizationNumber: applyInfo.certNo,
        });
    }

    const handleRequestCertificationNumber = () => {
        if (validateApplyInfoForCertNo()) {
            // 인증번호 받기
            requestCertNo()
                .then((data) => {
                    if (data.message) {
                        alert(data.message);
                        return;
                    }
                    alert('인증번호가 전송되었습니다')
                    setInfoDisabled(true);
                    setTimerOn(true);
                })
                .catch((e) => alert(e.description || '인증번호 전송에 실패하였습니다. 다시 시도해 주세요.'));
        }
    }

    const handleRequestCertificate = () => {
        if (validateRequestingCertification()) {
            certificateCertNo()
                .then((data) => {
                    if (data.message) {
                        alert(data.message);
                        return;
                    }
                    alert('본인인증이 완료되었습니다')
                    setTimerOn(false)
                    setCertDisabled(true)
                    changeApplyInfo('certificated', true);
                })
                .catch((e) => alert(e.description || '인증 오류입니다. 다시 시도해주세요.'));
        }
    }

    const handleFinalValidate = () => {
        // 최종 validation
        if (validateApplyInfoForRequest()) {
            setShowAlert(true);
        }
    }

    const handleApplyFSR = async () => {
        // fsr 신청하기
        setShowAlert(false);
        requestApply().then(() => alert('지원 신청이 완료되었습니다.')).catch((e) => alert(e.description));
    }

    const handleOpenTerms = (type) => {
        setTermsCondition({...termsCondition, type});
        setTermsModalShow(true);
    }

    const handleRequestInviterProfile = async () => {
        // 소개자 조회
        if(applyInfo.inviterCode.length === 8){
            validateApplyInfoForInviterInfo();

            let {item, deleteYn, isSuccess} = {
                item: {},
                deleteYn: false,
                isSuccess: false,
            };

            await FetchApi.get('/api/meton/channel/profile', {agentNumber: applyInfo.inviterCode})
                .then(response => {({item, deleteYn, isSuccess} = response);
                    if (isSuccess === true) {
                        if (deleteYn === true) {
                            setInviterProfile({});
                            changeApplyInfo('validInviterCode', false);
                            alert('조건에 맞는 Agent 정보가 없습니다. 다시 확인 바랍니다.');
                        } else {
                            setInviterProfile(item);
                            changeApplyInfo('validInviterCode', true);
                        }
                    } else {
                        alert('입력된 소개자 코드가 맞는지 다시 확인 바랍니다.');
                    }
                }).catch(() => {
                    setInviterProfile({});
                    changeApplyInfo('validInviterCode', false);
                    alert('입력된 소개자 코드가 맞는지 다시 확인 바랍니다.');
                });
        }else{
            alert('소개자 코드는 8자리입니다.');
        }
    }

    const showTerms = () => {
        return (
            <MetOnModal
                show={termsModalShow}
                setShow={setTermsModalShow}
                body={getTermsBody(termsCondition.telecom, termsCondition.type)}
            />
        );
    }

    const getTermsBody = (telecom, type) => {

        switch (type) {
            case 'Agency':
                return (<AgencyTerms telecom={telecom}/>);
            case 'Identifier':
                return (<IdentifierTerms telecom={telecom}/>);
            case 'Privacy':
                return (<PrivacyTerms telecom={telecom}/>);
            case 'Service':
                return (<ServiceTerms telecom={telecom}/>);
            default:
                return null;
        }
    }

    return (
        <div>
            메트라이프생명은 회사와 FSR로서의 버전을 자세히 소개하는
            MCS(MetLife Career Seminar)를 실시하고 있습니다.
            신청자분들께서는 MetLife 회사와 내 미래의 발전을 함께 검토하는
            기회를 얻으실 수 있습니다.
            <Form className="writeform ">
                <h3>본인인증</h3>
                휴대폰 본인인증 확인 후 신청하실 수 있습니다.
                <p className="essential">필수 입력 사항입니다.</p>
                <Form.Group
                    className="mb-3 ess colgroup"
                    controlId="formGroupName"
                >
                    <Form.Label>성명</Form.Label>
                    <p>
                        <Form.Control type="text" placeholder="성명을 입력하세요." value={applyInfo.name}
                                      disabled={infoDisabled}
                                      onChange={(e) => changeApplyInfo('name', e.target.value)}/>
                        <Form.Select value={applyInfo.type} disabled={infoDisabled}
                                     onChange={(e) => changeApplyInfo('type', e.target.value)}>
                            <option value="domestic">내국인</option>
                            <option value="foreigner">외국인</option>
                        </Form.Select>
                    </p>
                </Form.Group>
                <Form.Group
                    className="mb-3 ess colgroup2"
                    controlId="formGroupBirthday"
                >
                    <Form.Label>생년월일</Form.Label>
                    <p>
                        <span>
                          <Form.Control type="number" pattern="\d*" placeholder="예) 19920101"
                                        value={applyInfo.birth}
                                        disabled={infoDisabled}
                                        onChange={(e) => {
                                            changeApplyInfo('birth', e.target.value)
                                        }}
                          />
                        </span>
                        <span>
                          <Form.Check type="radio" label="남" checked={applyInfo.gender === '1'}
                                      disabled={infoDisabled}
                                      onClick={() => changeApplyInfo('gender', '1')}/>
                    <Form.Check type="radio" label="여" checked={applyInfo.gender === '2'}
                                disabled={infoDisabled}
                                onClick={() => changeApplyInfo('gender', '2')}/>
                        </span>
                    </p>
                </Form.Group>
                <Form.Group className="mb-3 ess" controlId="formGroupPhone">
                    <Form.Label>통신사선택</Form.Label>
                    <Form.Select value={termsCondition.telecom}
                                 disabled={infoDisabled}
                                 onChange={(e) => {
                                     setTermsCondition({...termsCondition, telecom: e.target.value});
                                     changeApplyInfo('telecomAccept', {
                                         all: false,
                                         agency: false,
                                         identifier: false,
                                         privacy: false,
                                         service: false,
                                     })
                                 }}>
                        <option value="SKT">SKT</option>
                        <option value="KTF">KT</option>
                        <option value="LGT">LGU+</option>
                        <option value="SKM">SKT(알뜰폰)</option>
                        <option value="KTM">KT(알뜰폰)</option>
                        <option value="LGM">LGU+(알뜰폰)</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 ess" controlId="formGroupPhone">
                    <Form.Label>휴대폰번호</Form.Label>
                    <Form.Control
                        type="number"
                        pattern="\d*"
                        placeholder="'-'제외하고 숫자만 입력"
                        value={applyInfo.mobile}
                        disabled={infoDisabled}
                        onChange={(e) => {
                            changeApplyInfo('mobile', e.target.value)
                        }}
                    />
                </Form.Group>
                <div className="dash">
                    <li>
                        본인 명의의 휴대폰 정보를 정확히 입력하여 주시기
                        바랍니다.
                    </li>
                    <li>
                        본인인증 5회 실패 시 당일 휴대폰 인증이 제한됩니다.
                    </li>
                    <li>
                        알뜰폰이란? 이동통신사(SKT, KT, LG U+)의 통신망을
                        임대하여 별도의 휴대폰 서비스를 제공하는 사업자를
                        말합니다.
                    </li>
                </div>
                <Form.Group controlId="yak" className="yak_area">
                    <Form.Group controlId="yak1">
                        <Form.Check
                            type="checkbox"
                            label="서비스 이용약관"
                            className="yak"
                            disabled={infoDisabled}
                            onChange={(e) => {
                                changeApplyInfo('telecomAccept', {
                                    all: checkTelecomAllAcceptedWithNewValue('service', e.target.checked),
                                    agency: applyInfo.telecomAccept.agency,
                                    identifier: applyInfo.telecomAccept.identifier,
                                    privacy: applyInfo.telecomAccept.privacy,
                                    service: e.target.checked,
                                });
                            }}
                            checked={applyInfo.telecomAccept.service}
                        />
                        <Button
                            className="button3"
                            bsPrefix="string"
                            onClick={() => handleOpenTerms('Service')}
                        >
                            전문보기
                        </Button>
                        {/*서비스 이용약관*/}
                    </Form.Group>
                    <Form.Group controlId="yak2">
                        <Form.Check
                            type="checkbox"
                            label="개인정보 이용 동의"
                            className="yak"
                            checked={applyInfo.telecomAccept.privacy}
                            disabled={infoDisabled}
                            onChange={(e) =>
                                changeApplyInfo('telecomAccept', {
                                    all: checkTelecomAllAcceptedWithNewValue('privacy', e.target.checked),
                                    agency: applyInfo.telecomAccept.agency,
                                    identifier: applyInfo.telecomAccept.identifier,
                                    privacy: e.target.checked,
                                    service: applyInfo.telecomAccept.service,
                                })
                            }
                        />
                        <Button
                            className="button3"
                            bsPrefix="string"
                            onClick={() => handleOpenTerms('Privacy')}
                        >
                            전문보기
                        </Button>
                        {/* 개인정보 이용 동의*/}
                    </Form.Group>
                    <Form.Group controlId="yak3">
                        <Form.Check
                            type="checkbox"
                            label="고유식별정보 처리 동의"
                            className="yak"
                            checked={applyInfo.telecomAccept.identifier}
                            disabled={infoDisabled}
                            onChange={(e) =>
                                changeApplyInfo('telecomAccept', {
                                    all: checkTelecomAllAcceptedWithNewValue('identifier', e.target.checked),
                                    agency: applyInfo.telecomAccept.agency,
                                    identifier: e.target.checked,
                                    privacy: applyInfo.telecomAccept.privacy,
                                    service: applyInfo.telecomAccept.service,
                                })
                            }
                        />
                        <Button
                            className="button3"
                            bsPrefix="string"
                            onClick={() => handleOpenTerms('Identifier')}
                        >
                            전문보기
                        </Button>
                        {/*고유식별정보 처리 동의*/}
                    </Form.Group>
                    <Form.Group controlId="yak4">
                        <Form.Check
                            type="checkbox"
                            label="통신사 이용약관 동의"
                            className="yak"
                            checked={applyInfo.telecomAccept.agency}
                            disabled={infoDisabled}
                            onChange={(e) => {
                                changeApplyInfo('telecomAccept', {
                                    all: checkTelecomAllAcceptedWithNewValue('agency', e.target.checked),
                                    agency: e.target.checked,
                                    identifier: applyInfo.telecomAccept.identifier,
                                    privacy: applyInfo.telecomAccept.privacy,
                                    service: applyInfo.telecomAccept.service,
                                })

                            }}
                        />
                        <Button
                            className="button3"
                            bsPrefix="string"
                            onClick={() => handleOpenTerms('Agency')}
                        >
                            전문보기
                        </Button>
                        {/*고유식별정보 처리 동의*/}
                    </Form.Group>
                    <Form.Group controlId="yak5">
                        <Form.Check
                            type="checkbox"
                            label="위 내용을 모두 확인하였으며, 전체 동의합니다."
                            className="yak yak_all"
                            checked={applyInfo.telecomAccept.all}
                            disabled={infoDisabled}
                            onChange={(e) =>
                                changeApplyInfo('telecomAccept', {
                                    all: e.target.checked,
                                    agency: e.target.checked,
                                    identifier: e.target.checked,
                                    privacy: e.target.checked,
                                    service: e.target.checked,
                                })
                            }
                        />
                    </Form.Group>
                </Form.Group>
                <Button className="button button2" bsPrefix="string" disabled={infoDisabled}
                        onClick={handleRequestCertificationNumber}>
                    인증번호 받기
                </Button>
                <Form.Group className="mb-4" controlId="formGroupConfirm">
                    <Form.Label>인증번호</Form.Label>
                    <Form.Control type="text" placeholder="인증번호 6자리" value={applyInfo.certNo}
                                  onChange={(e) => changeApplyInfo('certNo', e.target.value)}
                                  disabled={certDisabled}/>
                    <span className="intime">{timeText}</span>
                    <Button className="button" bsPrefix="string" onClick={handleRequestCertificate}
                            disabled={certDisabled}>
                        인증하기
                    </Button>
                </Form.Group>
                <h3 className="hr">
                    재정전문가(FSR) 모집을 위한 개인정보 수집∙이용에 관한 동의
                </h3>
                귀하는 아래 개인정보의 수집∙이용에 대한 동의를 거부 하실 수
                있습니다. 다만 동의하지 않으실 경우 재정전문가(FSR)에 대한
                정보를 받으실 수 없습니다.
                <br/>
                <br/>
                <Form.Group className="mb-1" controlId="formGroupTerms">
                    <p className="agree">
                        1. 개인정보의 수집∙이용 목적
                        <br/>
                        - 재정전문가(FSR) 안내
                        <br/>
                        <br/>
                        2. 수집∙이용할 개인정보의 내용
                        <br/>
                        - 이름, 성별, 출생년월, 휴대폰번호, 최종학력,
                        보험경력여부, 시스템 접속로그 등<br/>
                        <br/>
                        3. 개인정보의 보유∙이용 기간
                        <br/>-{" "}
                        <strong>
                            동의일로부터 3년(도래한 해당월 말일까지)
                        </strong>
                        <br/>
                        <br/>
                        ※ 본 동의는 당사와 위탁계약을 체결한 업무수탁자 및
                        위탁업체가 개인정보를 수집·이용하는 것을 포함합니다.
                        <br/>
                        ※ 상기 내용에 동의하시는 경우 당사 임직원, Agent(본부장,
                        지점장, Field Manager), 업무수탁자 등이 확인 및
                        상세안내를 위해 전화를 드릴 수 있습니다.
                        <br/>
                        <br/>
                        <span>
                          개인정보 수집제공동의 심사승인 (DIS007-2205-6001-001)
                        </span>
                    </p>
                </Form.Group>
                <Form.Group controlId="agree1" className="mb-3 gender">
                    <Form.Check type="radio" label="동의하지 않음" checked={applyInfo.metlifePrivacy === false}
                                onClick={() => changeApplyInfo('metlifePrivacy', false)}/>
                </Form.Group>
                <Form.Group controlId="agree2" className="mb-3 gender">
                    <Form.Check type="radio" label="동의함" checked={applyInfo.metlifePrivacy === true}
                                onClick={() => changeApplyInfo('metlifePrivacy', true)}/>
                </Form.Group>
                <Form.Group
                    className="mb-3 code"
                    controlId="formGroupProposwe"
                >
                    <Form.Label>소개자 코드</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="숫자만 입력 (8자리)"
                        onChange={e => {
                            changeApplyInfo('inviterCode', e.target.value);
                        }}
                    />
                    <Button
                        className="button button2"
                        bsPrefix="string"
                        onClick={handleRequestInviterProfile}
                        style={{width: "90px", float: "right"}}
                    >
                        확인
                    </Button>
                    <p>
                        {
                            applyInfo.validInviterCode &&
                            `${inviterProfile.basicInfo?.headquarterOrganizationName}
                             ${inviterProfile.basicInfo?.branchOfficeOrganizationName} /
                             ${inviterProfile.basicInfo?.employeeName}`
                        }
                    </p>
                </Form.Group>
            </Form>
            <Button
                className="button"
                bsPrefix="string"
                onClick={handleFinalValidate}
            >
                신청하기
            </Button>
            <Modal
                show={showAlert}
                onHide={() => setShowAlert(false)}
                dialogClassName="modal-alert"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title"/>
                </Modal.Header>
                <Modal.Body>
                    신청하시겠습니까?
                    <div className="btn_area_alert">
                        <Button
                            className="button button_small"
                            bsPrefix="string"
                            onClick={handleApplyFSR}
                        >
                            확인
                        </Button>
                        <Button
                            className="button button_small"
                            bsPrefix="string"
                            onClick={() => setShowAlert(false)}
                        >
                            취소
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            {showTerms()}
        </div>
    );
};

export default UI_06_02;