import React from "react";
import "../../styles/common.css";

const UI_09_04_AgentNotFound = function () {

    return (
        <div className="error">
            <div className="ebox ebox01">
                <strong>에이전트 정보를 찾을 수 없습니다.</strong>
                <span>
          사이트 URL 정보가 잘못 입력 되었습니다.
          <br/>
          <br/>
          사이트 주소가 정확한지 한 번 더 확인해 주세요.
        </span>
            </div>
        </div>
    );
}

export default UI_09_04_AgentNotFound;
