import React, {useEffect, useRef, useState} from "react";

const Truncate = ({
                      children = '',
                      ellipsis = '. . .',
                      lines: linesProp = 3,
                      trimWhitespace = false,
                      width,
                      onTruncate: onTruncateProps
                  }) => {

    let replacedLinks = [];
    let canvasContext = {};
    let timeout = {};
    let styles = {
        ellipsis: {
            position: 'fixed',
            visibility: 'hidden',
            top: 0,
            left: 0
        }
    };

    const [targetWidth, setTargetWidth] = useState();
    const [text, setText] = useState();
    const targetRef = useRef();
    const ellipsisRef = useRef();
    const textRef = useRef();

    useEffect(() => {
        const canvas = document.createElement('canvas');
        canvasContext = canvas.getContext('2d');
        calcTargetWidth(() => {
            if (textRef.current && textRef.current?.parentNode) {
                textRef.current.parentNode.removeChild(textRef.current);
            }
        });

        let txt;

        const mounted = !!(targetRef.current && targetWidth);

        if (typeof window !== 'undefined' && mounted) {
            if (linesProp > 0) {
                txt = getLines();
            } else {
                txt = children;
                onTruncate(false);
            }
        }
        setText(txt)

        window.addEventListener('resize', onResize);

        return (() => {
            if (ellipsisRef.current?.parentNode) {
                ellipsisRef.current.parentNode.removeChild(ellipsisRef.current);
            }

            window.removeEventListener('resize', onResize);
            window.cancelAnimationFrame(timeout);
        })

    }, [ellipsisRef, targetRef, textRef, targetWidth, linesProp, children]);

    const calcTargetWidth = (callback) => {
        if (!targetRef.current) {
            return;
        }

        const targetWidth = (width || Math.floor(targetRef.current?.parentNode.getBoundingClientRect().width));

        if (!targetWidth) {
            return window.requestAnimationFrame(() => calcTargetWidth(callback))
        }

        const style = window.getComputedStyle(targetRef.current);

        canvasContext.font = [
            style['font-weight'],
            style['font-style'],
            style['font-size'],
            style['font-family'],
        ].join(' ');
        canvasContext.letterSpacing = style['letter-spacing'];

        setTargetWidth(targetWidth);
        callback && callback();
    }

    const extractReplaceLinksKeys = (content) => {

        let i = 0;
        replacedLinks = [];
        content.replace(/(<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>)/g, function () {
            const item = Array.prototype.slice.call(arguments, 1, 4);
            item.key = '[' + '@'.repeat(item[2].length - 1) + '=' + i++ + ']';
            replacedLinks.push(item);

            content = content.replace(item[0], item.key);
        });

        return content;
    }

    const restoreReplacedLinks = (content) => {
        replacedLinks.forEach(item => {
            content = content.replace(item.key, item[0]);
        })

        return createMarkup(content);
    }

    const innerText = (node) => {
        const div = document.createElement('div');
        const contentKey = 'innerText' in window.HTMLElement.prototype ? 'innerText' : 'textContent';

        const content = node.innerHTML.replace(/\r\n|\r|\n/g, '');
        div.innerHTML = extractReplaceLinksKeys(content);

        let text = div[contentKey];

        const test = document.createElement('div');
        test.innerHTML = 'foo<br/>>bar';

        if (test[contentKey].replace(/\r\n|\r/g, '\n') !== 'foo\nbar') {
            div.innerHTML = div.innerHTML.replace(/<br.*?[/]?>/gi, '\n');
            text = div[contentKey];
        }

        return text;
    }

    const onResize = () => {
        calcTargetWidth();
    }

    const onTruncate = (didTruncate) => {
        if (typeof onTruncateProps === 'function') {
            timeout = window.requestAnimationFrame(() => {
                onTruncateProps(didTruncate);
            })
        }
    }


    const measureWidth = (text) => {
        return canvasContext.measureText(text).width
    }

    const ellipsisWidth = (node) => {
        return node.offsetWidth;
    }

    const trimRight = (text) => {
        return text.replace(/\s+$/, '');
    }

    const createMarkup = (str) => {
        return <span dangerouslySetInnerHTML={{__html: str}}/>;
    }


    const renderLine = (line, i, arr) => {
        if (i === arr.length - 1) {
            return <span key={i}>{line}</span>;
        } else {
            const br = <br key={i + 'br'}/>;

            if (line) {
                return [
                    <span key={i}>{line}</span>,
                    br
                ];
            } else {
                return br;
            }
        }

    }

    const getLines = () => {
        const lines = [];
        const text = innerText(textRef.current);
        const textLines = text.split('\n').map(line => line.split(' '));

        let didTruncate = true;
        const elliWidth = ellipsisWidth(ellipsisRef.current);


        for (let line = 1; line <= linesProp; line++) {
            const textWords = textLines[0];
            // Handle newline
            if (textWords.length === 0) {
                lines.push();
                textLines.shift();
                line--;
                continue;
            }

            let resultLine = textWords.join(' ');

            if (measureWidth(resultLine) <= targetWidth) {
                if (textLines.length === 1) {
                    // Line is end of text and fits without truncating
                    didTruncate = false;

                    resultLine = restoreReplacedLinks(resultLine);
                    lines.push(resultLine);

                    break;
                }
            }

            if (line === linesProp) {
                // Binary search determining the longest possible line including truncate string
                const textRest = textWords.join(' ');

                let lower = 0;
                let upper = textRest.length - 1;


                while (lower <= upper) {
                    const middle = Math.floor((lower + upper) / 2);

                    const testLine = textRest.slice(0, middle + 1);

                    if (measureWidth(testLine) + elliWidth <= targetWidth) {
                        lower = middle + 1;
                    } else {
                        upper = middle - 1;
                    }
                }

                let lastLineText = textRest.slice(0, lower);

                if (trimWhitespace) {
                    lastLineText = trimRight(lastLineText);

                    // Remove blank lines from the end of text
                    while (!lastLineText.length && lines.length) {
                        const prevLine = lines.pop();

                        lastLineText = trimRight(prevLine);
                    }
                }

                if (lastLineText.substr(lastLineText.length - 2) === '][') {
                    lastLineText = lastLineText.substring(0, lastLineText.length - 1);
                }


                lastLineText = lastLineText.replace(/\[@+$/, '');
                lastLineText = restoreReplacedLinks(lastLineText);

                resultLine = <span>{lastLineText}{ellipsis}</span>;
            } else {
                // Binary search determining when the line breaks
                let lower = 0;
                let upper = textWords.length - 1;

                while (lower <= upper) {
                    const middle = Math.floor((lower + upper) / 2);

                    const testLine = textWords.slice(0, middle + 1).join(' ');

                    if (measureWidth(testLine) <= targetWidth) {
                        lower = middle + 1;
                    } else {
                        upper = middle - 1;
                    }
                }

                // The first word of this line is too long to fit it
                if (lower === 0) {
                    // Jump to processing of last line
                    line = linesProp - 1;
                    continue;
                }

                resultLine = textWords.slice(0, lower).join(' ');

                resultLine = restoreReplacedLinks(resultLine);

                textLines[0].splice(0, lower);
            }

            lines.push(resultLine);
        }

        onTruncate(didTruncate);

        return lines.map(renderLine);
    }

    return (
        <span
            ref={targetRef}
        >
                <span>
                    {text}
                </span>
                <span
                    ref={textRef}
                >
                    {children}
                </span>
                <span
                    ref={ellipsisRef}
                    style={styles.ellipsis}
                >
                    {ellipsis}
                </span>
            </span>
    );
}

export default Truncate;