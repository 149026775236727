import React from "react";
import "../../styles/common.css";

const UI_09_04_Maintenance = function () {
    return (
        <div className="error">
            <div className="ebox ebox03">
                <strong>시스템 정기점검 중입니다.</strong>
                <span>
          신속하게 처리하여 빠른 시일 내에 정상적인 서비스가 되도록 하겠습니다.{" "}
                    <br />
          <br />
          - 점검시간 -<br />
          <i>2022/02/01 00:00~12:00 (12시간)</i>
          - 점검내용 -<br />
          성능 업그레이드를 위한 시스템 점검
        </span>
            </div>
        </div>
    );
}

export default UI_09_04_Maintenance;
