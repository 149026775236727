import {Link, useParams} from "react-router-dom";
import Fade from "react-reveal/Fade";
import {Button, Toast} from "react-bootstrap";
import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {UI_02_02} from "../02";
import {UI_02_03} from "../03";
import {
  formatDate,
  getProfileImgSrc,
  MetOnModal,
  RootContext,
  Share, ShowMoreText,
} from "../../common";
import miniProfileLogo from "../../assets/images/sub/profile_img_no.png"
import mainProfileLogo from "../../assets/images/visual.jpg"
import UI_LD_01_01_MainProfileSlide from "../../ui_01/01/UI_LD_01_01_MainProfileSlide";

const UI_02_01 = function ({trackingMenu}) {
  const {agent, agentMultiProfiles} = useContext(RootContext);
  const {encodedAgentNumber} = useParams();
  const [showMiniProfilePop, setShowMiniProfilePop] = useState(false);
  const [showMainProfilePop, setShowMainProfilePop] = useState(false);
  const [showAward, setShowAward] = useState(false);
  const [visualAttr, setVisualAttr] = useState({});
  const [profileAttr, setProfileAttr] = useState({});
  const [certificateInfoOpen, setCertificateInfoOpen] = useState(false);
  const [certificateInfo, setCertificateInfo] = useState({title: '', body: ''});
  const [showLicense, setShowLicense] = useState(false);
  //교차 설계서 여부
  const [isCrossAgent, setIsCrossAgent] = useState(false);

  const certificateInfoData = {
    varQual: {
      title: '변액보험 판매관리사',
      body: '투자형 상품인 변액보험을 판매/관리할 수 있는 전문 자격증 소유자'
    },
    mdrt: {
      title: 'MDRT (Million Dollar Round Table)',
      body:
        <div className="dash">
          <li>
            윤리영업을 기반으로 한 보험과 재무분야의 전세계적인 금융전문가들의 모임
          </li>
          <li>
            백만달러 원탁회의의 약어로 전세계 생명보험 명예의 전당으로 일정 기준의 업적 달성자에게 주어지는 자격
          </li>
          <li>
            업적에 따라 COT(Court of the Table), TOT (Top of the Table)로 구분됨
          </li>
        </div>
    },
    cot: {
      title: 'MDRT - COT (Court of the Table)',
      body:
        <div className="dash">
          <li>
            윤리영업을 기반으로 한 보험과 재무분야의 전세계적인
            금융전문가들의 모임
          </li>
          <li>
            백만달러 원탁회의의 약어로 전세계 생명보험 명예의 전당으로
            일정 기준의 업적 달성자에게 주어지는 자격
          </li>
          <li>
            COT: MDRT 영업실적의 3배를 달성한 Agent
          </li>
        </div>
    },
    tot: {
      title: 'MDRT - TOT (Top of the Table)',
      body:
        <div className="dash">
          <li>
            윤리영업을 기반으로 한 보험과 재무분야의 전세계적인
            금융전문가들의 모임
          </li>
          <li>
            백만달러 원탁회의의 약어로 전세계 생명보험 명예의 전당으로
            일정 기준의 업적 달성자에게 주어지는 자격
          </li>
          <li>
            TOT: MDRT 영업실적의 6배를 달성한 Agent
          </li>
        </div>
    },
    certifiedFsr: {
      title: '우수인증설계사',
      body: '최소 3년 이상의 경력과 고객관리, 불완전판매 이력을 고려하여 생명보험협회에서 인증한 우수 FSR'
    },
    seoulUnivCertifiedE07: {
      title: '서울대 은퇴설계 코칭 전문가 과정',
      body: '서울대 협업 은퇴설계 코칭 전문가 양성 과정'
    },
    seoulUnivCertifiedE37: {
      title: '서울대 은퇴설계 과정',
      body: '서울대 협업 은퇴설계 전문가 양성 과정'
    },
    cfp: {
      title: 'CFP',
      body: '국제공인 재무설계사 금융 자격증을 소지한 전문성과 윤리성을 지닌 전문가'
    },
    afpk: {
      title: 'AFPK',
      body: '한국 재무설계사 금융 자격증을 소지한 전문성과 윤리성을 지닌 전문가'
    },
    crossAgent: {
      title: '교차설계사',
      body: '메트라이프생명보험상품을 판매하는 손해보험소속설계사'
    }
  }

  const DutyCode = {
    MD: "5002",
    DS: "4006",
    DT: "4003",
    FPS: "1004",
  };

  const ellipsis = {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
  };

  useEffect(() => {
    trackingMenu("A0000010");
  }, []);

  useLayoutEffect(() => {
    //설계사 채널 구분 코드, 직책 구분
    const {financialConsultantChannelCode, businessPositionTypeCode} = agent?.crossAgentInfo;

    if (agent.profile.mainStoredImageFileName) {
      setVisualAttr({
        style: {
          background: `url("${getProfileImgSrc(
            agent.profile.mainStoredImageFileName
          )}") 0% 0% / cover`,
        },
      });
    } else {
      setVisualAttr({});
    }

    if (agent.profile.profileStoredImageFileName) {
      setProfileAttr({
        style: {
          background: `url("${getProfileImgSrc(
            agent.profile.profileStoredImageFileName
          )}") 0% 0% / cover`,
        },
      });
    } else {
      setProfileAttr({});
    }

    // 설계사 채널 구분코드가 '0111' 이고 직책 구분코드가 '1010' 인 경우 표시
    if (Object.is('0111', financialConsultantChannelCode)
      && Object.is('1010', businessPositionTypeCode)) {
      setIsCrossAgent(true);
    } else {
      setIsCrossAgent(false);
    }

  }, [agent]);

  const toggleCertificationBadge = (certificate) => {
    if (certificate) {
      setCertificateInfo(certificateInfoData[certificate])
    }
    setCertificateInfoOpen(!certificateInfoOpen);
  }

  function handleShowAward() {
    setShowAward(true);
  }

  function handleShowMiniProfilePop() {
    setShowMiniProfilePop(true);
  }

  function handleShowMainProfilePop() {
    setShowMainProfilePop(true);
  }

  function handleShowLicense() {
    setShowLicense(true);
  }

  const renderLicenseIcons = () => {
    return (
      <>
        {renderVarQualificationIcon()}
        {renderMDRTLicense()}
        {renderCertifiedFsrIcon()}
        {renderSeoulUniCertificationIcon()}
        {renderAfpkOrCfpIcon()}
        {renderCrossAgentIcon()}
        <Toast
          onClose={() => toggleCertificationBadge()}
          show={certificateInfoOpen}
          animation={false}
          className="share_pop license_pop"
        >
          <Toast.Header>
            <img
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{certificateInfo.title}</strong>
          </Toast.Header>
          <Toast.Body>
            {certificateInfo.body}
          </Toast.Body>
        </Toast>
      </>
    );
  }

  const renderMDRTLicense = () => {
    const code = agent.basicInfo.mdrtCode;

    switch (code) {
      case 'M':
        return (<span className="license_2" onClick={() => toggleCertificationBadge('mdrt')}>MDRT</span>);
      case 'C':
        return (<span className="license_3" onClick={() => toggleCertificationBadge('cot')}>COT</span>);
      case 'T':
        return (<span className="license_4" onClick={() => toggleCertificationBadge('tot')}>TOT</span>);
      default:
        return null
    }
  }

  const renderVarQualificationIcon = () => {
    const yn = agent.basicInfo.varQualYn;

    switch (yn) {
      case '1':
        return (<span className="license_1" onClick={() => toggleCertificationBadge('varQual')}>변액보험 판매관리사</span>)
      default:
        return null
    }
  }

  const renderCertifiedFsrIcon = () => {
    const yn = agent.basicInfo.certifiedFsrCode

    switch (yn) {
      case '1':
        return (<span className="license_5" onClick={() => toggleCertificationBadge('certifiedFsr')}>우수인증설계사</span>)
      default:
        return null
    }
  }

  const renderSeoulUniCertificationIcon = () => {
    const yn = agent.basicInfo.seoulUnivCertifiedCode

    switch (yn) {
      case 'E07':
        return (
          <span className="license_6" onClick={() => toggleCertificationBadge('seoulUnivCertifiedE07')}>서울대 은퇴설계 코칭 전문가 과정 수료</span>)
      case 'E37':
        return (<span className="license_7" onClick={() => toggleCertificationBadge('seoulUnivCertifiedE37')}>서울대 은퇴설계 전문가 과정</span>)
      default:
        return null
    }
  }

  const renderAfpkOrCfpIcon = () => {
    const cfpYn = agent.basicInfo.cfpYn
    const afpkYn = agent.basicInfo.afpkYn

    if (cfpYn === 'Y') {
      return (<span className="license_9" onClick={() => toggleCertificationBadge('cfp')}>CFP</span>);
    } else if (afpkYn === 'Y') {
      return (<span className="license_8" onClick={() => toggleCertificationBadge('afpk')}>AFPK</span>);
    } else {
      return null;
    }
  }

  /**
   * 교차 설계사 뱃지 확인
   */
  const renderCrossAgentIcon = () => {
    //설계사 채널 구분 코드, 직책 구분
    const {financialConsultantChannelCode, businessPositionTypeCode} = agent.crossAgentInfo;

    // 설계사 채널 구분코드가 '0111' 이고 직책 구분코드가 '1010' 인 경우 표시
    if (Object.is('0111', financialConsultantChannelCode)
      && Object.is('1010', businessPositionTypeCode)) {
      return (<span className="license_10" onClick={() => toggleCertificationBadge('crossAgent')}>교차 설계사</span>);
    } else {
      return null;
    }
  }

  /**
   * 설계사자격증에 등록되지 않은 자격 체크
   * @returns {number}
   */
  const checkLicenseIconsAmount = () => {
    let count = 0;
    const {financialConsultantChannelCode, businessPositionTypeCode} = agent.crossAgentInfo;

    agent.basicInfo.varQualYn === '1' && count++;
    ['M', 'C', 'T'].includes(agent.basicInfo.mdrtCode) && count++;
    agent.basicInfo.certifiedFsrCode === '1' && count++;
    ['E07', 'E37'].includes(agent.basicInfo.seoulUnivCertifiedCode) && count++;
    (agent.basicInfo.cfpYn === 'Y' || agent.basicInfo.afpkYn === 'Y') && count++

    if(Object.is('0111', financialConsultantChannelCode)
      && Object.is('1010', businessPositionTypeCode)) {
      count++;
    }

    return count;
  }

  return (
    <div>
      {/* PROFILE : START */}
      {/* 프로필 이미지 영역 className="visual"  background: url("../assets/images/visual.jpg") no-repeat; */}
      <UI_LD_01_01_MainProfileSlide>
        <div className={agentMultiProfiles && 0 < agentMultiProfiles.length ? "visual_info visual_seasonal_info" : "visual_info"}>
          <li>
            오늘부터 당신의 금융 파트너,
            <br/>
            {agent.basicInfo.businessChannelDivisionCode === "06"
              ? "메트라이프금융서비스"
              : "메트라이프"}
          </li>

          <li className="btn_area">
            <a
              href="https://cyber.MetLife.co.kr/app_download.html"
              target="_blank"
              rel="noreferrer"
            >
              <button>보험금 청구</button>
            </a>
            <Link to={`/${encodedAgentNumber}/counsel`}>
              <button>상담신청</button>
            </Link>
          </li>
        </div>
      </UI_LD_01_01_MainProfileSlide>
      {/* PROFILE : END */}
      <div className="profile_area">
        <div className="profile_name">
          {/* 20221006 이미지를 배경으로 처리함 */}
          <button onClick={() => handleShowMiniProfilePop()}>
            <p {...profileAttr} />
          </button>
          <strong>
            {agent.basicInfo.employeeName} {isCrossAgent && "교차설계사"}
            <Share/>
            <br/>
            <span>
              {agent.basicInfo.headquarterOrganizationName}
              {!Object.values(DutyCode)
                  .join("||")
                  .includes(agent.basicInfo.businessDutyCode) &&
                `\u00A0/\u00A0${agent.basicInfo.branchOfficeOrganizationName}`}
            </span>
          </strong>
        </div>
        <div className="profile_connect">
          <dl>
            <dt>연락처</dt>
            <dd>
              <a
                href={`tel:${agent.basicInfo.mobilePhoneNumber}`}
                title="Agent에게 전화하기"
                style={{color: "inherit"}}
              >
                {agent.basicInfo.mobilePhoneNumber &&
                  agent.basicInfo.mobilePhoneNumber
                    .replace(/[^0-9]/g, "")
                    .replace(/^(\d{3})(\d{4})(\d{4})$/, `$1-$2-$3`)}
              </a>
            </dd>
          </dl>
          <dl>
            <dt>카카오톡</dt>
            <dd>{agent.profile.kakaotalkId}</dd>
          </dl>
          <dl>
            <dt>이메일</dt>
            <dd>{agent.profile.emailText}</dd>
          </dl>
        </div>
      </div>
      <div className="met_mainarea">
        <div className="oneline">
          <p>한줄소개</p>
          <Fade bottom>
              <span style={{display: "block"}}>
                <ShowMoreText className="onelinebody" keepNewLines anchorClass="show-more-less-clickable moreText">
                  {agent.profile.introduceText
                    ? agent.profile.introduceText
                    : `고객의 일상을 함께하는 동반자가 되어 드리겠습니다.`}
              </ShowMoreText>
            </span>
          </Fade>
        </div>
        {
          (agent.profile.agentCertificateList.length !== 0 || checkLicenseIconsAmount() !== 0) && (
            <div className="box">
              <i/>
              <h2>보유 자격</h2>
              {agent.profile.agentCertificateList.length !== 0 && (
                <Button
                  className="me-2 mb-2 more"
                  bsPrefix="string"
                  onClick={() => handleShowLicense()}
                >
                  더보기
                </Button>
              )}
              <div className="license">
                {renderLicenseIcons()}
              </div>
              {agent.profile.agentCertificateList
                .sort((a, b) => b.certificateDate.slice(0, 4) - a.certificateDate.slice(0, 4) || b.createTimestamp - a.createTimestamp)
                .filter((e) => agent.profile.agentCertificateList.indexOf(e) < 5)
                .map((certification, index) => {
                  return (
                    <span style={{display: "block", padding: "7px 0"}}>
            <li
              key={index}
              style={{...ellipsis, WebkitLineClamp: 3, padding: "0"}}
            >
              <span>
                {formatDate(certification.certificateDate, "yyyy")}
              </span>
              <br/>
              {certification.certificateName}
            </li>
          </span>
                  );
                })}
            </div>
          )
        }
        {agent.profile.agentAwardList.length !== 0 && (
          <div className="box" style={{marginTop: '15px'}}>
            <i/>
            <h2>수상 경력</h2>

            <Button
              className="me-2 mb-2 more"
              bsPrefix="string"
              onClick={() => handleShowAward()}
            >
              더보기
            </Button>
            {agent.profile.agentAwardList
              .sort((a, b) => b.awardDate.slice(0, 4) - a.awardDate.slice(0, 4) || b.createTimestamp - a.createTimestamp)
              .filter((e) => agent.profile.agentAwardList.indexOf(e) < 5)
              .map((award, index) => {
                return (
                  <span style={{display: "block", padding: "7px 0"}}>
                    <li
                      key={index}
                      style={{...ellipsis, WebkitLineClamp: 3, padding: 0}}
                    >
                      <span>{formatDate(award.awardDate, "yyyy")}</span>
                      <br/>
                      {award.awardName}
                    </li>
                  </span>
                );
              })}
          </div>
        )}
      </div>
      {/* 20230926 프로필이미지 확대팝업 */}
      <MetOnModal
        show={showMiniProfilePop}
        setShow={setShowMiniProfilePop}
        title="mini프로필이미지"
        className="profile_big"
        body={
          <div>
            <img
              src={agent.profile.profileStoredImageFileName !== null ?
                getProfileImgSrc(agent.profile.profileStoredImageFileName)
                : miniProfileLogo}
              alt=""
              className="photo"
            />
          </div>
        }
      />
      <MetOnModal
        show={showMainProfilePop}
        setShow={setShowMainProfilePop}
        title="main프로필이미지"
        className="profile_big"
        body={
          <div>
            <img
              src={agent.profile.mainStoredImageFileName !== null ?
                getProfileImgSrc(agent.profile.mainStoredImageFileName)
                : mainProfileLogo}
              alt=""
              className="photo"
            />
          </div>
        }
      />
      {/* 수상경력 */}
      <MetOnModal
        show={showAward}
        setShow={setShowAward}
        title="수상 경력"
        body={<UI_02_02 awards={agent.profile.agentAwardList}/>}
      />
      {/* 자격 내역 */}
      <MetOnModal
        show={showLicense}
        setShow={setShowLicense}
        title="보유 자격"
        body={<UI_02_03 certificates={agent.profile.agentCertificateList}/>}
      />
    </div>
  );
};

export default UI_02_01;
