import {Link, useNavigate, useParams} from "react-router-dom";
import {Accordion, Button, Offcanvas} from "react-bootstrap";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {createSemmURL, FetchApi, getMenuHierarchy, getMenuMap, RootContext} from "../../common";

const UI_09_01 = function ({setKey}) {

    const {encodedAgentNumber} = useParams();
    const navigate = useNavigate();

    const {setMenuMap, agent, isGAManager} = useContext(RootContext);

    useEffect(() => {
        retrieveMenu();
    }, []);

    const link = useMemo(() => createSemmURL(agent), [ agent ])

    // 전체메뉴
    const [show, setShow] = useState(false);
    const handleClose = (menu) => {
        setShow(false);

        if (menu !== undefined) {
            if (menu.menuCode === 'A0000041') {
                setKey('home');
            }
            else if (menu.menuCode === 'A0000042') {
                setKey('profile');
            }
        }
    }
    const handleShow = () => setShow(true);

    const [menuHierarchy, setMenuHierarchy] = useState();

    const retrieveMenu = async () => {
        const {item} = await FetchApi.get('/api/meton/channel/menu', {
            sort: 'sortSequenceNumber:asc,menuCode:asc,menuLevelValue:asc',
            showIndicator: 1,
            urlText: encodedAgentNumber,
        });

        const hierarchy = getMenuHierarchy(item);
        const map = getMenuMap(item);

        setMenuMap(map);
        setMenuHierarchy(hierarchy);
    }


    const isOutsideLink = (value) => {
        return value.startsWith('http');
    }

    return (
        <div className="met_header">
            <div className="nav">
                <Link to={`/${encodedAgentNumber}`}>
                    <h1>
                        {/* 20221006 메트라이프 금융센터일 경우 <Button className="gnb_logo gnb_logo_mfs"> 로 gnb_logo_mfs 클래스 추가 되도록 */}
                        <Button className={`gnb_logo ${agent.basicInfo.businessChannelDivisionCode === '06'?'gnb_logo_mfs': ''}`}>
                            <span className="forWa">MetLife</span>
                        </Button>
                    </h1>
                </Link>
                {!isGAManager(agent.basicInfo.businessDutyCode) && <div className="menu_btn">
                    <>
                        <Button onClick={handleShow}>
                            <a href="#" className="forWa">
                                전체메뉴 보기
                            </a>
                        </Button>

                        <Offcanvas
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                {/*20221006 메트라이프 금융센터일 경우 <Offcanvas.Title className="offcanvas-title_mfs"> 로 offcanvas-title_mfs 클래스 추가 되도록 */}
                                <Offcanvas.Title
                                    className={agent.basicInfo.businessChannelDivisionCode === '06' ? 'offcanvas-title_mfs' : ''}
                                    onClick={() => {
                                        navigate(`/${encodedAgentNumber}`)
                                        setShow(false);
                                    }}>로고</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Accordion>
                                    {menuHierarchy && menuHierarchy.map((menu, index) => {
                                        return (
                                            <Accordion.Item eventKey={index} key={`menu-${index}`}>
                                                <Accordion.Header>
                                                    {
                                                        isOutsideLink(menu.screenPathText) ?
                                                            <a
                                                                href={menu.menuName !== '온라인 보험' ? menu.screenPathText : link}
                                                                target="_blank"
                                                                className="menu_only"
                                                                rel="noreferrer"
                                                            >
                                                                {menu.menuName}
                                                            </a>
                                                            :
                                                            menu.children ?
                                                                <span className="accordion-button2">
                                                                    {menu.menuName}
                                                                </span>
                                                                :
                                                                <Link
                                                                    to={`/${encodedAgentNumber}${menu.screenPathText}`}
                                                                    className="menu_only"
                                                                    onClick={() => handleClose(menu)}
                                                                >
                                                                    {menu.menuName}
                                                                </Link>
                                                    }
                                                </Accordion.Header>
                                                {menu.children &&
                                                <Accordion.Body>
                                                    {menu.children
                                                        .filter((sub) => !(agent.basicInfo.businessChannelDivisionCode === '06' && sub.menuName === 'Global Sales Professional'))
                                                        .map((sub, index) => {
                                                            return isOutsideLink(sub.screenPathText) ?
                                                                <a
                                                                    href={sub.menuName !== '온라인 보험' ? sub.screenPathText : `${agent.agentUrl[0].host}${agent.agentUrl[0].url}`}
                                                                    target="_blank"
                                                                    className="menu_only"
                                                                    rel="noreferrer"
                                                                    key={`sub-${index}`}
                                                                >
                                                                    {sub.menuName}
                                                                </a>
                                                                :
                                                                <Link
                                                                    to={sub.menuName !== '메트라이프 연금STORY' ? `/${encodedAgentNumber}${sub.screenPathText}` : `/${encodedAgentNumber}${sub.screenPathText}?category=story`}
                                                                    className="menu_only"
                                                                    onClick={() => handleClose(sub)}
                                                                    key={`sub-${index}`}
                                                                >
                                                                    {sub.menuName}
                                                                </Link>
                                                        })}
                                                </Accordion.Body>
                                                }
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </>
                </div>}
            </div>
        </div>
    );
};

export default UI_09_01;