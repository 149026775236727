/**
 * 메인 멀티 프로필 슬라이드
 * @constructor
 */
import React, {useContext, useLayoutEffect, useState} from "react";
import Slider from "react-slick";
import {getProfileImgSrc, MetOnModal, RootContext} from "../../common";
import mainProfileLogo from "../../assets/images/visual.jpg";

const UI_LD_01_01_MainProfileSlide = (props) => {
  const {children} = props;
  const {agent, agentMultiProfiles} = useContext(RootContext);
  const [selectPopupImage, setSelectPopupImage] = useState("");
  const [showMainProfilePop, setShowMainProfilePop] = useState(false);
  const [visualAttr, setVisualAttr] = useState({});

  let settings = {
    dots: agentMultiProfiles.length > 1,
    infinite: true,
    speed: 210,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
    autoplay: true,
  };

  /**
   * 메인 프로필 이미지 팝업
   */
  const handleShowMainProfilePop = (storedFileName) => {

    let cleanProfileSrc;

    if (storedFileName) {
      cleanProfileSrc = getProfileImgSrc(storedFileName);
    } else {
      cleanProfileSrc = mainProfileLogo;
    }

    if (agentMultiProfiles && 0 < agentMultiProfiles.length) {
      setSelectPopupImage(cleanProfileSrc);
    } else {
      if (cleanProfileSrc) {
        setSelectPopupImage(cleanProfileSrc);
      } else {
        setSelectPopupImage(mainProfileLogo);
      }
    }

    setShowMainProfilePop(true);
  }

  /**
   * 초기 설정
   */
  useLayoutEffect(() => {
    if (!Object.is(agent.profile?.mainStoredImageFileName, "dummyValue")) {
      if (!agentMultiProfiles || agentMultiProfiles.length <= 0) {
        if (agent.profile.mainStoredImageFileName) {
          setVisualAttr({
            style: {
              background: `url("${getProfileImgSrc(
                agent.profile.mainStoredImageFileName
              )}") 0% 0% / cover`,
            },
          });
        } else {
          setVisualAttr({
            style: {
              background: `url(${mainProfileLogo}) 0% 0% / cover`,
            },
          });
        }
      }
    }
  }, [agentMultiProfiles]);

  return (
    <>
      {
        agentMultiProfiles && 0 < agentMultiProfiles.length ?
          <div className="seasonal_container">
            <Slider {...settings}>
              {
                agentMultiProfiles?.map((item) => {
                  return (
                    <div key={`"div-"${item.multiProfileContentId}`}
                         className="visual_seasonal"
                         onClick={() => handleShowMainProfilePop(item.multiProfileStoredImageFileName)}
                    >
                      {
                        <img
                          src={`${getProfileImgSrc(item.multiProfileStoredImageFileName)} 0% 0% / cover`}
                          alt=""
                          key={`"img-"${item.multiProfileContentId}`}
                        />
                      }
                    </div>
                  )
                })
              }
            </Slider>
            {children}
          </div>
          : <div className="visual" {...visualAttr}
                 onClick={() => handleShowMainProfilePop(agent.profile.mainStoredImageFileName)}>
            {children}
          </div>
      }
      {/* 20230926 프로필이미지 확대팝업 */}
      <MetOnModal
        show={showMainProfilePop}
        setShow={setShowMainProfilePop}
        title="main프로필이미지"
        className="profile_big"
        body={
          <div>
            <img
              src={selectPopupImage}
              alt=""
              className="photo"
            />
          </div>
        }
      />
    </>
  )
}

export default UI_LD_01_01_MainProfileSlide;