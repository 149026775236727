const xibmClientId = process.env.REACT_APP_X_IBM_CLIENT_ID;

class FetchApi {
    static post(
        url,
        body = {},
        headers = {},
    ) {
        return FetchApi._useFetch(url, 'POST', body, headers);
    }

    static async get(
        url,
        params = {},
        headers = {}
    ) {
        return FetchApi._useFetch(FetchApi._joinUrlAndParams(url, params), 'GET', {}, headers);
    }

    static async delete(
        url,
        params = {},
        headers = {}
    ) {
        return FetchApi._useFetch(FetchApi._joinUrlAndParams(url, params), 'DELETE', {}, headers);
    }

    static put(
        url,
        body = {},
        headers = {},
    ) {
        return FetchApi._useFetch(url, 'PUT', body, headers);
    }

    static async _useFetch(
        url = '',
        method = 'GET',
        body = {},
        headers = {},
    ) {
        const option = {
            method: method,
            headers: {
                ...headers,
                'Content-Type': 'application/json; charset=UTF-8',
                'x-ibm-client-id': xibmClientId,
            },
        }

        if (Object.keys(body).length !== 0) {
            option.body = JSON.stringify({item: body});
        }

        // const data = await fetch(url, option)
        //     .then((response) => response.json());

        return  await fetch(url, option).then(async (response) => {

            const data = await response.json()

            if (response.status === 500) {
                throw data.errorMessage;
            }

            if (!data.isSuccess) {
                throw data.errorMessage;
            }

            return data;
        })
    }

    static _joinUrlAndParams(url, params) {
        const queryParams = Object.entries(params).map((entry) => entry[0] + '=' + entry[1]).join('&')
        return url + (queryParams ? '?' + queryParams : '');
    }
}

export default FetchApi;