import {Modal} from "react-bootstrap";

const IdentifierTerms = ({telecom}) => {

    const showIdentifierTerms = () => {
        switch (telecom) {
            case 'SKT':
                return showIdentifierTermsSkt();
            case 'KTF':
                return showIdentifierTermsKt();
            case 'LGT':
                return showIdentifierTermsLgu();
            case 'SKM':
                return showIdentifierTermsSktMvno();
            case 'KTM':
                return showIdentifierTermsKtMvno();
            case 'LGM':
                return showIdentifierTermsLguMvno();
            default:
                return null;
        }
    }

    const showIdentifierTermsSkt = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>고유식별 처리동의</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>통신사(SK 텔레콤㈜, ㈜KT, LGU+㈜) 귀중</strong>
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜(이하 ‘회사’라 함)가
                            휴대폰본인확인서비스(이하 ‘서비스’라 함)를 제공하기 위해
                            고유식별정보를 다음과 같이 제 3 자에게 제공 및 처리하는 것에
                            동의합니다.
                            <br />
                            <br />
                            고유식별정보를 제공받는 자<br />
                            <br />
                            <div className="dash">
                                <li>에스케이텔레콤㈜ : SCI 평가정보㈜, NICE 평가정보㈜</li>
                                <li>㈜케이티 : SCI 평가정보㈜, 코리아크레딧뷰로㈜</li>
                                <li>엘지유플러스㈜ : SCI 평가정보㈜</li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 목적
                            <br />
                            <div className="dash">
                                <li>
                                    연계정보(CI)와 중복가입확인정보(DI)의 생성 및‘회사’ 제공
                                </li>
                            </div>
                            <br />
                            고유식별정보
                            <br />
                            <div className="dash">
                                <li>
                                    ‘회사’가 보유하고 있는 고객의 주민등록번호 및 외국인등록번호
                                </li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 보유 및 이용기간
                            <br />
                            <div className="dash">
                                <li>연계정보(CI), 중복가입확인정보(DI)생성 후 즉시 폐기</li>
                            </div>
                            <br />
                            <strong>SCI 평가정보 귀중</strong>
                            <br />
                            <br />
                            SCI 평가정보㈜(이하 ‘회사’라 함)가 에스케이텔레콤㈜, ㈜케이티,
                            LG 유플러스㈜, 한국모바일인증㈜ 등의 업무 대행을 통해 제공하는
                            휴대폰 본인확인서비스와 관련하여 이용자로 부터 수집한
                            고유식별정보를 이용 및 처리하거나 제 3 자에게 제공하고자 할
                            때에는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 및
                            ‘개인정보보호법’에 따라서 이용자로 부터 동의를 받아야 합니다.
                            <br />
                            <br />
                            제 1 조 고유식별정보의 이용 및 처리 목적
                            <br />
                            <br />
                            ‘회사’는 이용자의 고유식별정보를 아래의 목적으로 이용 및 처리할
                            수 있습니다.
                            <br />
                            <br />
                            1. 정보통신망법 제 23 조에서 정한 본인확인서비스 제공
                            <br />
                            2. 부정 이용 방지 및 수사의뢰
                            <br />
                            3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)
                            <br />
                            <br />
                            제 2 조 고유식별정보의 이용 및 처리 내용
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜ 등의 통신사 또는
                            통신사의 대행업무를 수행하는 사업자에 정보를 전송하여
                            방송통신위원회에서 고시로 지정한 휴대폰본인 확인기관을 통해
                            본인여부를 확인하고 결과를 제공받기 위해 고유식별정보가 이용 및
                            처리됩니다.
                            <br />
                            <div className="dash">
                                <li>
                                    이용 및 처리되는 고유식별정보 : 주민등록번호(내국인),
                                    외국인등록번호(외국인)
                                </li>
                            </div>
                            <br />제 3 조 고유식별정보의 보유 및 이용 기간
                            <br />
                            <br />
                            고유식별정보의 수집, 이용 및 제공 목적이 달성 된 후에는 해당
                            정보를 지체 없이 파기합니다. 단, ‘개인정보보호법’, ‘정보통신망
                            이용 촉진 및 정보보호 등에 관한 법률’, ‘신용정보의 이용 및
                            보호에 관한 법률’등 기타 관련법령의 규정에 의하여 법률관계의
                            확인 등을 이유로 특정한 기간 동안 보유하여야 할 필요가 있을
                            경우에는 아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            <div className="dash">
                                <li>
                                    신용정보의 이용 및 보호에 관한 법률에 의거 정보 보존 기간 :
                                    3 년
                                </li>
                                <li>계약 또는 청약철회 등에 관한 기록 : 5 년</li>
                                <li>대금결제 및 재화 등의 공급에 관한 기록 : 5 년</li>
                                <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년</li>
                                <li>기타 다른 법률의 규정에 의하여 보유가 허용되는 기간</li>
                            </div>
                            <br /> ※ 상기 고유식별정보 처리에 대한 내용에 동의하지 않으실
                            경우 ‘서비스’를 이용할 수 없습니다. 상기 내용과 같이
                            고유식별정보의 이용 및 처리에 동의합니다.
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showIdentifierTermsKt = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>고유식별 처리동의</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>통신사(SK 텔레콤㈜, ㈜KT, LGU+㈜) 귀중</strong>
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜(이하 ‘회사’라 함)가
                            휴대폰본인확인서비스(이하 ‘서비스’라 함)를 제공하기 위해
                            고유식별정보를 다음과 같이 제 3 자에게 제공 및 처리하는 것에
                            동의합니다.
                            <br />
                            <br />
                            고유식별정보를 제공받는 자<br />
                            <br />
                            <div className="dash">
                                <li>에스케이텔레콤㈜ : SCI 평가정보㈜, NICE 평가정보㈜</li>
                                <li>㈜케이티 : SCI 평가정보㈜, 코리아크레딧뷰로㈜</li>
                                <li>엘지유플러스㈜ : SCI 평가정보㈜</li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 목적
                            <br />
                            <div className="dash">
                                <li>
                                    연계정보(CI)와 중복가입확인정보(DI)의 생성 및‘회사’ 제공
                                </li>
                            </div>
                            <br />
                            고유식별정보
                            <br />
                            <div className="dash">
                                <li>
                                    ‘회사’가 보유하고 있는 고객의 주민등록번호 및 외국인등록번호
                                </li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 보유 및 이용기간
                            <br />
                            <div className="dash">
                                <li>연계정보(CI), 중복가입확인정보(DI)생성 후 즉시 폐기</li>
                            </div>
                            <br />
                            <strong>SCI 평가정보 귀중</strong>
                            <br />
                            <br />
                            SCI 평가정보㈜(이하 ‘회사’라 함)가 에스케이텔레콤㈜, ㈜케이티,
                            LG 유플러스㈜, 한국모바일인증㈜ 등의 업무 대행을 통해 제공하는
                            휴대폰 본인확인서비스와 관련하여 이용자로 부터 수집한
                            고유식별정보를 이용 및 처리하거나 제 3 자에게 제공하고자 할
                            때에는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 및
                            ‘개인정보보호법’에 따라서 이용자로 부터 동의를 받아야 합니다.
                            <br />
                            <br />
                            제 1 조 고유식별정보의 이용 및 처리 목적
                            <br />
                            <br />
                            ‘회사’는 이용자의 고유식별정보를 아래의 목적으로 이용 및 처리할
                            수 있습니다.
                            <br />
                            <br />
                            1. 정보통신망법 제 23 조에서 정한 본인확인서비스 제공
                            <br />
                            2. 부정 이용 방지 및 수사의뢰
                            <br />
                            3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)
                            <br />
                            <br />
                            제 2 조 고유식별정보의 이용 및 처리 내용
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜ 등의 통신사 또는
                            통신사의 대행업무를 수행하는 사업자에 정보를 전송하여
                            방송통신위원회에서 고시로 지정한 휴대폰본인 확인기관을 통해
                            본인여부를 확인하고 결과를 제공받기 위해 고유식별정보가 이용 및
                            처리됩니다.
                            <br />
                            <div className="dash">
                                <li>
                                    이용 및 처리되는 고유식별정보 : 주민등록번호(내국인),
                                    외국인등록번호(외국인)
                                </li>
                            </div>
                            <br />제 3 조 고유식별정보의 보유 및 이용 기간
                            <br />
                            <br />
                            고유식별정보의 수집, 이용 및 제공 목적이 달성 된 후에는 해당
                            정보를 지체 없이 파기합니다. 단, ‘개인정보보호법’, ‘정보통신망
                            이용 촉진 및 정보보호 등에 관한 법률’, ‘신용정보의 이용 및
                            보호에 관한 법률’등 기타 관련법령의 규정에 의하여 법률관계의
                            확인 등을 이유로 특정한 기간 동안 보유하여야 할 필요가 있을
                            경우에는 아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            <div className="dash">
                                <li>
                                    신용정보의 이용 및 보호에 관한 법률에 의거 정보 보존 기간 :
                                    3 년
                                </li>
                                <li>계약 또는 청약철회 등에 관한 기록 : 5 년</li>
                                <li>대금결제 및 재화 등의 공급에 관한 기록 : 5 년</li>
                                <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년</li>
                                <li>기타 다른 법률의 규정에 의하여 보유가 허용되는 기간</li>
                            </div>
                            <br /> ※ 상기 고유식별정보 처리에 대한 내용에 동의하지 않으실
                            경우 ‘서비스’를 이용할 수 없습니다. 상기 내용과 같이
                            고유식별정보의 이용 및 처리에 동의합니다.
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showIdentifierTermsLgu = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>고유식별 처리동의</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>통신사(SK 텔레콤㈜, ㈜KT, LGU+㈜) 귀중</strong>
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜(이하 ‘회사’라 함)가
                            휴대폰본인확인서비스(이하 ‘서비스’라 함)를 제공하기 위해
                            고유식별정보를 다음과 같이 제 3 자에게 제공 및 처리하는 것에
                            동의합니다.
                            <br />
                            <br />
                            고유식별정보를 제공받는 자<br />
                            <br />
                            <div className="dash">
                                <li>에스케이텔레콤㈜ : SCI 평가정보㈜, NICE 평가정보㈜</li>
                                <li>㈜케이티 : SCI 평가정보㈜, 코리아크레딧뷰로㈜</li>
                                <li>엘지유플러스㈜ : SCI 평가정보㈜</li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 목적
                            <br />
                            <div className="dash">
                                <li>
                                    연계정보(CI)와 중복가입확인정보(DI)의 생성 및‘회사’ 제공
                                </li>
                            </div>
                            <br />
                            고유식별정보
                            <br />
                            <div className="dash">
                                <li>
                                    ‘회사’가 보유하고 있는 고객의 주민등록번호 및 외국인등록번호
                                </li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 보유 및 이용기간
                            <br />
                            <div className="dash">
                                <li>연계정보(CI), 중복가입확인정보(DI)생성 후 즉시 폐기</li>
                            </div>
                            <br />
                            <strong>SCI 평가정보 귀중</strong>
                            <br />
                            <br />
                            SCI 평가정보㈜(이하 ‘회사’라 함)가 에스케이텔레콤㈜, ㈜케이티,
                            LG 유플러스㈜, 한국모바일인증㈜ 등의 업무 대행을 통해 제공하는
                            휴대폰 본인확인서비스와 관련하여 이용자로 부터 수집한
                            고유식별정보를 이용 및 처리하거나 제 3 자에게 제공하고자 할
                            때에는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 및
                            ‘개인정보보호법’에 따라서 이용자로 부터 동의를 받아야 합니다.
                            <br />
                            <br />
                            제 1 조 고유식별정보의 이용 및 처리 목적
                            <br />
                            <br />
                            ‘회사’는 이용자의 고유식별정보를 아래의 목적으로 이용 및 처리할
                            수 있습니다.
                            <br />
                            <br />
                            1. 정보통신망법 제 23 조에서 정한 본인확인서비스 제공
                            <br />
                            2. 부정 이용 방지 및 수사의뢰
                            <br />
                            3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)
                            <br />
                            <br />
                            제 2 조 고유식별정보의 이용 및 처리 내용
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜ 등의 통신사 또는
                            통신사의 대행업무를 수행하는 사업자에 정보를 전송하여
                            방송통신위원회에서 고시로 지정한 휴대폰본인 확인기관을 통해
                            본인여부를 확인하고 결과를 제공받기 위해 고유식별정보가 이용 및
                            처리됩니다.
                            <br />
                            <div className="dash">
                                <li>
                                    이용 및 처리되는 고유식별정보 : 주민등록번호(내국인),
                                    외국인등록번호(외국인)
                                </li>
                            </div>
                            <br />제 3 조 고유식별정보의 보유 및 이용 기간
                            <br />
                            <br />
                            고유식별정보의 수집, 이용 및 제공 목적이 달성 된 후에는 해당
                            정보를 지체 없이 파기합니다. 단, ‘개인정보보호법’, ‘정보통신망
                            이용 촉진 및 정보보호 등에 관한 법률’, ‘신용정보의 이용 및
                            보호에 관한 법률’등 기타 관련법령의 규정에 의하여 법률관계의
                            확인 등을 이유로 특정한 기간 동안 보유하여야 할 필요가 있을
                            경우에는 아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            <div className="dash">
                                <li>
                                    신용정보의 이용 및 보호에 관한 법률에 의거 정보 보존 기간 :
                                    3 년
                                </li>
                                <li>계약 또는 청약철회 등에 관한 기록 : 5 년</li>
                                <li>대금결제 및 재화 등의 공급에 관한 기록 : 5 년</li>
                                <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년</li>
                                <li>기타 다른 법률의 규정에 의하여 보유가 허용되는 기간</li>
                            </div>
                            <br /> ※ 상기 고유식별정보 처리에 대한 내용에 동의하지 않으실
                            경우 ‘서비스’를 이용할 수 없습니다. 상기 내용과 같이
                            고유식별정보의 이용 및 처리에 동의합니다.
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showIdentifierTermsSktMvno = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>고유식별 처리동의</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>통신사(SK 텔레콤㈜, ㈜KT, LGU+㈜) 귀중</strong>
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜(이하 ‘회사’라 함)가
                            휴대폰본인확인서비스(이하 ‘서비스’라 함)를 제공하기 위해
                            고유식별정보를 다음과 같이 제 3 자에게 제공 및 처리하는 것에
                            동의합니다.
                            <br />
                            <br />
                            고유식별정보를 제공받는 자<br />
                            <br />
                            <div className="dash">
                                <li>에스케이텔레콤㈜ : SCI 평가정보㈜, NICE 평가정보㈜</li>
                                <li>㈜케이티 : SCI 평가정보㈜, 코리아크레딧뷰로㈜</li>
                                <li>엘지유플러스㈜ : SCI 평가정보㈜</li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 목적
                            <br />
                            <div className="dash">
                                <li>
                                    연계정보(CI)와 중복가입확인정보(DI)의 생성 및‘회사’ 제공
                                </li>
                            </div>
                            <br />
                            고유식별정보
                            <br />
                            <div className="dash">
                                <li>
                                    ‘회사’가 보유하고 있는 고객의 주민등록번호 및 외국인등록번호
                                </li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 보유 및 이용기간
                            <br />
                            <div className="dash">
                                <li>연계정보(CI), 중복가입확인정보(DI)생성 후 즉시 폐기</li>
                            </div>
                            <br />
                            <strong>SCI 평가정보 귀중</strong>
                            <br />
                            <br />
                            SCI 평가정보㈜(이하 ‘회사’라 함)가 에스케이텔레콤㈜, ㈜케이티,
                            LG 유플러스㈜, 한국모바일인증㈜ 등의 업무 대행을 통해 제공하는
                            휴대폰 본인확인서비스와 관련하여 이용자로 부터 수집한
                            고유식별정보를 이용 및 처리하거나 제 3 자에게 제공하고자 할
                            때에는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 및
                            ‘개인정보보호법’에 따라서 이용자로 부터 동의를 받아야 합니다.
                            <br />
                            <br />
                            제 1 조 고유식별정보의 이용 및 처리 목적
                            <br />
                            <br />
                            ‘회사’는 이용자의 고유식별정보를 아래의 목적으로 이용 및 처리할
                            수 있습니다.
                            <br />
                            <br />
                            1. 정보통신망법 제 23 조에서 정한 본인확인서비스 제공
                            <br />
                            2. 부정 이용 방지 및 수사의뢰
                            <br />
                            3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)
                            <br />
                            <br />
                            제 2 조 고유식별정보의 이용 및 처리 내용
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜ 등의 통신사 또는
                            통신사의 대행업무를 수행하는 사업자에 정보를 전송하여
                            방송통신위원회에서 고시로 지정한 휴대폰본인 확인기관을 통해
                            본인여부를 확인하고 결과를 제공받기 위해 고유식별정보가 이용 및
                            처리됩니다.
                            <br />
                            <div className="dash">
                                <li>
                                    이용 및 처리되는 고유식별정보 : 주민등록번호(내국인),
                                    외국인등록번호(외국인)
                                </li>
                            </div>
                            <br />제 3 조 고유식별정보의 보유 및 이용 기간
                            <br />
                            <br />
                            고유식별정보의 수집, 이용 및 제공 목적이 달성 된 후에는 해당
                            정보를 지체 없이 파기합니다. 단, ‘개인정보보호법’, ‘정보통신망
                            이용 촉진 및 정보보호 등에 관한 법률’, ‘신용정보의 이용 및
                            보호에 관한 법률’등 기타 관련법령의 규정에 의하여 법률관계의
                            확인 등을 이유로 특정한 기간 동안 보유하여야 할 필요가 있을
                            경우에는 아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            <div className="dash">
                                <li>
                                    신용정보의 이용 및 보호에 관한 법률에 의거 정보 보존 기간 :
                                    3 년
                                </li>
                                <li>계약 또는 청약철회 등에 관한 기록 : 5 년</li>
                                <li>대금결제 및 재화 등의 공급에 관한 기록 : 5 년</li>
                                <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년</li>
                                <li>기타 다른 법률의 규정에 의하여 보유가 허용되는 기간</li>
                            </div>
                            <br /> ※ 상기 고유식별정보 처리에 대한 내용에 동의하지 않으실
                            경우 ‘서비스’를 이용할 수 없습니다. 상기 내용과 같이
                            고유식별정보의 이용 및 처리에 동의합니다.
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showIdentifierTermsKtMvno  = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>고유식별 처리동의</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>통신사(SK 텔레콤㈜, ㈜KT, LGU+㈜) 귀중</strong>
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜(이하 ‘회사’라 함)가
                            휴대폰본인확인서비스(이하 ‘서비스’라 함)를 제공하기 위해
                            고유식별정보를 다음과 같이 제 3 자에게 제공 및 처리하는 것에
                            동의합니다.
                            <br />
                            <br />
                            고유식별정보를 제공받는 자<br />
                            <br />
                            <div className="dash">
                                <li>에스케이텔레콤㈜ : SCI 평가정보㈜, NICE 평가정보㈜</li>
                                <li>㈜케이티 : SCI 평가정보㈜, 코리아크레딧뷰로㈜</li>
                                <li>엘지유플러스㈜ : SCI 평가정보㈜</li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 목적
                            <br />
                            <div className="dash">
                                <li>
                                    연계정보(CI)와 중복가입확인정보(DI)의 생성 및‘회사’ 제공
                                </li>
                            </div>
                            <br />
                            고유식별정보
                            <br />
                            <div className="dash">
                                <li>
                                    ‘회사’가 보유하고 있는 고객의 주민등록번호 및 외국인등록번호
                                </li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 보유 및 이용기간
                            <br />
                            <div className="dash">
                                <li>연계정보(CI), 중복가입확인정보(DI)생성 후 즉시 폐기</li>
                            </div>
                            <br />
                            <strong>SCI 평가정보 귀중</strong>
                            <br />
                            <br />
                            SCI 평가정보㈜(이하 ‘회사’라 함)가 에스케이텔레콤㈜, ㈜케이티,
                            LG 유플러스㈜, 한국모바일인증㈜ 등의 업무 대행을 통해 제공하는
                            휴대폰 본인확인서비스와 관련하여 이용자로 부터 수집한
                            고유식별정보를 이용 및 처리하거나 제 3 자에게 제공하고자 할
                            때에는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 및
                            ‘개인정보보호법’에 따라서 이용자로 부터 동의를 받아야 합니다.
                            <br />
                            <br />
                            제 1 조 고유식별정보의 이용 및 처리 목적
                            <br />
                            <br />
                            ‘회사’는 이용자의 고유식별정보를 아래의 목적으로 이용 및 처리할
                            수 있습니다.
                            <br />
                            <br />
                            1. 정보통신망법 제 23 조에서 정한 본인확인서비스 제공
                            <br />
                            2. 부정 이용 방지 및 수사의뢰
                            <br />
                            3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)
                            <br />
                            <br />
                            제 2 조 고유식별정보의 이용 및 처리 내용
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜ 등의 통신사 또는
                            통신사의 대행업무를 수행하는 사업자에 정보를 전송하여
                            방송통신위원회에서 고시로 지정한 휴대폰본인 확인기관을 통해
                            본인여부를 확인하고 결과를 제공받기 위해 고유식별정보가 이용 및
                            처리됩니다.
                            <br />
                            <div className="dash">
                                <li>
                                    이용 및 처리되는 고유식별정보 : 주민등록번호(내국인),
                                    외국인등록번호(외국인)
                                </li>
                            </div>
                            <br />제 3 조 고유식별정보의 보유 및 이용 기간
                            <br />
                            <br />
                            고유식별정보의 수집, 이용 및 제공 목적이 달성 된 후에는 해당
                            정보를 지체 없이 파기합니다. 단, ‘개인정보보호법’, ‘정보통신망
                            이용 촉진 및 정보보호 등에 관한 법률’, ‘신용정보의 이용 및
                            보호에 관한 법률’등 기타 관련법령의 규정에 의하여 법률관계의
                            확인 등을 이유로 특정한 기간 동안 보유하여야 할 필요가 있을
                            경우에는 아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            <div className="dash">
                                <li>
                                    신용정보의 이용 및 보호에 관한 법률에 의거 정보 보존 기간 :
                                    3 년
                                </li>
                                <li>계약 또는 청약철회 등에 관한 기록 : 5 년</li>
                                <li>대금결제 및 재화 등의 공급에 관한 기록 : 5 년</li>
                                <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년</li>
                                <li>기타 다른 법률의 규정에 의하여 보유가 허용되는 기간</li>
                            </div>
                            <br /> ※ 상기 고유식별정보 처리에 대한 내용에 동의하지 않으실
                            경우 ‘서비스’를 이용할 수 없습니다. 상기 내용과 같이
                            고유식별정보의 이용 및 처리에 동의합니다.
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showIdentifierTermsLguMvno = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>고유식별 처리동의</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>통신사(SK 텔레콤㈜, ㈜KT, LGU+㈜) 귀중</strong>
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜(이하 ‘회사’라 함)가
                            휴대폰본인확인서비스(이하 ‘서비스’라 함)를 제공하기 위해
                            고유식별정보를 다음과 같이 제 3 자에게 제공 및 처리하는 것에
                            동의합니다.
                            <br />
                            <br />
                            고유식별정보를 제공받는 자<br />
                            <br />
                            <div className="dash">
                                <li>에스케이텔레콤㈜ : SCI 평가정보㈜, NICE 평가정보㈜</li>
                                <li>㈜케이티 : SCI 평가정보㈜, 코리아크레딧뷰로㈜</li>
                                <li>엘지유플러스㈜ : SCI 평가정보㈜</li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 목적
                            <br />
                            <div className="dash">
                                <li>
                                    연계정보(CI)와 중복가입확인정보(DI)의 생성 및‘회사’ 제공
                                </li>
                            </div>
                            <br />
                            고유식별정보
                            <br />
                            <div className="dash">
                                <li>
                                    ‘회사’가 보유하고 있는 고객의 주민등록번호 및 외국인등록번호
                                </li>
                            </div>
                            <br />
                            고유식별정보를 제공받는 자의 보유 및 이용기간
                            <br />
                            <div className="dash">
                                <li>연계정보(CI), 중복가입확인정보(DI)생성 후 즉시 폐기</li>
                            </div>
                            <br />
                            <strong>SCI 평가정보 귀중</strong>
                            <br />
                            <br />
                            SCI 평가정보㈜(이하 ‘회사’라 함)가 에스케이텔레콤㈜, ㈜케이티,
                            LG 유플러스㈜, 한국모바일인증㈜ 등의 업무 대행을 통해 제공하는
                            휴대폰 본인확인서비스와 관련하여 이용자로 부터 수집한
                            고유식별정보를 이용 및 처리하거나 제 3 자에게 제공하고자 할
                            때에는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 및
                            ‘개인정보보호법’에 따라서 이용자로 부터 동의를 받아야 합니다.
                            <br />
                            <br />
                            제 1 조 고유식별정보의 이용 및 처리 목적
                            <br />
                            <br />
                            ‘회사’는 이용자의 고유식별정보를 아래의 목적으로 이용 및 처리할
                            수 있습니다.
                            <br />
                            <br />
                            1. 정보통신망법 제 23 조에서 정한 본인확인서비스 제공
                            <br />
                            2. 부정 이용 방지 및 수사의뢰
                            <br />
                            3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)
                            <br />
                            <br />
                            제 2 조 고유식별정보의 이용 및 처리 내용
                            <br />
                            <br />
                            에스케이텔레콤㈜, ㈜케이티, 엘지유플러스㈜ 등의 통신사 또는
                            통신사의 대행업무를 수행하는 사업자에 정보를 전송하여
                            방송통신위원회에서 고시로 지정한 휴대폰본인 확인기관을 통해
                            본인여부를 확인하고 결과를 제공받기 위해 고유식별정보가 이용 및
                            처리됩니다.
                            <br />
                            <div className="dash">
                                <li>
                                    이용 및 처리되는 고유식별정보 : 주민등록번호(내국인),
                                    외국인등록번호(외국인)
                                </li>
                            </div>
                            <br />제 3 조 고유식별정보의 보유 및 이용 기간
                            <br />
                            <br />
                            고유식별정보의 수집, 이용 및 제공 목적이 달성 된 후에는 해당
                            정보를 지체 없이 파기합니다. 단, ‘개인정보보호법’, ‘정보통신망
                            이용 촉진 및 정보보호 등에 관한 법률’, ‘신용정보의 이용 및
                            보호에 관한 법률’등 기타 관련법령의 규정에 의하여 법률관계의
                            확인 등을 이유로 특정한 기간 동안 보유하여야 할 필요가 있을
                            경우에는 아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            <div className="dash">
                                <li>
                                    신용정보의 이용 및 보호에 관한 법률에 의거 정보 보존 기간 :
                                    3 년
                                </li>
                                <li>계약 또는 청약철회 등에 관한 기록 : 5 년</li>
                                <li>대금결제 및 재화 등의 공급에 관한 기록 : 5 년</li>
                                <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년</li>
                                <li>기타 다른 법률의 규정에 의하여 보유가 허용되는 기간</li>
                            </div>
                            <br /> ※ 상기 고유식별정보 처리에 대한 내용에 동의하지 않으실
                            경우 ‘서비스’를 이용할 수 없습니다. 상기 내용과 같이
                            고유식별정보의 이용 및 처리에 동의합니다.
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    return (
        showIdentifierTerms()
    );

}

export default IdentifierTerms;