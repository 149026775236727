export const tendencyPriority = {
    '0': '01',
    '1': '02',
    '2': '03',
    '3': '03',
    '4': '03',
    '0,1': '02',
    '0,2': '03',
    '0,3': '03',
    '0,4': '03',
    '1,2': '03',
    '1,3': '03',
    '1,4': '03',
    '2,3': '03',
    '2,4': '03',
    '3,4': '03',
    '0,1,2': '01',
    '0,1,3': '01',
    '0,1,4': '01',
    '0,2,3': '01',
    '0,2,4': '01',
    '1,2,3': '02',
    '1,3,4': '02',
    '2,3,4': '03',
    '0,3,4': '03',
    '1,2,4': '03',
    '0,1,2,3': '03',
    '0,1,2,4': '03',
    '0,2,3,4': '03',
    '1,2,3,4': '03',
    '0,1,3,4': '03',
    '0,1,2,3,4': '03',
}