import Carousel from "react-bootstrap/Carousel";
import React, {useContext, useEffect, useState} from "react";
import {FetchApi, formatDate, getContentImgSrc, RootContext, Share} from "../../common";
import {useParams} from "react-router-dom";

const UI_07_02_01 = function () {
    const params = useParams();

    const {setSelectedNewsMainImage, setSelectedNewsTitle} = useContext(RootContext);
    const [content, setContent] = useState({cardNewsFileList: []});

    useEffect(() => {
        retrieveContentById().then(({item}) => {
            setSelectedNewsMainImage(item.thumbnailStoredImageFileName);
            setSelectedNewsTitle(item.titleText);
        });
    }, []);

    const retrieveContentById = async () => {
        const result = await FetchApi.get(`/api/meton/channel/content/${params.contentId}`);
        setContent(Object.assign(result.item, {readCount: result.item.readCount + 1}));

        return result;
    };

    return (
        <>
            <div className="todayShareArea">
                <span>{formatDate(content.contentStartDate, 'yyyy.MM.DD')}</span>
                <Share/>
            </div>
            <div className="todayArea">
                <Carousel interval={null}>
                    {content && content.cardNewsFileList.map((file, index)=>
                        <Carousel.Item key={`card-${index}`}>
                            <img
                                className="d-block w-100"
                                src={getContentImgSrc(file.storedImageFileName)}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>
        </>
    );
};

export default UI_07_02_01;