import UI_01_01_CAMFS from "./UI_01_01_CAMFS";
import UI_01_01_GA from "./UI_01_01_GA";
import {useContext} from "react";
import {RootContext} from "../../common";

const UI_01_01 = () => {

  const { agent, isGAManager } = useContext(RootContext);

  const render = () => {
    if (isGAManager(agent.basicInfo.businessDutyCode)) {
      return (<UI_01_01_GA/>);
    } else {
      return (<UI_01_01_CAMFS/>);
    }
  }

  return render();
}

export default UI_01_01;