import React, {useContext, useEffect, useState} from "react";
import {FetchApi, formatDate, RootContext, Share} from "../../common";
import {useParams} from "react-router-dom";

const UI_07_02_02 = function () {

    const {contentId} = useParams();

    const {setSelectedNewsMainImage, setSelectedNewsTitle} = useContext(RootContext);
    const [content, setContent] = useState({});

    useEffect(() => {
        retrieveContentById().then(({item}) => {
            setSelectedNewsMainImage(item.thumbnailStoredImageFileName);
            setSelectedNewsTitle(item.titleText);
        });
    }, []);

    const retrieveContentById = async () => {
        const result = await FetchApi.get(`/api/meton/channel/content/${contentId}`);
        setContent(Object.assign(result.item, {readCount: result.item.readCount + 1}));

        return result;
    };

    return (
        <>
            <div className="todayShareArea">
                <span>{formatDate(content.contentStartDate, 'yyyy.MM.DD')}</span>
                <Share/>
            </div>
            <div className="todayArea newsletterimg">
                <div dangerouslySetInnerHTML={{__html: content.contentText}}/>
            </div>
        </>
    );
};

export default UI_07_02_02;