import {Modal} from "react-bootstrap";

const PrivacyTerms = ({telecom}) => {

    const showPrivacyTerms = () => {
        switch (telecom) {
            case 'SKT':
                return showPrivacyTermsSkt();
            case 'KTF':
                return showPrivacyTermsKt();
            case 'LGT':
                return showPrivacyTermsLgu();
            case 'SKM':
                return showPrivacyTermsSktMvno();
            case 'KTM':
                return showPrivacyTermsKtMvno();
            case 'LGM':
                return showPrivacyTermsLguMvno();
            default:
                return null;
        }
    }

    const showPrivacyTermsSkt = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>개인정보 이용동의 - SKT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>SK텔레콤 귀중</strong>
                            <br />
                            <br />
                            <strong>
                                본인은 SK텔레콤(주)(이하 ‘회사’라 합니다)가 제공하는
                                본인확인서비스(이하 ‘서비스’라 합니다)를 이용하기 위해, 다음과
                                같이 ‘회사’가 본인의 개인정보를 수집/이용하고, 개인정보의
                                취급을 위탁하는 것에 동의합니다.
                            </strong>
                            <br />
                            <br />
                            (1) 수집항목
                            <div className="dash">
                                <li>
                                    이용자의 성명, 이동전화번호, 가입한 이동전화 회사, 생년월일,
                                    성별
                                </li>
                                <li>연계정보(CI), 중복가입확인정보(DI)</li>
                                <li>
                                    이용자가 이용하는 웹사이트 또는 Application 정보, 이용일시
                                </li>
                                <li>내외국인 여부</li>
                                <li>가입한 이동전화회사 및 이동전화브랜드</li>
                            </div>
                            <br />
                            (2) 이용목적
                            <div className="dash">
                                <li>
                                    이용자가 웹사이트 또는 Application에 입력한 본인확인정보의
                                    정확성 여부 확인 (본인확인서비스 제공)
                                </li>
                                <li>
                                    해당 웹사이트 또는 Application에
                                    연계정보(CI)/중복가입확인정보(DI) 전송
                                </li>
                                <li>서비스 관련 상담 및 불만 처리 등</li>
                                <li>
                                    이용 웹사이트/Application 정보 등에 대한 분석 및 세분화를
                                    통한, 이용자의 서비스 이용 선호도 분석
                                </li>
                            </div>
                            <br />
                            (3) 개인정보의 보유기간 및 이용기간
                            <div className="dash">
                                <li>
                                    이용자가 서비스를 이용하는 기간에 한하여 보유 및 이용. 다만,
                                    아래의 경우는 제외
                                </li>
                                <li>
                                    법령에서 정하는 경우 해당 기간까지 보유(상세 사항은 회사의
                                    개인정보취급방침에 기재된 바에 따름)
                                </li>
                            </div>
                            <br />
                            (4) 본인확인서비스 제공을 위한 개인정보의 취급위탁
                            <div className="dash">
                                <li>수탁자 : SCI평가정보(주)</li>
                                <li>
                                    취급위탁 업무 : 본인확인정보의 정확성 여부
                                    확인(본인확인서비스 제공), 연계정보(CI)/중복가입확인
                                    정보(DI) 생성 및 전송, 서비스 관련 상담 및 불만 처리,
                                    휴대폰인증보호 서비스, 이용자의 서비스 이용 선호도 분석정보
                                    제공관련 시스템 구축 광고매체 연동 및 위탁영업 등
                                </li>
                                <li>
                                    수탁자의 상세 개인정보 취급 위탁 내용은 각 수탁자가 정하는
                                    절차와 방법에 따라 수탁자 홈페이지 등에 게시된 수탁자의
                                    개인정보 취급방침 및 제3자 제공 동의 방침 등에 따릅니다.
                                </li>
                            </div>
                            <br />
                            (5) 위 개인정보 수집·이용 및 취급위탁에 동의하지 않으실 경우,
                            서비스를 이용하실 수 없습니다.
                            <div className="dash">
                                <li>
                                    회사가 제공하는 서비스와 관련된 개인정보의 취급과 관련된
                                    사항은, 회사의 개인정보취급방침(회사 홈페이지
                                    www.sktelecom.com )에 따릅니다. 본인은 위 내용을
                                    숙지하였으며 이에 동의합니다.
                                </li>
                            </div>
                            <br />
                            <strong>SCI 평가정보㈜ 귀중</strong>
                            <br />
                            <br />
                            SCI평가정보㈜는 에스케이텔레콤㈜의 업무를 대행하여
                            휴대폰본인확인서비스를 제공함에 있어 본인의 개인정보를
                            수집·이용하고자 하는 경우에는 「정보통신망 이용촉진 및 정보보호
                            등에 관한 법률」및 「신용 정보의 이용 및 보호에 관한 법률」 에
                            따라 본인의 동의를 얻어야 합니다. 이에 본인은 귀사가 아래와 같이
                            본인의 개인정보를 수집·이용하는데 동의합니다.
                            <br />
                            <br />
                            [제1조] 개인정보의 수집∙이용 목적
                            <br />
                            <br />
                            가. 사업자의 서비스(회원가입, ID/PW찾기, 거래동의 등) 이용을
                            위한 본인확인, 식별확인 업무처리 및 정보제공
                            <br />
                            나. 에스케이텔레콤(주)에 이용자 정보를 전송하여 본인확인 및
                            휴대폰 정보의 일치 여부 확인
                            <br />
                            다. 휴대폰 사용자 확인을 위한 SMS(또는 LMS) 인증번호 전송
                            <br />
                            라. 부정 이용 방지 및 수사의뢰
                            <br />
                            마. 이용자 본인 요청에 의한 본인확인 이력정보 제공, 민원처리,
                            추후 분쟁조정을 위한 기록보존, 고지사항 전달 등<br />
                            바. 부가서비스 제공 및 해제(서비스 가입자에 한함)
                            <br />
                            사. 기타 법률에서 정한 목적
                            <br />
                            <br />
                            [제2조] 수집하는 개인정보의 항목
                            <br />
                            <br />
                            가. 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                            생년월일, 내/외국인 구분
                            <br />
                            나. 연계정보(CI), 중복가입확인정보(DI) 정보
                            <br />
                            다. 인증처 및 사이트 URL, 인증일시, IP주소 등<br />
                            <br />
                            [제3조] 개인정보의 보유∙이용 기간
                            <br />
                            <br />
                            개인정보는 개인정보의 수집목적 또는 제공받은 목적이 소멸되면
                            파기됩니다. 단, ‘개인정보보호법’, ‘정보통신망 이용 촉진 및
                            정보보호 등에 관한 법률’, ‘신용정보의 이용 및 보호에 관한
                            법률’등 기타 관련 법령의 규정에 의하여 법률관계의 확인 등을
                            이유로 특정한 기간 동안 보유하여야 할 필요가 있을 경우 에는
                            아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            가. 신용정보의 이용 및 보호의 관한 법률에 의거 정보 보존 기간:
                            3년
                            <br />
                            나. 계약 또는 청약철회 등에 관한 기록 : 5년
                            <br />
                            다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                            <br />
                            라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                            <br />
                            마. 기타 다른 법률의 규정에 의하여 보유가 허용되는 기간
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showPrivacyTermsKt = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>개인정보 이용동의 - KT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>케이티 귀중</strong>
                            <br />
                            <br />
                            <strong>
                                ㈜케이티(이하 ‘회사’라 함)가 제공하는 휴대폰 본인확인서비스를
                                이용하기 위해, 다음과 같이 ‘회사’가 본인의 개인정보를 수집 및
                                이용하고, 개인정보의 취급을 위탁하는 것에 동의합니다.
                            </strong>
                            <br />
                            <br />
                            (1) 수집항목
                            <div className="dash">
                                <li>
                                    이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                                    생년월일, 내/외국인 구분
                                </li>
                                <li>연계정보(CI), 중복가입확인정보(DI)</li>
                                <li>
                                    이용자가 본인확인을 요청한 서비스 명 및 URL정보,
                                    본인확인이용일시, IP주소
                                </li>
                            </div>
                            <br />
                            (2) 이용목적
                            <div className="dash">
                                <li>
                                    인터넷사업자의 서비스(회원가입, ID/PW찾기 등) 이용에 필요한
                                    이용자 본인확인 여부 및 정보 전달 (※ 이용자가 본인확인을
                                    요청한 인터넷사업자에 한합니다.)
                                </li>
                                <li>
                                    ㈜케이티 등 이동통신사에 이용자 정보를 전송하여 본인확인 및
                                    휴대폰 정보의 일치 여부 확인
                                </li>
                                <li>휴대폰 사용자 확인을 위한 SMS 인증번호 전송</li>
                                <li>부정 이용 방지 및 수사의뢰</li>
                                <li>이용자 본인 요청에 의한 본인확인 이력정보 제공</li>
                                <li>
                                    휴대폰번호보호서비스 가입여부 확인(서비스 가입자에 한함)
                                </li>
                            </div>
                            <br />
                            (3) 개인정보의 보유기간 및 이용기간
                            <div className="dash">
                                <li>
                                    회사’는 이용자의 개인정보를 이용목적이 달성되거나 보유 및
                                    보존기간이 종료하면 해당 정보를 지체 없이 파기하며 별도의
                                    보관을 하지 않습니다. 단, 관련 법령 및 회사방침에 따라
                                    보존하는 목적과 기간은 아래와 같습니다.
                                </li>
                                <li>
                                    소비자의 불만 또는 분쟁처리에 관한 기록 :
                                    3년(전자상거래등에서의 소비자보호에 관한 법률)
                                </li>
                            </div>
                            <br />
                            (4) 본인확인서비스 제공을 위한 개인정보의 취급위탁
                            <div className="dash">
                                <li>수탁자 : SCI평가정보(주)</li>
                                <li>
                                    취급위탁 업무 : 본인확인정보의 정확성 여부 확인,
                                    연계정보(CI)/중복가입확인정보(DI) 전송, 서비스 관련 상담 및
                                    불만 처리 등
                                </li>
                            </div>
                            <br />
                            (5) 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않으실
                            경우, 서비스를 이용하실 수 없습니다.
                            <div className="dash">
                                <li>
                                    회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은,
                                    아래의 홈페이지에 기재된 개인정보취급방 침에 따릅니다.
                                    (www.kt.com) 본인은 위 내용을 숙지하였으며 이에 동의합니다.
                                </li>
                            </div>
                            <br />
                            <strong>SCI 평가정보㈜ 귀중</strong>
                            SCI평가정보㈜는 ㈜케이티의 업무를 대행하여
                            휴대폰본인확인서비스를 제공함에 있어 본인의 개인정보를
                            수집·이용하고자 하는 경우에는 「정보통신망 이용촉진 및 정보보호
                            등에 관한 법률」및 「신용정보의이용 및 보호에 관한 법률」 에
                            따라 본인의 동의를 얻어야 합니다. 이에 본인은 귀사가 아래와 같이
                            본인의 개인정보를 수집·이용하는데 동의합니다.
                            <br />
                            <br />
                            [제1조] 개인정보의 수집∙이용 목적
                            <br />
                            <br />
                            가. 사업자의 서비스(회원가입, ID/PW찾기, 거래동의 등) 이용을
                            위한 본인확인, 식별확인 업무처리 및 정보제공
                            <br />
                            나. (주)케이티에 이용자 정보를 전송하여 본인확인 및 휴대폰
                            정보의 일치 여부 확인
                            <br />
                            다. 휴대폰 사용자 확인을 위한 SMS(또는 LMS) 인증번호 전송
                            <br />
                            라. 부정 이용 방지 및 수사의뢰
                            <br />
                            마. 이용자 본인 요청에 의한 본인확인 이력정보 제공, 민원처리,
                            추후 분쟁조정을 위한 기록보존, 고지사항 전달 등<br />
                            바. 부가서비스 제공 및 해제(서비스 가입자에 한함)
                            <br />
                            사. 기타 법률에서 정한 목적
                            <br />
                            <br />
                            [제2조] 수집하는 개인정보의 항목
                            <br />
                            <br />
                            가. 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                            생년월일, 내/외국인 구분
                            <br />
                            나. 연계정보(CI), 중복가입확인정보(DI) 정보
                            <br />
                            다. 인증처 및 사이트 URL, 인증일시, IP주소 등<br />
                            <br />
                            [제3조] 개인정보의 보유∙이용 기간
                            <br />
                            <br />
                            개인정보는 개인정보의 수집목적 또는 제공받은 목적이 소멸되면
                            파기됩니다. 단, ‘개인정보보호법’, ‘정보통신망 이용 촉진 및
                            정보보호 등에 관한 법률’, ‘신용정보의 이용 및 보호에 관한
                            법률’등 기타 관련법령의 규정에 의하여 법률관계의 확인 등을
                            이유로 특정한 기간 동안 보유하여야 할 필요가 있을 경우에는
                            아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            가. 신용정보의 이용 및 보호의 관한 법률에 의거 정보 보존 기간:
                            3년
                            <br />
                            나. 계약 또는 청약철회 등에 관한 기록 : 5년
                            <br />
                            다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                            <br />
                            라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                            <br />
                            마. 기타 다른 법률의 규정에 의하여 보유가 허용되는 기간
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showPrivacyTermsLgu = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>개인정보 이용동의 - LGU+</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>엘지유플러스 귀중</strong>
                            <br />
                            <br />
                            <strong>
                                LGU+㈜ (이하 ‘회사’라 함)가 제공하는 휴대폰 본인확인서비스를
                                이용하기 위해, 다음과 같이 ‘회사’가 본인의 개인정보를 수집 및
                                이용하고, 개인정보의 취급을 위탁하는 것에 동의합니다.
                            </strong>
                            <br />
                            <br />
                            (1) 수집항목
                            <div className="dash">
                                <li>
                                    고객의 생년월일, 이동전화번호, 성명, 성별, 내/외국인 구분
                                </li>
                                <li>연계정보(CI), 중복가입확인정보(DI)</li>
                                <li>고객이 이용하는 웹사이트 등</li>
                            </div>
                            <br />
                            (2) 이용목적
                            <div className="dash">
                                <li>
                                    고객이 웹사이트 또는 Application 등에 입력한 본인확인정보의
                                    정확성 여부 확인
                                </li>
                                <li>
                                    해당 웹사이트 또는 Application 등에 연계정보(CI)와
                                    중복가입확인정보(DI) 전송
                                </li>
                                <li>서비스 관련 상담 및 불만 처리</li>
                                <li>기타 법률에서 정한 목적</li>
                            </div>
                            <br />
                            (3) 개인정보의 보유 및 이용기간
                            <br />
                            고객이 서비스를 이용하는 기간에 한하여 보유 및 이용을 원칙으로
                            하되, 법률에서 정하는 경우 해당 기간까지 보유 및 이용(세부사항은
                            ‘회사’의 개인정보취급방침에 따름)
                            <br />
                            <br />
                            (4) 본인확인서비스 제공을 위한 개인정보의 취급위탁
                            <div className="dash">
                                <li>수탁자 : SCI평가정보(주)</li>
                                <li>
                                    취급위탁 업무 : 본인확인정보의 정확성 여부 확인,
                                    연계정보(CI)/중복가입확인정보(DI) 전송, 서비스 관련 상담 및
                                    불만 처리 등
                                </li>
                            </div>
                            <br />
                            (5) 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않으실
                            경우, 서비스를 이용하실 수 없습니다.
                            <div className="dash">
                                <li>
                                    회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은,
                                    아래의 홈페이지에 기재된 개인정보취급방침에 따릅니다. .
                                    (www.lguplus.co.kr) 본인은 위 내용을 숙지하였으며 이에
                                    동의합니다.
                                </li>
                            </div>
                            <br />
                            <strong>SCI 평가정보㈜ 귀중</strong>
                            <br />
                            <br />
                            SCI평가정보㈜는 ㈜엘지유플러스의 업무를 대행하여
                            휴대폰본인확인서비스를 제공함에 있어 본인의 개인정보를
                            수집·이용하고자 하는 경우에는 「정보통신망 이용촉진 및 정보보호
                            등에 관한 법률」및 「신용정보의 이용 및 보호에 관한 법률」 에
                            따라 본인의 동의를 얻어야 합니다. 이에 본인은 귀사가 아래와 같이
                            본인의 개인정보를 수집·이용하는데 동의합니다.
                            <br />
                            <br />
                            [제1조] 개인정보의 수집∙이용 목적
                            <br />
                            <br />
                            가. 사업자의 서비스(회원가입, ID/PW찾기, 거래동의 등) 이용을
                            위한 본인확인, 식별확인 업무처리 및 정보제공
                            <br />
                            나. 엘지유플러스(주)에 이용자 정보를 전송하여 본인확인 및 휴대폰
                            정보의 일치 여부 확인
                            <br />
                            다. 휴대폰 사용자 확인을 위한 SMS(또는 LMS) 인증번호 전송
                            <br />
                            라. 부정 이용 방지 및 수사의뢰
                            <br />
                            마. 이용자 본인 요청에 의한 본인확인 이력정보 제공, 민원처리,
                            추후 분쟁조정을 위한 기록보존, 고지사항 전달 등<br />
                            바. 부가서비스 제공 및 해제(서비스 가입자에 한함)
                            <br />
                            사. 기타 법률에서 정한 목적
                            <br />
                            <br />
                            [제2조] 수집하는 개인정보의 항목
                            <br />
                            <br />
                            가. 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                            생년월일, 내/외국인 구분
                            <br />
                            나. 연계정보(CI), 중복가입확인정보(DI) 정보
                            <br />
                            다. 인증처 및 사이트 URL, 인증일시, IP주소 등<br />
                            <br />
                            [제3조] 개인정보의 보유∙이용 기간
                            <br />
                            <br />
                            개인정보는 개인정보의 수집목적 또는 제공받은 목적이 소멸되면
                            파기됩니다. 단, ‘개인정보보호법’, ‘정보통신망 이용 촉진 및
                            정보보호 등에 관한 법률’, ‘신용정보의 이용 및 보호에 관한
                            법률’등 기타 관련법령의 규정에 의하여 법률관계의 확인 등을
                            이유로 특정한 기간 동안 보유하여야 할 필요가 있을 경우에는
                            아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            가. 신용정보의 이용 및 보호의 관한 법률에 의거 정보 보존 기간:
                            3년
                            <br />
                            나. 계약 또는 청약철회 등에 관한 기록 : 5년
                            <br />
                            다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                            <br />
                            라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                            <br />
                            마. 기타 다른 법률의 규정에 의하여 보유가 허용되는 기간
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showPrivacyTermsSktMvno = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>개인정보 이용동의 - SKT 알뜰폰</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>
                                '알뜰폰(MVNO)’사업자는 다음과 같이 개인정보를 제 3 자에게
                                제공하고 있습니다.
                            </strong>
                            <br />
                            <br />
                            제 1 조 (알뜰폰(MVNO) 사업자)
                            <br />
                            SKT 의 알뜰폰(MVNO) 사업자는 아이즈비전(아이즈모바일),
                            KCT(Tplus), 유니컴즈(모빙), 스마텔, SK 텔링크(7 모바일),
                            큰사람(이야기알뜰폰), 이마트(e 모바일),
                            에스원(안심모바일),프리텔레콤(FreeT), 티브로드(티브로드모바일),
                            하나방송(하나방송알뜰폰), 제주방송(KCTV 알뜰폰),
                            남인천방송(mfun), 서경방송(서경휴대폰),
                            광주방송(광주방송알뜰폰), 금강방송(kcn 휴대폰), JCN 울산(JCN
                            알뜰폰), CJ 헬로비전(헬로모바일), 에넥스텔레콤(A 모바일)입니다.
                            <br />
                            <br />제 2 조 (제공목적)
                            <br />본 동의는 본인확인 서비스를 제공하기 위하여 본인확인기관인
                            SK 텔레콤㈜와 본인확인서비스 이용자간 본인확인서비스 이용에
                            필요한 고객정보를 위탁하여 본인확인서비스를 제공 하는 것에 대해
                            동의합니다.
                            <br />
                            <br />제 3 조 (제공정보)
                            <br />
                            휴대폰 본인확인 서비스 제공에 필요한 개인정보로 성명,
                            휴대폰번호, 생년월일, 내.외국인구분, 성별 정보입니다.
                            <br />
                            <br />제 4 조 (제공받는 자)
                            <br />
                            SK 텔레콤㈜
                            <br />
                            <br />제 5 조 (제공기간)
                            <br />
                            이동통신사에서 보유중인 정보로서 별도 이용기간은 없습니다.
                            ‘본인’은 정보제공에 동의하지 않으실 수 있으며, 동의하지 않으실
                            경우 휴대폰 본인 확인 서비스를 이용 하실 수 없습니다.
                            <br />
                            <br />
                            <strong>SK 텔레콤 귀중</strong>
                            <br />
                            <br />
                            본인은 SK 텔레콤(주)(이하 ‘회사’라 합니다)가 제공하는
                            본인확인서비스(이하 ‘서비스’라 합니다)를 이용하기 위해, 다음과
                            같이 ‘회사’가 본인의 개인정보를 수집/이용하고, 개인정보의 취급을
                            위탁하는 것에 동의합니다. <br />
                            <br />
                            (1) 수집항목
                            <div className="dash">
                                <li>
                                    이용자의 성명, 이동전화번호, 가입한 이동전화 회사, 생년월일,
                                    성별
                                </li>
                                <li>연계정보(CI), 중복가입확인정보(DI)</li>
                                <li>
                                    이용자가 이용하는 웹사이트 또는 Application 정보, 이용일시-
                                    내외국인 여부
                                </li>
                                <li>가입한 이동전화회사 및 이동전화브랜드</li>
                            </div>
                            <br />
                            (2) 이용목적
                            <div className="dash">
                                <li>
                                    이용자가 웹사이트 또는 Application 에 입력한 본인확인정보의
                                    정확성 여부 확인(본인확인서비스 제공)
                                </li>
                                <li>
                                    해당 웹사이트 또는 Application 에
                                    연계정보(CI)/중복가입확인정보(DI) 전송
                                </li>
                                <li>서비스 관련 상담 및 불만 처리 등</li>
                                <li>
                                    이용 웹사이트/Application 정보 등에 대한 분석 및 세분화를
                                    통한, 이용자의 서비스이용 선호도 분석
                                </li>
                            </div>
                            <br />
                            (3) 개인정보의 보유기간 및 이용기간
                            <div className="dash">
                                <li>
                                    이용자가 서비스를 이용하는 기간에 한하여 보유 및 이용. 다만,
                                    아래의 경우는 제외
                                </li>
                                <li>
                                    법령에서 정하는 경우 해당 기간까지 보유(상세 사항은 회사의
                                    개인정보취급방침에 기재된 바에 따름)
                                </li>
                            </div>
                            <br />
                            (4) 본인확인서비스 제공을 위한 개인정보의 취급위탁
                            <div className="dash">
                                <li>수탁자 : SCI평가정보(주)</li>
                                <li>
                                    취급위탁 업무 : 본인확인정보의 정확성 여부
                                    확인(본인확인서비스 제공), 연계정보(CI)/중복가입확인
                                    정보(DI) 생성 및 전송, 서비스 관련 상담 및 불만 처리,
                                    휴대폰인증보호 서비스, 이용자의 서비스 이용 선호도 분석정보
                                    제공관련 시스템 구축 광고매체 연동 및 위탁영업 등
                                </li>
                                <li>
                                    수탁자의 상세 개인정보 취급 위탁 내용은 각 수탁자가 정하는
                                    절차와 방법에 따라 수탁자 홈페이지 등에 게시된 수탁자의
                                    개인정보 취급방침 및 제3자 제공 동의 방침 등에 따릅니다.
                                </li>
                            </div>
                            <br />
                            (5) 위 개인정보 수집·이용 및 취급위탁에 동의하지 않으실 경우,
                            서비스를 이용하실 수 없습니다.
                            <div className="dash">
                                <li>
                                    회사가 제공하는 서비스와 관련된 개인정보의 취급과 관련된
                                    사항은, 회사의 개인정보취급방침(회사 홈페이지
                                    www.sktelecom.com )에 따릅니다. 본인은 위 내용을
                                    숙지하였으며 이에 동의합니다.
                                </li>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showPrivacyTermsKtMvno  = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>개인정보 이용동의 - KT 알뜰폰</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>
                                ’알뜰폰(MVNO)’사업자는 다음과 같이 개인정보를 제3자에게
                                제공하고 있습니다.
                            </strong>
                            <br />
                            <br />
                            제 1 조 (알뜰폰(MVNO) 사업자)
                            <br />
                            KT의 알뜰폰(MVNO) 사업자는 CJ헬로비전(헬로모바일),
                            KT파워텔(더블비), 홈플러스(플러스모바일),
                            씨엔커뮤니케이션(WMVNO), 에넥스텔레콤(WHOM), 에스원(안심폰),
                            워너스텔(Well), 에이씨앤코리아(Flash모바일),
                            세종텔레콤(스노우맨), KT텔레캅, 프리텔레콤(freeTKT알뜰폰),
                            이지모바일(EG제로), KTM모바일(M모바일),
                            앤알커뮤니케이션(앤텔레콤), 아이즈비전(아이즈모바일), 제이씨티,
                            머천드코리아(마이월드),장성모바일, 유니컴즈(Mobing)입니다.
                            <br />
                            <br />제 2 조 (제공목적)
                            <br />
                            본 동의는 본인확인 서비스를 제공하기 위하여 본인확인기관인
                            ㈜케이티와 본인확인서비스 이용자간 본인확인서비스 이용에 필요한
                            고객정보를 위탁하여 본인확인서비스를 제공 하는 것에 대해
                            동의합니다.
                            <br />
                            <br />제 3 조 (제공정보)
                            <br />
                            휴대폰 본인확인 서비스 제공에 필요한 개인정보로 성명,
                            휴대폰번호, 생년월일, 내.외국인구분, 성별정보입니다.
                            <br />
                            <br />제 4 조 (제공받는 자)
                            <br />
                            (주)케이티
                            <br />
                            <br />제 5 조 (제공기간)
                            <br />
                            이동통신사에서 보유중인 정보로서 별도 이용기간은 없습니다.
                            <br />
                            ‘본인’은 정보제공에 동의하지 않으실 수 있으며, 동의하지 않으실
                            경우 휴대폰 본인 확인 서비스를 이용 하실 수 없습니다.
                            <br />
                            <br />
                            <strong>케이티 귀중</strong>
                            <br />
                            <br />
                            ㈜케이티(이하 ‘회사’라 함)가 제공하는 휴대폰 본인확인서비스를
                            이용하기 위해, 다음과 같이 ‘회사’가본인의개인정보를 수집 및
                            이용하고, 개인정보의 취급을 위탁하는 것에 동의합니다.
                            <br />
                            <br />
                            (1) 수집항목
                            <div className="dash">
                                <li>
                                    이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                                    생년월일, 내/외국인 구분
                                </li>
                                <li>연계정보(CI), 중복가입확인정보(DI)</li>
                                <li>
                                    이용자가 본인확인을 요청한 서비스 명 및 URL정보,
                                    본인확인이용일시, IP주소
                                </li>
                            </div>
                            <br />
                            (2) 이용목적
                            <div className="dash">
                                <li>
                                    인터넷사업자의 서비스(회원가입, ID/PW찾기 등) 이용에 필요한
                                    이용자 본인확인 여부 및 정보 전달 (※ 이용자가 본인확인을
                                    요청한 인터넷사업자에 한합니다.)
                                </li>
                                <li>
                                    ㈜케이티 등 이동통신사에 이용자 정보를 전송하여 본인확인 및
                                    휴대폰 정보의 일치 여부 확인
                                </li>
                                <li>휴대폰 사용자 확인을 위한 SMS 인증번호 전송</li>
                                <li>부정 이용 방지 및 수사의뢰</li>
                                <li>이용자 본인 요청에 의한 본인확인 이력정보 제공</li>
                                <li>
                                    휴대폰번호보호서비스 가입여부 확인(서비스 가입자에 한함)
                                </li>
                            </div>
                            <br />
                            (3) 개인정보의 보유기간 및 이용기간
                            <div className="dash">
                                <li>
                                    회사’는 이용자의 개인정보를 이용목적이 달성되거나 보유 및
                                    보존기간이 종료하면 해당 정보를 지체 없이 파기하며 별도의
                                    보관을 하지 않습니다.
                                    <br />
                                    단, 관련 법령 및 회사방침에 따라 보존하는 목적과 기간은
                                    아래와 같습니다.
                                </li>
                                <li>
                                    소비자의 불만 또는 분쟁처리에 관한 기록 :
                                    3년(전자상거래등에서의 소비자보호에 관한 법률)
                                </li>
                            </div>
                            <br />
                            (4) 본인확인서비스 제공을 위한 개인정보의 취급위탁
                            <div className="dash">
                                <li>수탁자 : SCI평가정보(주)</li>
                                <li>
                                    취급위탁 업무 : 본인확인정보의 정확성 여부 확인,
                                    연계정보(CI)/중복가입확인정보(DI) 전송, 서비스 관련 상담 및
                                    불만 처리 등
                                </li>
                            </div>
                            <br />
                            (5) 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않으실
                            경우, 서비스를 이용하실 수 없습니다.
                            <div className="dash">
                                <li>
                                    회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은,
                                    아래의 홈페이지에 기재된 개인정보취급방 침에 따릅니다.
                                    (www.kt.com) 본인은 위 내용을 숙지하였으며 이에 동의합니다.
                                </li>
                            </div>
                            <br />
                            <strong>SCI 평가정보㈜ 귀중</strong>
                            <br />
                            <br />
                            SCI평가정보㈜는 ㈜케이티의 업무를 대행하여
                            휴대폰본인확인서비스를 제공함에 있어 본인의 개인정보를
                            수집·이용하고자 하는 경우에는 「정보통신망 이용촉진 및 정보보호
                            등에 관한 법률」및 「신용정보의이용 및 보호에 관한 법률」 에
                            따라 본인의 동의를 얻어야 합니다. 이에 본인은 귀사가 아래와 같이
                            본인의 개인정보를 수집·이용하는데 동의합니다.
                            <br />
                            <br />
                            [제1조] 개인정보의 수집∙이용 목적
                            <br />
                            <br />
                            가. 사업자의 서비스(회원가입, ID/PW찾기, 거래동의 등) 이용을
                            위한 본인확인, 식별확인 업무처리 및 정보제공
                            <br />
                            나. (주)케이티에 이용자 정보를 전송하여 본인확인 및 휴대폰
                            정보의 일치 여부 확인
                            <br />
                            다. 휴대폰 사용자 확인을 위한 SMS(또는 LMS) 인증번호 전송
                            <br />
                            라. 부정 이용 방지 및 수사의뢰
                            <br />
                            마. 이용자 본인 요청에 의한 본인확인 이력정보 제공, 민원처리,
                            추후 분쟁조정을 위한 기록보존, 고지사항 전달 등
                            <br />
                            바. 부가서비스 제공 및 해제(서비스 가입자에 한함)
                            <br />
                            사. 기타 법률에서 정한 목적
                            <br />
                            <br />
                            [제2조] 수집하는 개인정보의 항목
                            <br />
                            <br />
                            가. 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                            생년월일, 내/외국인 구분
                            <br />
                            나. 연계정보(CI), 중복가입확인정보(DI) 정보
                            <br />
                            다. 인증처 및 사이트 URL, 인증일시, IP주소 등
                            <br />
                            <br />
                            [제3조] 개인정보의 보유∙이용 기간
                            <br />
                            <br />
                            개인정보는 개인정보의 수집목적 또는 제공받은 목적이 소멸되면
                            파기됩니다. 단, ‘개인정보보호법’, ‘정보통신망 이용 촉진 및
                            정보보호 등에 관한 법률’, ‘신용정보의 이용 및 보호에 관한
                            법률’등 기타 관련법령의 규정에 의하여 법률관계의 확인 등을
                            이유로 특정한 기간 동안 보유하여야 할 필요가 있을 경우에는
                            아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            가. 신용정보의 이용 및 보호의 관한 법률에 의거 정보 보존 기간:
                            3년
                            <br />
                            나. 계약 또는 청약철회 등에 관한 기록 : 5년
                            <br />
                            다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                            <br />
                            라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                            <br />
                            마. 기타 다른 법률의 규정에 의하여 보유가 허용되는 기간
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showPrivacyTermsLguMvno = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>개인정보 이용동의 - LGU+ 알뜰폰</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            <strong>
                                ‘알뜰폰(MVNO)’사업자는 다음과 같이 개인정보를 제3자에게
                                제공하고 있습니다.
                            </strong>
                            <br />
                            <br />
                            제 1 조 (알뜰폰(MVNO) 사업자)
                            <br />
                            엘지유플러스의 알뜰폰(MVNO) 사업자는 미디어로그(U mobi),
                            인스코비(freeTLGU+알뜰폰), 머천드코리아(마이월드),
                            엠티티텔레콤(메리큐), 홈플러스(플러스모바일), 알뜰폰,
                            이마트(이마트알뜰폰), 서경방송(서경휴대폰),
                            울산중앙방송(JCN알뜰폰), 남인천방송(mfun), 금강방송(kcn휴대폰),
                            제주방송(KCTV알뜰폰), 푸른방송(푸른방송알뜰폰),
                            와이엘랜드(여유텔레콤) 입니다.
                            <br />
                            <br />제 2 조 (제공목적)
                            <br />
                            본 동의는 본인확인 서비스를 제공하기 위하여 본인확인기관인
                            ㈜엘지유플러스와 본인확인서비스 이용자간 본인확인서비스 이용에
                            필요한 고객정보를 위탁하여 본인확인서비스를 제공 하는 것에 대해
                            동의합니다.
                            <br />
                            <br />제 3 조 (제공정보)
                            <br />
                            휴대폰 본인확인 서비스 제공에 필요한 개인정보로 성명,
                            휴대폰번호, 생년월일, 내.외국인구분, 성별정보입니다.
                            <br />
                            <br />제 4 조 (제공받는 자)
                            <br />
                            ㈜엘지유플러스
                            <br />
                            <br />제 5 조 (제공기간)
                            <br />
                            이동통신사에서 보유중인 정보로서 별도 이용기간은 없습니다.
                            <br />
                            ‘본인’은 정보제공에 동의하지 않으실 수 있으며, 동의하지 않으실
                            경우 휴대폰 본인 확인 서비스를 이용 하실 수 없습니다.
                            <br />
                            <br />
                            <strong>엘지유플러스 귀중</strong>
                            <br />
                            <br />
                            <strong>
                                LGU+㈜ (이하 ‘회사’라 함)가 제공하는 휴대폰 본인확인서비스를
                                이용하기 위해, 다음과 같이 ‘회사’가 본인의 개인정보를 수집 및
                                이용하고, 개인정보의 취급을 위탁하는 것에 동의합니다.
                            </strong>
                            <br />
                            <br />
                            (1) 수집항목
                            <div className="dash">
                                <li>
                                    고객의 생년월일, 이동전화번호, 성명, 성별, 내/외국인 구분
                                </li>
                                <li>연계정보(CI), 중복가입확인정보(DI)</li>
                                <li>고객이 이용하는 웹사이트 등</li>
                            </div>
                            <br />
                            (2) 이용목적
                            <div className="dash">
                                <li>
                                    고객이 웹사이트 또는 Application 등에 입력한 본인확인정보의
                                    정확성 여부 확인
                                </li>
                                <li>
                                    해당 웹사이트 또는 Application 등에 연계정보(CI)와
                                    중복가입확인정보(DI) 전송
                                </li>
                                <li>서비스 관련 상담 및 불만 처리</li>
                                <li>기타 법률에서 정한 목적</li>
                            </div>
                            <br />
                            (3) 개인정보의 보유 및 이용기간
                            <br />
                            고객이 서비스를 이용하는 기간에 한하여 보유 및 이용을 원칙으로
                            하되, 법률에서 정하는 경우 해당 기간까지 보유 및 이용(세부사항은
                            ‘회사’의 개인정보취급방침에 따름)
                            <br />
                            <br />
                            (4) 본인확인서비스 제공을 위한 개인정보의 취급위탁
                            <div className="dash">
                                <li>수탁자 : SCI평가정보(주)</li>
                                <li>
                                    취급위탁 업무 : 본인확인정보의 정확성 여부 확인,
                                    연계정보(CI)/중복가입확인정보(DI) 전송, 서비스 관련 상담 및
                                    불만 처리 등
                                </li>
                            </div>
                            <br />
                            (5) 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않으실
                            경우, 서비스를 이용하실 수 없습니다.
                            <div className="dash">
                                <li>
                                    회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은,
                                    아래의 홈페이지에 기재된 개인정보취급방침에 따릅니다. .
                                    (www.lguplus.co.kr) 본인은 위 내용을 숙지하였으며 이에
                                    동의합니다.
                                </li>
                            </div>
                            <br />
                            <strong>SCI 평가정보㈜ 귀중</strong>
                            <br />
                            <br />
                            SCI평가정보㈜는 ㈜엘지유플러스의 업무를 대행하여
                            휴대폰본인확인서비스를 제공함에 있어 본인의 개인정보를
                            수집·이용하고자 하는 경우에는 「정보통신망 이용촉진 및 정보보호
                            등에 관한 법률」및 「신용정보의 이용 및 보호에 관한 법률」 에
                            따라 본인의 동의를 얻어야 합니다. 이에 본인은 귀사가 아래와 같이
                            본인의 개인정보를 수집·이용하는데 동의합니다.
                            <br />
                            <br />
                            [제1조] 개인정보의 수집∙이용 목적
                            <br />
                            가. 사업자의 서비스(회원가입, ID/PW찾기, 거래동의 등) 이용을
                            위한 본인확인, 식별확인 업무처리 및 정보제공
                            <br />
                            나. 엘지유플러스(주)에 이용자 정보를 전송하여 본인확인 및 휴대폰
                            정보의 일치 여부 확인
                            <br />
                            다. 휴대폰 사용자 확인을 위한 SMS(또는 LMS) 인증번호 전송
                            <br />
                            라. 부정 이용 방지 및 수사의뢰
                            <br />
                            마. 이용자 본인 요청에 의한 본인확인 이력정보 제공, 민원처리,
                            추후 분쟁조정을 위한 기록보존, 고지사항 전달 등<br />
                            바. 부가서비스 제공 및 해제(서비스 가입자에 한함)
                            <br />
                            사. 기타 법률에서 정한 목적
                            <br />
                            <br />
                            [제2조] 수집하는 개인정보의 항목
                            <br />
                            가. 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                            생년월일, 내/외국인 구분
                            <br />
                            나. 연계정보(CI), 중복가입확인정보(DI) 정보
                            <br />
                            다. 인증처 및 사이트 URL, 인증일시, IP주소 등<br />
                            <br />
                            [제3조] 개인정보의 보유∙이용 기간
                            <br />
                            개인정보는 개인정보의 수집목적 또는 제공받은 목적이 소멸되면
                            파기됩니다. 단, ‘개인정보보호법’, ‘정보통신망 이용 촉진 및
                            정보보호 등에 관한 법률’, ‘신용정보의 이용 및 보호에 관한
                            법률’등 기타 관련법령의 규정에 의하여 법률관계의 확인 등을
                            이유로 특정한 기간 동안 보유하여야 할 필요가 있을 경우에는
                            아래에 정한 기간 동안 보유합니다.
                            <br />
                            <br />
                            가. 신용정보의 이용 및 보호의 관한 법률에 의거 정보 보존 기간:
                            3년
                            <br />
                            나. 계약 또는 청약철회 등에 관한 기록 : 5년
                            <br />
                            다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                            <br />
                            라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                            <br />
                            마. 기타 다른 법률의 규정에 의하여 보유가 허용되는 기간
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    return (
        showPrivacyTerms()
    );
}

export default PrivacyTerms;