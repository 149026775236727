import {Modal} from "react-bootstrap";
import React from "react";

const MetOnModal = function ({show, setShow, title, body, ...modalProps}) {

    return (
        <Modal
            show={show}
            fullscreen
            onHide={() => setShow(false)}
            {...modalProps}
        >
            {title &&
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            }
            <Modal.Body>
                {body}
            </Modal.Body>
        </Modal>
    );
};

export default MetOnModal;