import {Modal} from "react-bootstrap";

const ServiceTerms = ({telecom}) => {

    const showServiceTerms = () => {
        switch (telecom) {
            case 'SKT':
                return showServiceTermsSkt();
            case 'KTF':
                return showServiceTermsKt();
            case 'LGT':
                return showServiceTermsLgu();
            case 'SKM':
                return showServiceTermsSktMvno();
            case 'KTM':
                return showServiceTermsKtMvno();
            case 'LGM':
                return showServiceTermsLguMvno();
            default:
                return null;
        }
    }

    const showServiceTermsSkt = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>서비스 이용약관</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            SCI 본인확인서비스 이용 약관
                            <br/>
                            <br/>
                            제 1 조 (목적)
                            <br/>
                            <br/>
                            본 약관은 SCI평가정보㈜(이하 ‘회사’라 한다)가 제공하는
                            본인확인서비스(이하 ‘서비스’라 한다)에 관한 이용조건 및 절차 등
                            기본적인 사항을 규정함을 목적으로 합니다.
                            <br/>
                            <br/>
                            제 2 조 (약관의 효력 및 변경)
                            <br/>
                            <br/>
                            (1) 본 약관은 ‘이용자’에게 ‘서비스’ 화면에 게시하거나, 기타의
                            방법으로 공지하는 것으로 효력이 발생합니다.
                            <br/>
                            <br/>
                            (2) 회사’는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지
                            않는 범위에서 본 약관의 내용을 개정할 수 있으며, 변경된 경우에는
                            제1항과 같은 방법으로 공지합니다. 다만 ‘이용자’의 권리와 의무에
                            관한 중요한 사항은 변경된 내용의 시행 15일 이전에 공지합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 변경된 약관에 대한 내용을 알지 못하여 발생하는
                            손해 및 피해에 대해서는 ‘회사’가 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 3 조 (약관 외 준칙)
                            <br/>
                            <br/>
                            본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용 촉진
                            및 정보보호 등에 관한 법률 등 기타 관련 법령 또는 상관례에
                            따릅니다.
                            <br/>
                            <br/>
                            제 4 조 (용어의 정의)
                            <br/>
                            <br/>
                            본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                            <br/>
                            <br/>
                            (1) ‘본인확인서비스’라 함은 ‘이용자’가 인터넷상에서 휴대폰 등의
                            인증수단을 이용하여, 본인임을 안전하게 식별 및 확인해 주는
                            서비스를 말합니다.
                            <br/>
                            <br/>
                            (2) ‘이용자’라 함은 ‘회사’에서 제공하는 ‘서비스’의 이용을 위해
                            자신의 ‘본인확인정보’를 ‘회사’ 및 ‘본인확인기관’ 등에게
                            제공하고, 본인임을 확인 받고자 하는 자를 말합니다.
                            <br/>
                            <br/>
                            (3) ‘본인확인정보’라 함은 ‘이용자’가 입력한 생년월일, 성별,
                            성명, 내/외국인, 휴대폰번호, 통신사 등 본인 식별에 필요한
                            ‘이용자’의 정보를 말합니다.
                            <br/>
                            <br/>
                            (4) ‘본인확인기관’이라 함은 주민등록번호를 사용하지 아니하고
                            본인을 확인하는 방법 또는 서비스를 제공하는 자로
                            방송통신위원회로부터 본인확인기관으로 지정을 받은 자를
                            의미합니다.
                            <br/>
                            <br/>
                            (5) ‘인터넷사업자’라 함은 인터넷을 이용하여 정보를 제공하거나,
                            정보의 제공을 매개하는 일을 업으로 하는 자로 ‘회사’와 계약을
                            통해 운영하며, 인터넷 웹사이트의 ‘이용자’에 대한
                            ‘본인확인정보’를 제공 받는 사업체를 말합니다.
                            <br/>
                            <br/>
                            (6) ‘중복가입확인정보’라 함은 웹사이트에 가입하고자 하는
                            이용자의 중복 확인을 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            (7) ‘연계정보’ 라 함은 ‘인터넷사업자’의 온ㆍ오프라인 서비스 연계
                            등의 목적으로 ‘이용자’를 식별하기 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            제 5 조 (회사의 의무)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’ 제공과 관련하여 인지한 ‘이용자’의
                            ‘본인확인정보’를 본인의 승낙 없이 제3자에게 누설하거나 배포하지
                            않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의
                            목적이 있는 경우 등 기타 관계법령에서 정한 절차에 따른 요청이
                            있는 경우에는 그러하지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’에게 안전한 ‘서비스’ 제공을 위하여
                            지속적으로 ‘서비스’의 예방점검, 유지보수 등을 이행하며,
                            ‘서비스’의 장애가 발생하는 경우, 이를 지체 없이 수리 및
                            복구합니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 ‘이용자’가 제기한 의견, 불만이 타당하다고 인정되는
                            경우 적절한 절차를 거쳐 이를 지체 없이 처리하거나 처리에 관한
                            일정을 통지하여야 합니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 접근매체의 발급 주체가 아닌 경우에는 접근 매체의
                            위조나 변조로 발생한 사고로 인하여,‘이용자’에게 발생한 손해에
                            대하여 배상책임이 없습니다.
                            <br/>
                            <br/>
                            제 6 조 (이용자의 의무)
                            <br/>
                            <br/>
                            (1) ‘이용자’는 ‘서비스’를 이용함에 있어서 다음 각호에 해당하는
                            행위를 하여서는 안되며, ‘회사’는 위반행위에 따르는 일체의 법적
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            ① 타 ‘이용자’의 ‘본인확인정보’를 부정하게 사용 및 도용하는 행위
                            <br/>② ‘회사’의 저작권, 제3자의 저작원 등 기타 권리를 침해하는
                            행위 <br/>③ 범죄 행위 <br/>④ 기타 관련 법령에 위배되는 행위
                            <br/>
                            <br/>
                            (2) ‘이용자’는 자신의 접근매체를 제3자에게 누설 또는 노출하거나
                            방치하여서는 안되며, 접근 매체의 도용이나 위조 또는 변조를
                            방지하기 위하여 충분한 주의를 기울여야 합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 본 약관에서 규정하는 사항과 ‘서비스’에 대한
                            이용안내 또는 주의사항을 준수하여야 합니다.
                            <br/>
                            <br/>
                            제 7 조 (서비스의 내용)
                            <br/>
                            <br/>
                            (1) ‘서비스’는 ‘이용자’가 주민등록번호의 입력 없이, 본인명의로
                            된 인증수단(휴대폰 등)을 이용하여 본인 확인이 가능한 인증 서비스
                            입니다.
                            <br/>
                            <br/>
                            (2) ‘인터넷사업자’는 회원가입, ID/PW찾기, 성인인증, 기타
                            본인확인이 필요한 콘텐츠 요청 시 ‘이용자’에게 ‘서비스’를 제공
                            합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 자신의 생년월일, 성명, 성별, 내/외국인,
                            휴대폰번호, 통신사 등의 정보를 입력 후, 입력한정보가 일치한 경우
                            해당 휴대폰번호로 1회성 비밀번호(승인번호)가 발송되며, 수신된
                            승인번호를 정확하게 입력하는 것으로 본인 확인이 이루어 집니다.
                            <br/>
                            <br/>
                            제 8 조 (서비스 제공시간)
                            <br/>
                            <br/>
                            (1) ‘서비스’의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.
                            다만, 정기 점검 및 기타 기술상의 이유로 ‘서비스’가 일시 중지될
                            수 있고 또한, 운영상의 목적으로 ‘회사’가 정한 기간에도 일시
                            중지될 수 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 효율적인 업무 수행을 위하여 필요하다고 판단하는
                            경우 ‘서비스’를 일정 범위로 분할 하여 각 범위 별로 이용가능
                            시간을 달리 정할 수 있으며, 이 경우 그 내용을 공지 합니다.
                            <br/>
                            <br/>
                            제 9 조 (서비스 제공의 중지)
                            <br/>
                            <br/>
                            (1) ‘회사’는 다음 각 호에 해당하는 사유가 있는 경우 ‘서비스’
                            제공의 전부 또는 일부를 중지할 수 있습니다.
                            <br/>
                            <br/>① ‘서비스’를 위한 설비의 보수 등 공사로 인한 부득이한 경우{" "}
                            <br/>② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로
                            정상적인 ‘서비스’ 이용에 지장이 있는 경우 <br/>③ ‘서비스’
                            계약종료 등과 같은 ‘인터넷사업자’의 제반 사정으로 ‘서비스’를
                            유지할 수 업는 경우 <br/>④ 기타 천재지변, 국가비상사태 등
                            불가항력적 사유가 있는 경우
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘서비스’의 변경, 중지로 발생하는 문제에 대해서는
                            어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 10 조 (서비스의 안전성 확보)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’의 안전성과 신뢰성을 확보하기 위하여
                            업무처리지침의 제정 및 시행, 정보처리시스템 및 전산자료의 관리
                            등의 관리적 조치와 모니터링 체계 및 해킹방지시스템 구축 및 운영
                            등의 기술적 조치를 취하고 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 서버 및 통신기기의 정상작동여부 확인을 위하여
                            정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한 모니터링
                            체계를 갖추고 있습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및
                            프로그램을 설치하여 운영합니다.
                            <br/>
                            <br/>
                            ① 침입차단시스템 설치 <br/>② 침입탐지시스템 설치 <br/>③ 그
                            밖에 필요한 보호장비 또는 암호프로그램 등 정보보호시스템 설치
                            <br/>
                            <br/>
                            (4) ‘회사’는 컴퓨터바이러스 감염을 방지하기 위하여 다음 각 호를
                            포함한 대책을 수립, 운영하고 있습니다.
                            <br/>
                            <br/>
                            ① 출처, 유통경로 및 제작자가 명확하지 아니한 응용프로그램은
                            사용을 자제하고 불가피할 경우에는 컴퓨터바이러스
                            검색프로그램으로 진단 및 치료 후 사용할 것 <br/>②
                            컴퓨터바이러스 검색, 치료 프로그램을 설치하고 최신 버전을 유지할
                            것 <br/>③ 컴퓨터바이러스 감염에 대비하여 방어, 탐색 및 복구
                            절차를 마련할 것
                            <br/>
                            <br/>
                            제 11 조 (이용자의 개인정보보호)
                            <br/>
                            <br/>
                            ‘회사’는 관련법령이 정하는 바에 따라서 ‘이용자’의 개인정보를
                            보호하기 위하여 노력합니다. ‘이용자’의 개인정보보호에 관한
                            사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에 정한
                            바에 따릅니다.
                            <br/>
                            <br/>
                            제 12 조 (개인정보의 위탁)
                            <br/>
                            <br/>
                            ‘회사’는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로
                            수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를
                            ‘회사’가 선정한 회사에 위탁할 수 있습니다. 개인정보의 위탁에
                            관한 사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에
                            정한 바에 따릅니다.
                            <br/>
                            <br/>
                            제 13 조 (손해배상)
                            <br/>
                            <br/>
                            ‘회사’는 ‘서비스’의 이용과 관련하여 ‘이용자’에게 발생한
                            ‘회사’측의 중과실에 의한 손해를 제외한 어떠한 손해에 관하여도
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 14 조 (회사와 인터넷사업자와의 관계)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘인터넷사업자’가 제공하는 서비스 상품 또는 용역
                            등에 대하여 보증책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’와 ‘인터넷사업자’는 독자적으로 사이트를 운영하며,
                            ‘인터넷사업자’와 ‘이용자’간에 행해진 거래에 대하여 고의 또는
                            중과실이 있는 경우를 제외하고는 어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 15 조 (면책)
                            <br/>
                            <br/>
                            (1) ‘회사’의 고의 또는 과실 없이 ‘서비스’를 제공할 수 없는
                            경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’ 또는 제3자 측의 사정으로 인한 ‘서비스’
                            이용의 장애에 대하여 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 고의 또는 중과실이 없는 한 ‘이용자’가 ‘서비스’를
                            이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며
                            그밖에 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을
                            지지 않습니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 ‘이용자’가 ‘서비스’에 게재한 정보, 자료, 사실의
                            신뢰도, 정확성 등 내용에 관하여는 고의 또는 중과실이 없는 한
                            일체의 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (5) 계약만료 또는 요금 미납의 결과로 사용자계정이 정지되거나,
                            보관 파일이 삭제된 후 이에 따르는 손해에 대하여 책임을 지지
                            않습니다.
                            <br/>
                            <br/>
                            제 16 조 (관할 법원)
                            <br/>
                            <br/>
                            (1) ‘회사’와 ‘이용자’는 ‘서비스’와 관련하여 분쟁이 발생한 경우,
                            원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
                            <br/>
                            <br/>
                            (2) 제1항의 협의에서도 분쟁이 해결되지 않은 경우 등, 약관과
                            관련하여 분쟁으로 인한 소송이 제기될 경우 동 소송은
                            서울중앙지방법원을 관할 법원으로 합니다.
                            <br/>
                            <br/>
                            [부칙]
                            <br/>
                            <br/>
                            (시행일) 이 약관은 2013년 2월 15일부터 그 효력이 발생합니다.
                            (2013/02/15 신설)
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showServiceTermsKt = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>서비스 이용약관</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            SCI 본인확인서비스 이용 약관
                            <br/>
                            <br/>
                            제 1 조 (목적)
                            <br/>
                            <br/>
                            본 약관은 SCI평가정보㈜(이하 ‘회사’라 한다)가 제공하는
                            본인확인서비스(이하 ‘서비스’라 한다)에 관한 이용조건 및 절차 등
                            기본적인 사항을 규정함을 목적으로 합니다.
                            <br/>
                            <br/>
                            제 2 조 (약관의 효력 및 변경)
                            <br/>
                            <br/>
                            (1) 본 약관은 ‘이용자’에게 ‘서비스’ 화면에 게시하거나, 기타의
                            방법으로 공지하는 것으로 효력이 발생합니다.
                            <br/>
                            <br/>
                            (2) 회사’는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지
                            않는 범위에서 본 약관의 내용을 개정할 수 있으며, 변경된 경우에는
                            제1항과 같은 방법으로 공지합니다. 다만 ‘이용자’의 권리와 의무에
                            관한 중요한 사항은 변경된 내용의 시행 15일 이전에 공지합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 변경된 약관에 대한 내용을 알지 못하여 발생하는
                            손해 및 피해에 대해서는 ‘회사’가 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 3 조 (약관 외 준칙)
                            <br/>
                            <br/>
                            본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용 촉진
                            및 정보보호 등에 관한 법률 등 기타 관련 법령 또는 상관례에
                            따릅니다.
                            <br/>
                            <br/>
                            제 4 조 (용어의 정의)
                            <br/>
                            <br/>
                            본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                            <br/>
                            <br/>
                            (1) ‘본인확인서비스’라 함은 ‘이용자’가 인터넷상에서 휴대폰 등의
                            인증수단을 이용하여, 본인임을 안전하게 식별 및 확인해 주는
                            서비스를 말합니다.
                            <br/>
                            <br/>
                            (2) ‘이용자’라 함은 ‘회사’에서 제공하는 ‘서비스’의 이용을 위해
                            자신의 ‘본인확인정보’를 ‘회사’ 및 ‘본인확인기관’ 등에게
                            제공하고, 본인임을 확인 받고자 하는 자를 말합니다.
                            <br/>
                            <br/>
                            (3) ‘본인확인정보’라 함은 ‘이용자’가 입력한 생년월일, 성별,
                            성명, 내/외국인, 휴대폰번호, 통신사 등 본인 식별에 필요한
                            ‘이용자’의 정보를 말합니다.
                            <br/>
                            <br/>
                            (4) ‘본인확인기관’이라 함은 주민등록번호를 사용하지 아니하고
                            본인을 확인하는 방법 또는 서비스를 제공하는 자로
                            방송통신위원회로부터 본인확인기관으로 지정을 받은 자를
                            의미합니다.
                            <br/>
                            <br/>
                            (5) ‘인터넷사업자’라 함은 인터넷을 이용하여 정보를 제공하거나,
                            정보의 제공을 매개하는 일을 업으로 하는 자로 ‘회사’와 계약을
                            통해 운영하며, 인터넷 웹사이트의 ‘이용자’에 대한
                            ‘본인확인정보’를 제공 받는 사업체를 말합니다.
                            <br/>
                            <br/>
                            (6) ‘중복가입확인정보’라 함은 웹사이트에 가입하고자 하는
                            이용자의 중복 확인을 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            (7) ‘연계정보’ 라 함은 ‘인터넷사업자’의 온ㆍ오프라인 서비스 연계
                            등의 목적으로 ‘이용자’를 식별하기 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            제 5 조 (회사의 의무)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’ 제공과 관련하여 인지한 ‘이용자’의
                            ‘본인확인정보’를 본인의 승낙 없이 제3자에게 누설하거나 배포하지
                            않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의
                            목적이 있는 경우 등 기타 관계법령에서 정한 절차에 따른 요청이
                            있는 경우에는 그러하지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’에게 안전한 ‘서비스’ 제공을 위하여
                            지속적으로 ‘서비스’의 예방점검, 유지보수 등을 이행하며,
                            ‘서비스’의 장애가 발생하는 경우, 이를 지체 없이 수리 및
                            복구합니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 ‘이용자’가 제기한 의견, 불만이 타당하다고 인정되는
                            경우 적절한 절차를 거쳐 이를 지체 없이 처리하거나 처리에 관한
                            일정을 통지하여야 합니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 접근매체의 발급 주체가 아닌 경우에는 접근 매체의
                            위조나 변조로 발생한 사고로 인하여,‘이용자’에게 발생한 손해에
                            대하여 배상책임이 없습니다.
                            <br/>
                            <br/>
                            제 6 조 (이용자의 의무)
                            <br/>
                            <br/>
                            (1) ‘이용자’는 ‘서비스’를 이용함에 있어서 다음 각호에 해당하는
                            행위를 하여서는 안되며, ‘회사’는 위반행위에 따르는 일체의 법적
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            ① 타 ‘이용자’의 ‘본인확인정보’를 부정하게 사용 및 도용하는 행위
                            <br/>
                            ② ‘회사’의 저작권, 제3자의 저작원 등 기타 권리를 침해하는 행위
                            <br/>
                            ③ 범죄 행위
                            <br/>
                            ④ 기타 관련 법령에 위배되는 행위
                            <br/>
                            <br/>
                            (2) ‘이용자’는 자신의 접근매체를 제3자에게 누설 또는 노출하거나
                            방치하여서는 안되며, 접근 매체의 도용이나 위조 또는 변조를
                            방지하기 위하여 충분한 주의를 기울여야 합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 본 약관에서 규정하는 사항과 ‘서비스’에 대한
                            이용안내 또는 주의사항을 준수하여야 합니다.
                            <br/>
                            <br/>
                            제 7 조 (서비스의 내용)
                            <br/>
                            <br/>
                            (1) ‘서비스’는 ‘이용자’가 주민등록번호의 입력 없이, 본인명의로
                            된 인증수단(휴대폰 등)을 이용하여 본인 확인이 가능한 인증 서비스
                            입니다.
                            <br/>
                            <br/>
                            (2) ‘인터넷사업자’는 회원가입, ID/PW찾기, 성인인증, 기타
                            본인확인이 필요한 콘텐츠 요청 시 ‘이용자’에게 ‘서비스’를 제공
                            합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 자신의 생년월일, 성명, 성별, 내/외국인,
                            휴대폰번호, 통신사 등의 정보를 입력 후, 입력한정보가 일치한 경우
                            해당 휴대폰번호로 1회성 비밀번호(승인번호)가 발송되며, 수신된
                            승인번호를 정확하게 입력하는 것으로 본인 확인이 이루어 집니다.
                            <br/>
                            <br/>
                            제 8 조 (서비스 제공시간)
                            <br/>
                            <br/>
                            (1) ‘서비스’의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.
                            다만, 정기 점검 및 기타 기술상의 이유로 ‘서비스’가 일시 중지될
                            수 있고 또한, 운영상의 목적으로 ‘회사’가 정한 기간에도 일시
                            중지될 수 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 효율적인 업무 수행을 위하여 필요하다고 판단하는
                            경우 ‘서비스’를 일정 범위로 분할 하여 각 범위 별로 이용가능
                            시간을 달리 정할 수 있으며, 이 경우 그 내용을 공지 합니다.
                            <br/>
                            <br/>
                            제 9 조 (서비스 제공의 중지)
                            <br/>
                            <br/>
                            (1) ‘회사’는 다음 각 호에 해당하는 사유가 있는 경우 ‘서비스’
                            제공의 전부 또는 일부를 중지할 수 있습니다.
                            <br/>
                            <br/>
                            ① ‘서비스’를 위한 설비의 보수 등 공사로 인한 부득이한 경우
                            <br/>
                            ② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
                            ‘서비스’ 이용에 지장이 있는 경우
                            <br/>
                            ③ ‘서비스’ 계약종료 등과 같은 ‘인터넷사업자’의 제반 사정으로
                            ‘서비스’를 유지할 수 업는 경우
                            <br/>
                            ④ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘서비스’의 변경, 중지로 발생하는 문제에 대해서는
                            어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 10 조 (서비스의 안전성 확보)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’의 안전성과 신뢰성을 확보하기 위하여
                            업무처리지침의 제정 및 시행, 정보처리시스템 및 전산자료의 관리
                            등의 관리적 조치와 모니터링 체계 및 해킹방지시스템 구축 및 운영
                            등의 기술적 조치를 취하고 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 서버 및 통신기기의 정상작동여부 확인을 위하여
                            정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한 모니터링
                            체계를 갖추고 있습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및
                            프로그램을 설치하여 운영합니다.
                            <br/>
                            <br/>
                            ① 침입차단시스템 설치
                            <br/>
                            ② 침입탐지시스템 설치
                            <br/>
                            ③ 그 밖에 필요한 보호장비 또는 암호프로그램 등 정보보호시스템
                            설치
                            <br/>
                            <br/>
                            (4) ‘회사’는 컴퓨터바이러스 감염을 방지하기 위하여 다음 각 호를
                            포함한 대책을 수립, 운영하고 있습니다.
                            <br/>
                            <br/>
                            ① 출처, 유통경로 및 제작자가 명확하지 아니한 응용프로그램은
                            사용을 자제하고 불가피할 경우에는 컴퓨터바이러스
                            검색프로그램으로 진단 및 치료 후 사용할 것
                            <br/>② 컴퓨터바이러스 검색, 치료 프로그램을 설치하고 최신
                            버전을 유지할 것
                            <br/>③ 컴퓨터바이러스 감염에 대비하여 방어, 탐색 및 복구 절차를
                            마련할 것
                            <br/>
                            <br/>
                            제 11 조 (이용자의 개인정보보호)
                            <br/>
                            <br/>
                            ‘회사’는 관련법령이 정하는 바에 따라서 ‘이용자’의 개인정보를
                            보호하기 위하여 노력합니다. ‘이용자’의 개인정보보호에 관한
                            사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에 정한
                            바에 따릅니다.
                            <br/>
                            <br/>
                            제 12 조 (개인정보의 위탁)
                            <br/>
                            <br/>
                            ‘회사’는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로
                            수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를
                            ‘회사’가 선정한 회사에 위탁할 수 있습니다. 개인정보의 위탁에
                            관한 사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에
                            정한 바에 따릅니다.
                            <br/>
                            <br/>
                            제 13 조 (손해배상)
                            <br/>
                            <br/>
                            ‘회사’는 ‘서비스’의 이용과 관련하여 ‘이용자’에게 발생한
                            ‘회사’측의 중과실에 의한 손해를 제외한 어떠한 손해에 관하여도
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 14 조 (회사와 인터넷사업자와의 관계
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘인터넷사업자’가 제공하는 서비스 상품 또는 용역
                            등에 대하여 보증책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’와 ‘인터넷사업자’는 독자적으로 사이트를 운영하며,
                            ‘인터넷사업자’와 ‘이용자’간에 행해진 거래에 대하여 고의 또는
                            중과실이 있는 경우를 제외하고는 어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 15 조 (면책)
                            <br/>
                            <br/>
                            (1) ‘회사’의 고의 또는 과실 없이 ‘서비스’를 제공할 수 없는
                            경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’ 또는 제3자 측의 사정으로 인한 ‘서비스’
                            이용의 장애에 대하여 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 고의 또는 중과실이 없는 한 ‘이용자’가 ‘서비스’를
                            이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며
                            그밖에 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을
                            지지 않습니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 ‘이용자’가 ‘서비스’에 게재한 정보, 자료, 사실의
                            신뢰도, 정확성 등 내용에 관하여는 고의 또는 중과실이 없는 한
                            일체의 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (5) 계약만료 또는 요금 미납의 결과로 사용자계정이 정지되거나,
                            보관 파일이 삭제된 후 이에 따르는 손해에 대하여 책임을 지지
                            않습니다.
                            <br/>
                            <br/>
                            제 16 조 (관할 법원)
                            <br/>
                            <br/>
                            (1) ‘회사’와 ‘이용자’는 ‘서비스’와 관련하여 분쟁이 발생한 경우,
                            원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
                            <br/>
                            <br/>
                            (2) 제1항의 협의에서도 분쟁이 해결되지 않은 경우 등, 약관과
                            관련하여 분쟁으로 인한 소송이 제기될 경우 동 소송은
                            서울중앙지방법원을 관할 법원으로 합니다.
                            <br/>
                            <br/>
                            [부칙]
                            <br/>
                            <br/>
                            (시행일) 이 약관은 2013년 2월 15일부터 그 효력이 발생합니다.
                            (2013/02/15 신설)
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showServiceTermsLgu = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>서비스 이용약관</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            SCI 본인확인서비스 이용 약관
                            <br/>
                            <br/>
                            제 1 조 (목적)
                            <br/>
                            <br/>
                            본 약관은 SCI평가정보㈜(이하 ‘회사’라 한다)가 제공하는
                            본인확인서비스(이하 ‘서비스’라 한다)에 관한 이용조건 및 절차 등
                            기본적인 사항을 규정함을 목적으로 합니다.
                            <br/>
                            <br/>
                            제 2 조 (약관의 효력 및 변경)
                            <br/>
                            <br/>
                            (1) 본 약관은 ‘이용자’에게 ‘서비스’ 화면에 게시하거나, 기타의
                            방법으로 공지하는 것으로 효력이 발생합니다.
                            <br/>
                            <br/>
                            (2) 회사’는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지
                            않는 범위에서 본 약관의 내용을 개정할 수 있으며, 변경된 경우에는
                            제1항과 같은 방법으로 공지합니다. 다만 ‘이용자’의 권리와 의무에
                            관한 중요한 사항은 변경된 내용의 시행 15일 이전에 공지합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 변경된 약관에 대한 내용을 알지 못하여 발생하는
                            손해 및 피해에 대해서는 ‘회사’가 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 3 조 (약관 외 준칙)
                            <br/>
                            <br/>
                            본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용 촉진
                            및 정보보호 등에 관한 법률 등 기타 관련 법령 또는 상관례에
                            따릅니다.
                            <br/>
                            <br/>
                            제 4 조 (용어의 정의)
                            <br/>
                            <br/>
                            본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                            <br/>
                            <br/>
                            (1) ‘본인확인서비스’라 함은 ‘이용자’가 인터넷상에서 휴대폰 등의
                            인증수단을 이용하여, 본인임을 안전하게 식별 및 확인해 주는
                            서비스를 말합니다.
                            <br/>
                            <br/>
                            (2) ‘이용자’라 함은 ‘회사’에서 제공하는 ‘서비스’의 이용을 위해
                            자신의 ‘본인확인정보’를 ‘회사’ 및 ‘본인확인기관’ 등에게
                            제공하고, 본인임을 확인 받고자 하는 자를 말합니다.
                            <br/>
                            <br/>
                            (3) ‘본인확인정보’라 함은 ‘이용자’가 입력한 생년월일, 성별,
                            성명, 내/외국인, 휴대폰번호, 통신사 등 본인 식별에 필요한
                            ‘이용자’의 정보를 말합니다.
                            <br/>
                            <br/>
                            (4) ‘본인확인기관’이라 함은 주민등록번호를 사용하지 아니하고
                            본인을 확인하는 방법 또는 서비스를 제공하는 자로
                            방송통신위원회로부터 본인확인기관으로 지정을 받은 자를
                            의미합니다.
                            <br/>
                            <br/>
                            (5) ‘인터넷사업자’라 함은 인터넷을 이용하여 정보를 제공하거나,
                            정보의 제공을 매개하는 일을 업으로 하는 자로 ‘회사’와 계약을
                            통해 운영하며, 인터넷 웹사이트의 ‘이용자’에 대한
                            ‘본인확인정보’를 제공 받는 사업체를 말합니다.
                            <br/>
                            <br/>
                            (6) ‘중복가입확인정보’라 함은 웹사이트에 가입하고자 하는
                            이용자의 중복 확인을 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            (7) ‘연계정보’ 라 함은 ‘인터넷사업자’의 온ㆍ오프라인 서비스 연계
                            등의 목적으로 ‘이용자’를 식별하기 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            제 5 조 (회사의 의무)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’ 제공과 관련하여 인지한 ‘이용자’의
                            ‘본인확인정보’를 본인의 승낙 없이 제3자에게 누설하거나 배포하지
                            않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의
                            목적이 있는 경우 등 기타 관계법령에서 정한 절차에 따른 요청이
                            있는 경우에는 그러하지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’에게 안전한 ‘서비스’ 제공을 위하여
                            지속적으로 ‘서비스’의 예방점검, 유지보수 등을 이행하며,
                            ‘서비스’의 장애가 발생하는 경우, 이를 지체 없이 수리 및
                            복구합니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 ‘이용자’가 제기한 의견, 불만이 타당하다고 인정되는
                            경우 적절한 절차를 거쳐 이를 지체 없이 처리하거나 처리에 관한
                            일정을 통지하여야 합니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 접근매체의 발급 주체가 아닌 경우에는 접근 매체의
                            위조나 변조로 발생한 사고로 인하여,‘이용자’에게 발생한 손해에
                            대하여 배상책임이 없습니다.
                            <br/>
                            <br/>
                            제 6 조 (이용자의 의무)
                            <br/>
                            <br/>
                            (1) ‘이용자’는 ‘서비스’를 이용함에 있어서 다음 각호에 해당하는
                            행위를 하여서는 안되며, ‘회사’는 위반행위에 따르는 일체의 법적
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            ① 타 ‘이용자’의 ‘본인확인정보’를 부정하게 사용 및 도용하는 행위
                            <br/>
                            ② ‘회사’의 저작권, 제3자의 저작원 등 기타 권리를 침해하는 행위
                            <br/>
                            ③ 범죄 행위
                            <br/>
                            ④ 기타 관련 법령에 위배되는 행위
                            <br/>
                            <br/>
                            (2) ‘이용자’는 자신의 접근매체를 제3자에게 누설 또는 노출하거나
                            방치하여서는 안되며, 접근 매체의 도용이나 위조 또는 변조를
                            방지하기 위하여 충분한 주의를 기울여야 합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 본 약관에서 규정하는 사항과 ‘서비스’에 대한
                            이용안내 또는 주의사항을 준수하여야 합니다.
                            <br/>
                            <br/>
                            제 7 조 (서비스의 내용)
                            <br/>
                            <br/>
                            (1) ‘서비스’는 ‘이용자’가 주민등록번호의 입력 없이, 본인명의로
                            된 인증수단(휴대폰 등)을 이용하여 본인 확인이 가능한 인증 서비스
                            입니다.
                            <br/>
                            <br/>
                            (2) ‘인터넷사업자’는 회원가입, ID/PW찾기, 성인인증, 기타
                            본인확인이 필요한 콘텐츠 요청 시 ‘이용자’에게 ‘서비스’를 제공
                            합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 자신의 생년월일, 성명, 성별, 내/외국인,
                            휴대폰번호, 통신사 등의 정보를 입력 후, 입력한정보가 일치한 경우
                            해당 휴대폰번호로 1회성 비밀번호(승인번호)가 발송되며, 수신된
                            승인번호를 정확하게 입력하는 것으로 본인 확인이 이루어 집니다.
                            <br/>
                            <br/>
                            제 8 조 (서비스 제공시간)
                            <br/>
                            <br/>
                            (1) ‘서비스’의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.
                            다만, 정기 점검 및 기타 기술상의 이유로 ‘서비스’가 일시 중지될
                            수 있고 또한, 운영상의 목적으로 ‘회사’가 정한 기간에도 일시
                            중지될 수 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 효율적인 업무 수행을 위하여 필요하다고 판단하는
                            경우 ‘서비스’를 일정 범위로 분할 하여 각 범위 별로 이용가능
                            시간을 달리 정할 수 있으며, 이 경우 그 내용을 공지 합니다.
                            <br/>
                            <br/>
                            제 9 조 (서비스 제공의 중지)
                            <br/>
                            <br/>
                            (1) ‘회사’는 다음 각 호에 해당하는 사유가 있는 경우 ‘서비스’
                            제공의 전부 또는 일부를 중지할 수 있습니다.
                            <br/>
                            <br/>
                            ① ‘서비스’를 위한 설비의 보수 등 공사로 인한 부득이한 경우
                            <br/>
                            ② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
                            ‘서비스’ 이용에 지장이 있는 경우
                            <br/>
                            ③ ‘서비스’ 계약종료 등과 같은 ‘인터넷사업자’의 제반 사정으로
                            ‘서비스’를 유지할 수 업는 경우
                            <br/>
                            ④ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘서비스’의 변경, 중지로 발생하는 문제에 대해서는
                            어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 10 조 (서비스의 안전성 확보)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’의 안전성과 신뢰성을 확보하기 위하여
                            업무처리지침의 제정 및 시행, 정보처리시스템 및 전산자료의 관리
                            등의 관리적 조치와 모니터링 체계 및 해킹방지시스템 구축 및 운영
                            등의 기술적 조치를 취하고 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 서버 및 통신기기의 정상작동여부 확인을 위하여
                            정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한 모니터링
                            체계를 갖추고 있습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및
                            프로그램을 설치하여 운영합니다.
                            <br/>
                            <br/>
                            ① 침입차단시스템 설치
                            <br/>
                            ② 침입탐지시스템 설치
                            <br/>
                            ③ 그 밖에 필요한 보호장비 또는 암호프로그램 등 정보보호시스템
                            설치
                            <br/>
                            <br/>
                            (4) ‘회사’는 컴퓨터바이러스 감염을 방지하기 위하여 다음 각 호를
                            포함한 대책을 수립, 운영하고 있습니다.
                            <br/>
                            <br/>
                            ① 출처, 유통경로 및 제작자가 명확하지 아니한 응용프로그램은
                            사용을 자제하고 불가피할 경우에는 컴퓨터바이러스
                            검색프로그램으로 진단 및 치료 후 사용할 것
                            <br/>② 컴퓨터바이러스 검색, 치료 프로그램을 설치하고 최신
                            버전을 유지할 것
                            <br/>③ 컴퓨터바이러스 감염에 대비하여 방어, 탐색 및 복구 절차를
                            마련할 것
                            <br/>
                            <br/>
                            제 11 조 (이용자의 개인정보보호)
                            <br/>
                            <br/>
                            ‘회사’는 관련법령이 정하는 바에 따라서 ‘이용자’의 개인정보를
                            보호하기 위하여 노력합니다. ‘이용자’의 개인정보보호에 관한
                            사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에 정한
                            바에 따릅니다.
                            <br/>
                            <br/>
                            제 12 조 (개인정보의 위탁)
                            <br/>
                            <br/>
                            ‘회사’는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로
                            수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를
                            ‘회사’가 선정한 회사에 위탁할 수 있습니다. 개인정보의 위탁에
                            관한 사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에
                            정한 바에 따릅니다.
                            <br/>
                            <br/>
                            제 13 조 (손해배상)
                            <br/>
                            <br/>
                            ‘회사’는 ‘서비스’의 이용과 관련하여 ‘이용자’에게 발생한
                            ‘회사’측의 중과실에 의한 손해를 제외한 어떠한 손해에 관하여도
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 14 조 (회사와 인터넷사업자와의 관계)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘인터넷사업자’가 제공하는 서비스 상품 또는 용역
                            등에 대하여 보증책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’와 ‘인터넷사업자’는 독자적으로 사이트를 운영하며,
                            ‘인터넷사업자’와 ‘이용자’간에 행해진 거래에 대하여 고의 또는
                            중과실이 있는 경우를 제외하고는 어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 15 조 (면책)
                            <br/>
                            <br/>
                            (1) ‘회사’의 고의 또는 과실 없이 ‘서비스’를 제공할 수 없는
                            경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’ 또는 제3자 측의 사정으로 인한 ‘서비스’
                            이용의 장애에 대하여 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 고의 또는 중과실이 없는 한 ‘이용자’가 ‘서비스’를
                            이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며
                            그밖에 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을
                            지지 않습니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 ‘이용자’가 ‘서비스’에 게재한 정보, 자료, 사실의
                            신뢰도, 정확성 등 내용에 관하여는 고의 또는 중과실이 없는 한
                            일체의 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (5) 계약만료 또는 요금 미납의 결과로 사용자계정이 정지되거나,
                            보관 파일이 삭제된 후 이에 따르는 손해에 대하여 책임을 지지
                            않습니다.
                            <br/>
                            <br/>
                            제 16 조 (관할 법원)
                            <br/>
                            <br/>
                            (1) ‘회사’와 ‘이용자’는 ‘서비스’와 관련하여 분쟁이 발생한 경우,
                            원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
                            <br/>
                            <br/>
                            (2) 제1항의 협의에서도 분쟁이 해결되지 않은 경우 등, 약관과
                            관련하여 분쟁으로 인한 소송이 제기될 경우 동 소송은
                            서울중앙지방법원을 관할 법원으로 합니다.
                            <br/>
                            <br/>
                            [부칙]
                            <br/>
                            <br/>
                            (시행일) 이 약관은 2013년 2월 15일부터 그 효력이 발생합니다.
                            (2013/02/15 신설)
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showServiceTermsSktMvno = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>서비스 이용약관</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            SCI 본인확인서비스 이용 약관
                            <br/>
                            <br/>
                            제 1 조 (목적)
                            <br/>
                            <br/>
                            본 약관은 SCI평가정보㈜(이하 ‘회사’라 한다)가 제공하는
                            본인확인서비스(이하 ‘서비스’라 한다)에 관한 이용조건 및 절차 등
                            기본적인 사항을 규정함을 목적으로 합니다.
                            <br/>
                            <br/>
                            제 2 조 (약관의 효력 및 변경)
                            <br/>
                            <br/>
                            (1) 본 약관은 ‘이용자’에게 ‘서비스’ 화면에 게시하거나, 기타의
                            방법으로 공지하는 것으로 효력이 발생합니다.
                            <br/>
                            <br/>
                            (2) 회사’는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지
                            않는 범위에서 본 약관의 내용을 개정할 수 있으며, 변경된 경우에는
                            제1항과 같은 방법으로 공지합니다. 다만 ‘이용자’의 권리와 의무에
                            관한 중요한 사항은 변경된 내용의 시행 15일 이전에 공지합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 변경된 약관에 대한 내용을 알지 못하여 발생하는
                            손해 및 피해에 대해서는 ‘회사’가 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 3 조 (약관 외 준칙)
                            <br/>
                            <br/>
                            본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용 촉진
                            및 정보보호 등에 관한 법률 등 기타 관련 법령 또는 상관례에
                            따릅니다.
                            <br/>
                            <br/>
                            제 4 조 (용어의 정의)
                            <br/>
                            <br/>
                            본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                            <br/>
                            <br/>
                            (1) ‘본인확인서비스’라 함은 ‘이용자’가 인터넷상에서 휴대폰 등의
                            인증수단을 이용하여, 본인임을 안전하게 식별 및 확인해 주는
                            서비스를 말합니다.
                            <br/>
                            <br/>
                            (2) ‘이용자’라 함은 ‘회사’에서 제공하는 ‘서비스’의 이용을 위해
                            자신의 ‘본인확인정보’를 ‘회사’ 및 ‘본인확인기관’ 등에게
                            제공하고, 본인임을 확인 받고자 하는 자를 말합니다.
                            <br/>
                            <br/>
                            (3) ‘본인확인정보’라 함은 ‘이용자’가 입력한 생년월일, 성별,
                            성명, 내/외국인, 휴대폰번호, 통신사 등 본인 식별에 필요한
                            ‘이용자’의 정보를 말합니다.
                            <br/>
                            <br/>
                            (4) ‘본인확인기관’이라 함은 주민등록번호를 사용하지 아니하고
                            본인을 확인하는 방법 또는 서비스를 제공하는 자로
                            방송통신위원회로부터 본인확인기관으로 지정을 받은 자를
                            의미합니다.
                            <br/>
                            <br/>
                            (5) ‘인터넷사업자’라 함은 인터넷을 이용하여 정보를 제공하거나,
                            정보의 제공을 매개하는 일을 업으로 하는 자로 ‘회사’와 계약을
                            통해 운영하며, 인터넷 웹사이트의 ‘이용자’에 대한
                            ‘본인확인정보’를 제공 받는 사업체를 말합니다.
                            <br/>
                            <br/>
                            (6) ‘중복가입확인정보’라 함은 웹사이트에 가입하고자 하는
                            이용자의 중복 확인을 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            (7) ‘연계정보’ 라 함은 ‘인터넷사업자’의 온ㆍ오프라인 서비스 연계
                            등의 목적으로 ‘이용자’를 식별하기 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            제 5 조 (회사의 의무)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’ 제공과 관련하여 인지한 ‘이용자’의
                            ‘본인확인정보’를 본인의 승낙 없이 제3자에게 누설하거나 배포하지
                            않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의
                            목적이 있는 경우 등 기타 관계법령에서 정한 절차에 따른 요청이
                            있는 경우에는 그러하지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’에게 안전한 ‘서비스’ 제공을 위하여
                            지속적으로 ‘서비스’의 예방점검, 유지보수 등을 이행하며,
                            ‘서비스’의 장애가 발생하는 경우, 이를 지체 없이 수리 및
                            복구합니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 ‘이용자’가 제기한 의견, 불만이 타당하다고 인정되는
                            경우 적절한 절차를 거쳐 이를 지체 없이 처리하거나 처리에 관한
                            일정을 통지하여야 합니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 접근매체의 발급 주체가 아닌 경우에는 접근 매체의
                            위조나 변조로 발생한 사고로 인하여,‘이용자’에게 발생한 손해에
                            대하여 배상책임이 없습니다.
                            <br/>
                            <br/>
                            제 6 조 (이용자의 의무)
                            <br/>
                            <br/>
                            (1) ‘이용자’는 ‘서비스’를 이용함에 있어서 다음 각호에 해당하는
                            행위를 하여서는 안되며, ‘회사’는 위반행위에 따르는 일체의 법적
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            ① 타 ‘이용자’의 ‘본인확인정보’를 부정하게 사용 및 도용하는 행위
                            <br/>
                            ② ‘회사’의 저작권, 제3자의 저작원 등 기타 권리를 침해하는 행위
                            <br/>
                            ③ 범죄 행위
                            <br/>
                            ④ 기타 관련 법령에 위배되는 행위
                            <br/>
                            <br/>
                            (2) ‘이용자’는 자신의 접근매체를 제3자에게 누설 또는 노출하거나
                            방치하여서는 안되며, 접근 매체의 도용이나 위조 또는 변조를
                            방지하기 위하여 충분한 주의를 기울여야 합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 본 약관에서 규정하는 사항과 ‘서비스’에 대한
                            이용안내 또는 주의사항을 준수하여야 합니다.
                            <br/>
                            <br/>
                            제 7 조 (서비스의 내용)
                            <br/>
                            <br/>
                            (1) ‘서비스’는 ‘이용자’가 주민등록번호의 입력 없이, 본인명의로
                            된 인증수단(휴대폰 등)을 이용하여 본인 확인이 가능한 인증 서비스
                            입니다.
                            <br/>
                            <br/>
                            (2) ‘인터넷사업자’는 회원가입, ID/PW찾기, 성인인증, 기타
                            본인확인이 필요한 콘텐츠 요청 시 ‘이용자’에게 ‘서비스’를 제공
                            합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 자신의 생년월일, 성명, 성별, 내/외국인,
                            휴대폰번호, 통신사 등의 정보를 입력 후, 입력한정보가 일치한 경우
                            해당 휴대폰번호로 1회성 비밀번호(승인번호)가 발송되며, 수신된
                            승인번호를 정확하게 입력하는 것으로 본인 확인이 이루어 집니다.
                            <br/>
                            <br/>
                            제 8 조 (서비스 제공시간)
                            <br/>
                            <br/>
                            (1) ‘서비스’의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.
                            다만, 정기 점검 및 기타 기술상의 이유로 ‘서비스’가 일시 중지될
                            수 있고 또한, 운영상의 목적으로 ‘회사’가 정한 기간에도 일시
                            중지될 수 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 효율적인 업무 수행을 위하여 필요하다고 판단하는
                            경우 ‘서비스’를 일정 범위로 분할 하여 각 범위 별로 이용가능
                            시간을 달리 정할 수 있으며, 이 경우 그 내용을 공지 합니다.
                            <br/>
                            <br/>
                            제 9 조 (서비스 제공의 중지)
                            <br/>
                            <br/>
                            (1) ‘회사’는 다음 각 호에 해당하는 사유가 있는 경우 ‘서비스’
                            제공의 전부 또는 일부를 중지할 수 있습니다.
                            <br/>
                            <br/>
                            ① ‘서비스’를 위한 설비의 보수 등 공사로 인한 부득이한 경우
                            <br/>
                            ② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
                            ‘서비스’ 이용에 지장이 있는 경우
                            <br/>
                            ③ ‘서비스’ 계약종료 등과 같은 ‘인터넷사업자’의 제반 사정으로
                            ‘서비스’를 유지할 수 업는 경우
                            <br/>
                            ④ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘서비스’의 변경, 중지로 발생하는 문제에 대해서는
                            어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 10 조 (서비스의 안전성 확보)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’의 안전성과 신뢰성을 확보하기 위하여
                            업무처리지침의 제정 및 시행, 정보처리시스템 및 전산자료의 관리
                            등의 관리적 조치와 모니터링 체계 및 해킹방지시스템 구축 및 운영
                            등의 기술적 조치를 취하고 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 서버 및 통신기기의 정상작동여부 확인을 위하여
                            정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한 모니터링
                            체계를 갖추고 있습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및
                            프로그램을 설치하여 운영합니다.
                            <br/>
                            <br/>
                            ① 침입차단시스템 설치
                            <br/>
                            ② 침입탐지시스템 설치
                            <br/>
                            ③ 그 밖에 필요한 보호장비 또는 암호프로그램 등 정보보호시스템
                            설치
                            <br/>
                            <br/>
                            (4) ‘회사’는 컴퓨터바이러스 감염을 방지하기 위하여 다음 각 호를
                            포함한 대책을 수립, 운영하고 있습니다.
                            <br/>
                            <br/>
                            ① 출처, 유통경로 및 제작자가 명확하지 아니한 응용프로그램은
                            사용을 자제하고 불가피할 경우에는 컴퓨터바이러스
                            검색프로그램으로 진단 및 치료 후 사용할 것
                            <br/>② 컴퓨터바이러스 검색, 치료 프로그램을 설치하고 최신
                            버전을 유지할 것
                            <br/>③ 컴퓨터바이러스 감염에 대비하여 방어, 탐색 및 복구 절차를
                            마련할 것
                            <br/>
                            <br/>
                            제 11 조 (이용자의 개인정보보호)
                            <br/>
                            <br/>
                            ‘회사’는 관련법령이 정하는 바에 따라서 ‘이용자’의 개인정보를
                            보호하기 위하여 노력합니다. ‘이용자’의 개인정보보호에 관한
                            사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에 정한
                            바에 따릅니다.
                            <br/>
                            <br/>
                            제 12 조 (개인정보의 위탁)
                            <br/>
                            <br/>
                            ‘회사’는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로
                            수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를
                            ‘회사’가 선정한 회사에 위탁할 수 있습니다. 개인정보의 위탁에
                            관한 사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에
                            정한 바에 따릅니다.
                            <br/>
                            <br/>
                            제 13 조 (손해배상)
                            <br/>
                            <br/>
                            ‘회사’는 ‘서비스’의 이용과 관련하여 ‘이용자’에게 발생한
                            ‘회사’측의 중과실에 의한 손해를 제외한 어떠한 손해에 관하여도
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 14 조 (회사와 인터넷사업자와의 관계)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘인터넷사업자’가 제공하는 서비스 상품 또는 용역
                            등에 대하여 보증책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’와 ‘인터넷사업자’는 독자적으로 사이트를 운영하며,
                            ‘인터넷사업자’와 ‘이용자’간에 행해진 거래에 대하여 고의 또는
                            중과실이 있는 경우를 제외하고는 어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 15 조 (면책)
                            <br/>
                            <br/>
                            (1) ‘회사’의 고의 또는 과실 없이 ‘서비스’를 제공할 수 없는
                            경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’ 또는 제3자 측의 사정으로 인한 ‘서비스’
                            이용의 장애에 대하여 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 고의 또는 중과실이 없는 한 ‘이용자’가 ‘서비스’를
                            이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며
                            그밖에 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을
                            지지 않습니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 ‘이용자’가 ‘서비스’에 게재한 정보, 자료, 사실의
                            신뢰도, 정확성 등 내용에 관하여는 고의 또는 중과실이 없는 한
                            일체의 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (5) 계약만료 또는 요금 미납의 결과로 사용자계정이 정지되거나,
                            보관 파일이 삭제된 후 이에 따르는 손해에 대하여 책임을 지지
                            않습니다.
                            <br/>
                            <br/>
                            제 16 조 (관할 법원)
                            <br/>
                            <br/>
                            (1) ‘회사’와 ‘이용자’는 ‘서비스’와 관련하여 분쟁이 발생한 경우,
                            원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
                            <br/>
                            <br/>
                            (2) 제1항의 협의에서도 분쟁이 해결되지 않은 경우 등, 약관과
                            관련하여 분쟁으로 인한 소송이 제기될 경우 동 소송은
                            서울중앙지방법원을 관할 법원으로 합니다.
                            <br/>
                            <br/>
                            [부칙]
                            <br/>
                            <br/>
                            (시행일) 이 약관은 2013년 2월 15일부터 그 효력이 발생합니다.
                            (2013/02/15 신설)
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showServiceTermsKtMvno = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>KT서비스 이용약관</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            SCI 본인확인서비스 이용 약관
                            <br/>
                            <br/>
                            제 1 조 (목적)
                            <br/>
                            <br/>
                            본 약관은 SCI평가정보㈜(이하 ‘회사’라 한다)가 제공하는
                            본인확인서비스(이하 ‘서비스’라 한다)에 관한 이용조건 및 절차 등
                            기본적인 사항을 규정함을 목적으로 합니다.
                            <br/>
                            <br/>
                            제 2 조 (약관의 효력 및 변경)
                            <br/>
                            <br/>
                            (1) 본 약관은 ‘이용자’에게 ‘서비스’ 화면에 게시하거나, 기타의
                            방법으로 공지하는 것으로 효력이 발생합니다.
                            <br/>
                            <br/>
                            (2) 회사’는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지
                            않는 범위에서 본 약관의 내용을 개정할 수 있으며, 변경된 경우에는
                            제1항과 같은 방법으로 공지합니다. 다만 ‘이용자’의 권리와 의무에
                            관한 중요한 사항은 변경된 내용의 시행 15일 이전에 공지합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 변경된 약관에 대한 내용을 알지 못하여 발생하는
                            손해 및 피해에 대해서는 ‘회사’가 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 3 조 (약관 외 준칙)
                            <br/>
                            <br/>
                            본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용 촉진
                            및 정보보호 등에 관한 법률 등 기타 관련 법령 또는 상관례에
                            따릅니다.
                            <br/>
                            <br/>
                            제 4 조 (용어의 정의)
                            <br/>
                            <br/>
                            본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                            <br/>
                            <br/>
                            (1) ‘본인확인서비스’라 함은 ‘이용자’가 인터넷상에서 휴대폰 등의
                            인증수단을 이용하여, 본인임을 안전하게 식별 및 확인해 주는
                            서비스를 말합니다.
                            <br/>
                            <br/>
                            (2) ‘이용자’라 함은 ‘회사’에서 제공하는 ‘서비스’의 이용을 위해
                            자신의 ‘본인확인정보’를 ‘회사’ 및 ‘본인확인기관’ 등에게
                            제공하고, 본인임을 확인 받고자 하는 자를 말합니다.
                            <br/>
                            <br/>
                            (3) ‘본인확인정보’라 함은 ‘이용자’가 입력한 생년월일, 성별,
                            성명, 내/외국인, 휴대폰번호, 통신사 등 본인 식별에 필요한
                            ‘이용자’의 정보를 말합니다.
                            <br/>
                            <br/>
                            (4) ‘본인확인기관’이라 함은 주민등록번호를 사용하지 아니하고
                            본인을 확인하는 방법 또는 서비스를 제공하는 자로
                            방송통신위원회로부터 본인확인기관으로 지정을 받은 자를
                            의미합니다.
                            <br/>
                            <br/>
                            (5) ‘인터넷사업자’라 함은 인터넷을 이용하여 정보를 제공하거나,
                            정보의 제공을 매개하는 일을 업으로 하는 자로 ‘회사’와 계약을
                            통해 운영하며, 인터넷 웹사이트의 ‘이용자’에 대한
                            ‘본인확인정보’를 제공 받는 사업체를 말합니다.
                            <br/>
                            <br/>
                            (6) ‘중복가입확인정보’라 함은 웹사이트에 가입하고자 하는
                            이용자의 중복 확인을 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            (7) ‘연계정보’ 라 함은 ‘인터넷사업자’의 온ㆍ오프라인 서비스 연계
                            등의 목적으로 ‘이용자’를 식별하기 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            제 5 조 (회사의 의무)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’ 제공과 관련하여 인지한 ‘이용자’의
                            ‘본인확인정보’를 본인의 승낙 없이 제3자에게 누설하거나 배포하지
                            않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의
                            목적이 있는 경우 등 기타 관계법령에서 정한 절차에 따른 요청이
                            있는 경우에는 그러하지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’에게 안전한 ‘서비스’ 제공을 위하여
                            지속적으로 ‘서비스’의 예방점검, 유지보수 등을 이행하며,
                            ‘서비스’의 장애가 발생하는 경우, 이를 지체 없이 수리 및
                            복구합니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 ‘이용자’가 제기한 의견, 불만이 타당하다고 인정되는
                            경우 적절한 절차를 거쳐 이를 지체 없이 처리하거나 처리에 관한
                            일정을 통지하여야 합니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 접근매체의 발급 주체가 아닌 경우에는 접근 매체의
                            위조나 변조로 발생한 사고로 인하여,‘이용자’에게 발생한 손해에
                            대하여 배상책임이 없습니다.
                            <br/>
                            <br/>
                            제 6 조 (이용자의 의무)
                            <br/>
                            <br/>
                            (1) ‘이용자’는 ‘서비스’를 이용함에 있어서 다음 각호에 해당하는
                            행위를 하여서는 안되며, ‘회사’는 위반행위에 따르는 일체의 법적
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            ① 타 ‘이용자’의 ‘본인확인정보’를 부정하게 사용 및 도용하는 행위
                            <br/>
                            ② ‘회사’의 저작권, 제3자의 저작원 등 기타 권리를 침해하는 행위
                            <br/>
                            ③ 범죄 행위
                            <br/>
                            ④ 기타 관련 법령에 위배되는 행위
                            <br/>
                            <br/>
                            (2) ‘이용자’는 자신의 접근매체를 제3자에게 누설 또는 노출하거나
                            방치하여서는 안되며, 접근 매체의 도용이나 위조 또는 변조를
                            방지하기 위하여 충분한 주의를 기울여야 합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 본 약관에서 규정하는 사항과 ‘서비스’에 대한
                            이용안내 또는 주의사항을 준수하여야 합니다.
                            <br/>
                            <br/>
                            제 7 조 (서비스의 내용)
                            <br/>
                            <br/>
                            (1) ‘서비스’는 ‘이용자’가 주민등록번호의 입력 없이, 본인명의로
                            된 인증수단(휴대폰 등)을 이용하여 본인 확인이 가능한 인증 서비스
                            입니다.
                            <br/>
                            <br/>
                            (2) ‘인터넷사업자’는 회원가입, ID/PW찾기, 성인인증, 기타
                            본인확인이 필요한 콘텐츠 요청 시 ‘이용자’에게 ‘서비스’를 제공
                            합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 자신의 생년월일, 성명, 성별, 내/외국인,
                            휴대폰번호, 통신사 등의 정보를 입력 후, 입력한정보가 일치한 경우
                            해당 휴대폰번호로 1회성 비밀번호(승인번호)가 발송되며, 수신된
                            승인번호를 정확하게 입력하는 것으로 본인 확인이 이루어 집니다.
                            <br/>
                            <br/>
                            제 8 조 (서비스 제공시간)
                            <br/>
                            <br/>
                            (1) ‘서비스’의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.
                            다만, 정기 점검 및 기타 기술상의 이유로 ‘서비스’가 일시 중지될
                            수 있고 또한, 운영상의 목적으로 ‘회사’가 정한 기간에도 일시
                            중지될 수 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 효율적인 업무 수행을 위하여 필요하다고 판단하는
                            경우 ‘서비스’를 일정 범위로 분할 하여 각 범위 별로 이용가능
                            시간을 달리 정할 수 있으며, 이 경우 그 내용을 공지 합니다.
                            <br/>
                            <br/>
                            제 9 조 (서비스 제공의 중지)
                            <br/>
                            <br/>
                            (1) ‘회사’는 다음 각 호에 해당하는 사유가 있는 경우 ‘서비스’
                            제공의 전부 또는 일부를 중지할 수 있습니다.
                            <br/>
                            <br/>
                            ① ‘서비스’를 위한 설비의 보수 등 공사로 인한 부득이한 경우
                            <br/>
                            ② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
                            ‘서비스’ 이용에 지장이 있는 경우
                            <br/>
                            ③ ‘서비스’ 계약종료 등과 같은 ‘인터넷사업자’의 제반 사정으로
                            ‘서비스’를 유지할 수 업는 경우
                            <br/>
                            ④ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘서비스’의 변경, 중지로 발생하는 문제에 대해서는
                            어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 10 조 (서비스의 안전성 확보)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’의 안전성과 신뢰성을 확보하기 위하여
                            업무처리지침의 제정 및 시행, 정보처리시스템 및 전산자료의 관리
                            등의 관리적 조치와 모니터링 체계 및 해킹방지시스템 구축 및 운영
                            등의 기술적 조치를 취하고 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 서버 및 통신기기의 정상작동여부 확인을 위하여
                            정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한 모니터링
                            체계를 갖추고 있습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및
                            프로그램을 설치하여 운영합니다.
                            <br/>
                            <br/>
                            ① 침입차단시스템 설치
                            <br/>
                            ② 침입탐지시스템 설치
                            <br/>
                            ③ 그 밖에 필요한 보호장비 또는 암호프로그램 등 정보보호시스템
                            설치
                            <br/>
                            <br/>
                            (4) ‘회사’는 컴퓨터바이러스 감염을 방지하기 위하여 다음 각 호를
                            포함한 대책을 수립, 운영하고 있습니다.
                            <br/>
                            <br/>
                            ① 출처, 유통경로 및 제작자가 명확하지 아니한 응용프로그램은
                            사용을 자제하고 불가피할 경우에는 컴퓨터바이러스
                            검색프로그램으로 진단 및 치료 후 사용할 것
                            <br/>② 컴퓨터바이러스 검색, 치료 프로그램을 설치하고 최신
                            버전을 유지할 것
                            <br/>③ 컴퓨터바이러스 감염에 대비하여 방어, 탐색 및 복구 절차를
                            마련할 것
                            <br/>
                            <br/>
                            제 11 조 (이용자의 개인정보보호)
                            <br/>
                            <br/>
                            ‘회사’는 관련법령이 정하는 바에 따라서 ‘이용자’의 개인정보를
                            보호하기 위하여 노력합니다. ‘이용자’의 개인정보보호에 관한
                            사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에 정한
                            바에 따릅니다.
                            <br/>
                            <br/>
                            제 12 조 (개인정보의 위탁)
                            <br/>
                            <br/>
                            ‘회사’는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로
                            수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를
                            ‘회사’가 선정한 회사에 위탁할 수 있습니다. 개인정보의 위탁에
                            관한 사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에
                            정한 바에 따릅니다.
                            <br/>
                            <br/>
                            제 13 조 (손해배상)
                            <br/>
                            <br/>
                            ‘회사’는 ‘서비스’의 이용과 관련하여 ‘이용자’에게 발생한
                            ‘회사’측의 중과실에 의한 손해를 제외한 어떠한 손해에 관하여도
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 14 조 (회사와 인터넷사업자와의 관계)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘인터넷사업자’가 제공하는 서비스 상품 또는 용역
                            등에 대하여 보증책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’와 ‘인터넷사업자’는 독자적으로 사이트를 운영하며,
                            ‘인터넷사업자’와 ‘이용자’간에 행해진 거래에 대하여 고의 또는
                            중과실이 있는 경우를 제외하고는 어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 15 조 (면책)
                            <br/>
                            <br/>
                            (1) ‘회사’의 고의 또는 과실 없이 ‘서비스’를 제공할 수 없는
                            경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’ 또는 제3자 측의 사정으로 인한 ‘서비스’
                            이용의 장애에 대하여 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 고의 또는 중과실이 없는 한 ‘이용자’가 ‘서비스’를
                            이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며
                            그밖에 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을
                            지지 않습니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 ‘이용자’가 ‘서비스’에 게재한 정보, 자료, 사실의
                            신뢰도, 정확성 등 내용에 관하여는 고의 또는 중과실이 없는 한
                            일체의 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (5) 계약만료 또는 요금 미납의 결과로 사용자계정이 정지되거나,
                            보관 파일이 삭제된 후 이에 따르는 손해에 대하여 책임을 지지
                            않습니다.
                            <br/>
                            <br/>
                            제 16 조 (관할 법원)
                            <br/>
                            <br/>
                            (1) ‘회사’와 ‘이용자’는 ‘서비스’와 관련하여 분쟁이 발생한 경우,
                            원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
                            <br/>
                            <br/>
                            (2) 제1항의 협의에서도 분쟁이 해결되지 않은 경우 등, 약관과
                            관련하여 분쟁으로 인한 소송이 제기될 경우 동 소송은
                            서울중앙지방법원을 관할 법원으로 합니다.
                            <br/>
                            <br/>
                            [부칙]
                            <br/>
                            <br/>
                            (시행일) 이 약관은 2013년 2월 15일부터 그 효력이 발생합니다.
                            (2013/02/15 신설)
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    const showServiceTermsLguMvno = () => {
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>KT서비스 이용약관</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="yak_body">
                        <div className="yak_scroll">
                            SCI 본인확인서비스 이용 약관
                            <br/>
                            <br/>
                            제 1 조 (목적)
                            <br/>
                            <br/>
                            본 약관은 SCI평가정보㈜(이하 ‘회사’라 한다)가 제공하는
                            본인확인서비스(이하 ‘서비스’라 한다)에 관한 이용조건 및 절차 등
                            기본적인 사항을 규정함을 목적으로 합니다.
                            <br/>
                            <br/>
                            제 2 조 (약관의 효력 및 변경)
                            <br/>
                            <br/>
                            (1) 본 약관은 ‘이용자’에게 ‘서비스’ 화면에 게시하거나, 기타의
                            방법으로 공지하는 것으로 효력이 발생합니다.
                            <br/>
                            <br/>
                            (2) 회사’는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지
                            않는 범위에서 본 약관의 내용을 개정할 수 있으며, 변경된 경우에는
                            제1항과 같은 방법으로 공지합니다. 다만 ‘이용자’의 권리와 의무에
                            관한 중요한 사항은 변경된 내용의 시행 15일 이전에 공지합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 변경된 약관에 대한 내용을 알지 못하여 발생하는
                            손해 및 피해에 대해서는 ‘회사’가 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            제 3 조 (약관 외 준칙)
                            <br/>
                            <br/>
                            본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용 촉진
                            및 정보보호 등에 관한 법률 등 기타 관련 법령 또는 상관례에
                            따릅니다.
                            <br/>
                            <br/>
                            제 4 조 (용어의 정의)
                            <br/>
                            <br/>
                            본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                            <br/>
                            <br/>
                            (1) ‘본인확인서비스’라 함은 ‘이용자’가 인터넷상에서 휴대폰 등의
                            인증수단을 이용하여, 본인임을 안전하게 식별 및 확인해 주는
                            서비스를 말합니다.
                            <br/>
                            <br/>
                            (2) ‘이용자’라 함은 ‘회사’에서 제공하는 ‘서비스’의 이용을 위해
                            자신의 ‘본인확인정보’를 ‘회사’ 및 ‘본인확인기관’ 등에게
                            제공하고, 본인임을 확인 받고자 하는 자를 말합니다.
                            <br/>
                            <br/>
                            (3) ‘본인확인정보’라 함은 ‘이용자’가 입력한 생년월일, 성별,
                            성명, 내/외국인, 휴대폰번호, 통신사 등 본인 식별에 필요한
                            ‘이용자’의 정보를 말합니다.
                            <br/>
                            <br/>
                            (4) ‘본인확인기관’이라 함은 주민등록번호를 사용하지 아니하고
                            본인을 확인하는 방법 또는 서비스를 제공하는 자로
                            방송통신위원회로부터 본인확인기관으로 지정을 받은 자를
                            의미합니다.
                            <br/>
                            <br/>
                            (5) ‘인터넷사업자’라 함은 인터넷을 이용하여 정보를 제공하거나,
                            정보의 제공을 매개하는 일을 업으로 하는 자로 ‘회사’와 계약을
                            통해 운영하며, 인터넷 웹사이트의 ‘이용자’에 대한
                            ‘본인확인정보’를 제공 받는 사업체를 말합니다.
                            <br/>
                            <br/>
                            (6) ‘중복가입확인정보’라 함은 웹사이트에 가입하고자 하는
                            이용자의 중복 확인을 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            (7) ‘연계정보’ 라 함은 ‘인터넷사업자’의 온ㆍ오프라인 서비스 연계
                            등의 목적으로 ‘이용자’를 식별하기 위해 제공되는 정보를 말합니다.
                            <br/>
                            <br/>
                            제 5 조 (회사의 의무)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’ 제공과 관련하여 인지한 ‘이용자’의
                            ‘본인확인정보’를 본인의 승낙 없이 제3자에게 누설하거나 배포하지
                            않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의
                            목적이 있는 경우 등 기타 관계법령에서 정한 절차에 따른 요청이
                            있는 경우에는 그러하지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’에게 안전한 ‘서비스’ 제공을 위하여
                            지속적으로 ‘서비스’의 예방점검, 유지보수 등을 이행하며,
                            ‘서비스’의 장애가 발생하는 경우, 이를 지체 없이 수리 및
                            복구합니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 ‘이용자’가 제기한 의견, 불만이 타당하다고 인정되는
                            경우 적절한 절차를 거쳐 이를 지체 없이 처리하거나 처리에 관한
                            일정을 통지하여야 합니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 접근매체의 발급 주체가 아닌 경우에는 접근 매체의
                            위조나 변조로 발생한 사고로 인하여,‘이용자’에게 발생한 손해에
                            대하여 배상책임이 없습니다.
                            <br/>
                            <br/>
                            제 6 조 (이용자의 의무)
                            <br/>
                            <br/>
                            (1) ‘이용자’는 ‘서비스’를 이용함에 있어서 다음 각호에 해당하는
                            행위를 하여서는 안되며, ‘회사’는 위반행위에 따르는 일체의 법적
                            책임을 지지 않습니다.
                            <br/>
                            <br/>
                            ① 타 ‘이용자’의 ‘본인확인정보’를 부정하게 사용 및 도용하는 행위
                            <br/>
                            ② ‘회사’의 저작권, 제3자의 저작원 등 기타 권리를 침해하는 행위
                            <br/>
                            ③ 범죄 행위
                            <br/>
                            ④ 기타 관련 법령에 위배되는 행위
                            <br/>
                            <br/>
                            (2) ‘이용자’는 자신의 접근매체를 제3자에게 누설 또는 노출하거나
                            방치하여서는 안되며, 접근 매체의 도용이나 위조 또는 변조를
                            방지하기 위하여 충분한 주의를 기울여야 합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 본 약관에서 규정하는 사항과 ‘서비스’에 대한
                            이용안내 또는 주의사항을 준수하여야 합니다.
                            <br/>
                            <br/>
                            제 7 조 (서비스의 내용)
                            <br/>
                            <br/>
                            (1) ‘서비스’는 ‘이용자’가 주민등록번호의 입력 없이, 본인명의로
                            된 인증수단(휴대폰 등)을 이용하여 본인 확인이 가능한 인증 서비스
                            입니다.
                            <br/>
                            <br/>
                            (2) ‘인터넷사업자’는 회원가입, ID/PW찾기, 성인인증, 기타
                            본인확인이 필요한 콘텐츠 요청 시 ‘이용자’에게 ‘서비스’를 제공
                            합니다.
                            <br/>
                            <br/>
                            (3) ‘이용자’는 자신의 생년월일, 성명, 성별, 내/외국인,
                            휴대폰번호, 통신사 등의 정보를 입력 후, 입력한정보가 일치한 경우
                            해당 휴대폰번호로 1회성 비밀번호(승인번호)가 발송되며, 수신된
                            승인번호를 정확하게 입력하는 것으로 본인 확인이 이루어 집니다.
                            <br/>
                            <br/>
                            제 8 조 (서비스 제공시간)
                            <br/>
                            <br/>
                            (1) ‘서비스’의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.
                            다만, 정기 점검 및 기타 기술상의 이유로 ‘서비스’가 일시 중지될
                            수 있고 또한, 운영상의 목적으로 ‘회사’가 정한 기간에도 일시
                            중지될 수 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 효율적인 업무 수행을 위하여 필요하다고 판단하는
                            경우 ‘서비스’를 일정 범위로 분할 하여 각 범위 별로 이용가능
                            시간을 달리 정할 수 있으며, 이 경우 그 내용을 공지 합니다.
                            <br/>
                            <br/>
                            제 9 조 (서비스 제공의 중지)
                            <br/>
                            <br/>
                            (1) ‘회사’는 다음 각 호에 해당하는 사유가 있는 경우 ‘서비스’
                            제공의 전부 또는 일부를 중지할 수 있습니다.
                            <br/>
                            <br/>
                            ① ‘서비스’를 위한 설비의 보수 등 공사로 인한 부득이한 경우
                            <br/>
                            ② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
                            ‘서비스’ 이용에 지장이 있는 경우
                            <br/>
                            ③ ‘서비스’ 계약종료 등과 같은 ‘인터넷사업자’의 제반 사정으로
                            ‘서비스’를 유지할 수 업는 경우
                            <br/>
                            ④ 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘서비스’의 변경, 중지로 발생하는 문제에 대해서는
                            어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 10 조 (서비스의 안전성 확보)
                            <br/>
                            <br/>
                            (1) ‘회사’는 ‘서비스’의 안전성과 신뢰성을 확보하기 위하여
                            업무처리지침의 제정 및 시행, 정보처리시스템 및 전산자료의 관리
                            등의 관리적 조치와 모니터링 체계 및 해킹방지시스템 구축 및 운영
                            등의 기술적 조치를 취하고 있습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 서버 및 통신기기의 정상작동여부 확인을 위하여
                            정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한 모니터링
                            체계를 갖추고 있습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및
                            프로그램을 설치하여 운영합니다.
                            <br/>
                            <br/>
                            ① 침입차단시스템 설치
                            <br/>
                            ② 침입탐지시스템 설치
                            <br/>
                            ③ 그 밖에 필요한 보호장비 또는 암호프로그램 등 정보보호시스템
                            설치
                            <br/>
                            <br/>
                            (4) ‘회사’는 컴퓨터바이러스 감염을 방지하기 위하여 다음 각 호를
                            포함한 대책을 수립, 운영하고 있습니다.
                            <br/>
                            <br/>
                            ① 출처, 유통경로 및 제작자가 명확하지 아니한 응용프로그램은
                            사용을 자제하고 불가피할 경우에는 컴퓨터바이러스
                            검색프로그램으로 진단 및 치료 후 사용할 것
                            <br/>② 컴퓨터바이러스 검색, 치료 프로그램을 설치하고 최신
                            버전을 유지할 것
                            <br/>③ 컴퓨터바이러스 감염에 대비하여 방어, 탐색 및 복구 절차를
                            마련할 것
                            <br/>
                            <br/>
                            제 11 조 (이용자의 개인정보보호)
                            <br/>
                            <br/>
                            ‘회사’는 관련법령이 정하는 바에 따라서 ‘이용자’의 개인정보를
                            보호하기 위하여 노력합니다. ‘이용자’의 개인정보보호에 관한
                            사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에 정한
                            바에 따릅니다.
                            <br/>
                            <br/>
                            제 12 조 (개인정보의 위탁)
                            <br/>
                            <br/>
                            ‘회사’는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로
                            수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를
                            ‘회사’가 선정한 회사에 위탁할 수 있습니다. 개인정보의 위탁에
                            관한 사항은 관련법령 및 ‘회사’가 정하는 ‘개인정보취급방침’에
                            정한 바에 따릅니다.
                            <br/>
                            <br/>
                            제 13 조 (손해배상)
                            <br/>
                            <br/>
                            ‘회사’는 ‘서비스’의 이용과 관련하여 ‘이용자’에게 발생한
                            ‘회사’측의 중과실에 의한 손해를 제외한 어떠한 손해에 관하여도
                            책임을 지지 않습니다.
                            <br/>
                            <br/>제 14 조 (회사와 인터넷사업자와의 관계) (1) ‘회사’는
                            ‘인터넷사업자’가 제공하는 서비스 상품 또는 용역 등에 대하여
                            보증책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (2) ‘회사’와 ‘인터넷사업자’는 독자적으로 사이트를 운영하며,
                            ‘인터넷사업자’와 ‘이용자’간에 행해진 거래에 대하여 고의 또는
                            중과실이 있는 경우를 제외하고는 어떠한 책임도 지지 않습니다.
                            <br/>
                            <br/>
                            제 15 조 (면책)
                            <br/>
                            <br/>
                            (1) ‘회사’의 고의 또는 과실 없이 ‘서비스’를 제공할 수 없는
                            경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.
                            <br/>
                            <br/>
                            (2) ‘회사’는 ‘이용자’ 또는 제3자 측의 사정으로 인한 ‘서비스’
                            이용의 장애에 대하여 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (3) ‘회사’는 고의 또는 중과실이 없는 한 ‘이용자’가 ‘서비스’를
                            이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며
                            그밖에 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을
                            지지 않습니다.
                            <br/>
                            <br/>
                            (4) ‘회사’는 ‘이용자’가 ‘서비스’에 게재한 정보, 자료, 사실의
                            신뢰도, 정확성 등 내용에 관하여는 고의 또는 중과실이 없는 한
                            일체의 책임을 지지 않습니다.
                            <br/>
                            <br/>
                            (5) 계약만료 또는 요금 미납의 결과로 사용자계정이 정지되거나,
                            보관 파일이 삭제된 후 이에 따르는 손해에 대하여 책임을 지지
                            않습니다.
                            <br/>
                            <br/>
                            제 16 조 (관할 법원)
                            <br/>
                            <br/>
                            (1) ‘회사’와 ‘이용자’는 ‘서비스’와 관련하여 분쟁이 발생한 경우,
                            원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
                            <br/>
                            <br/>
                            (2) 제1항의 협의에서도 분쟁이 해결되지 않은 경우 등, 약관과
                            관련하여 분쟁으로 인한 소송이 제기될 경우 동 소송은
                            서울중앙지방법원을 관할 법원으로 합니다.
                            <br/>
                            <br/>
                            [부칙]
                            <br/>
                            <br/>
                            (시행일) 이 약관은 2013년 2월 15일부터 그 효력이 발생합니다.
                            (2013/02/15 신설)
                        </div>
                    </div>
                </Modal.Body>
            </>
        );
    }

    return (
        <>
            {showServiceTerms()}
        </>
    );
}

export default ServiceTerms;