import { Link, useParams } from "react-router-dom";
import React, { useContext } from "react";
import { Action, Fab } from "./UI_09_02_Patch";
import { RootContext } from "../../common";

const UI_09_02_CAMFS = function () {

    const { encodedAgentNumber } = useParams();

    const { agent } = useContext(RootContext);

    return (
        <div className="tinyFab">
            <Fab alwaysShowTitle bsPrefix="string">
                <Action text="Agent에게 전화하기">
                    <a
                        href={`tel:${agent.basicInfo.mobilePhoneNumber}`}
                        title="Agent에게 전화하기"
                        className="icon icon01"
                    >
                        Agent에게 전화하기
                    </a>
                </Action>
                <Action text="콜센터 전화하기">
                    <a
                        href="tel:02-1588-9600"
                        title="콜센터 전화하기"
                        className="icon icon02"
                    >
                        콜센터 전화하기
                    </a>
                </Action>
                <Action text="상담신청">
                    <Link to={`/${encodedAgentNumber}/counsel`} className="icon icon03" />
                </Action>
                <Action text="MetLife One">
                    <a
                        href="https://cyber.metlife.co.kr/app_download.html"
                        title="MetLife One 새창열림"
                        target="_blank"
                        className="icon icon04"
                        rel="noreferrer"
                    >
                        MetLife One
                    </a>
                </Action>
                <Action text="메트라이프생명">
                    <a
                        href="https://www.metlife.co.kr"
                        title="메트라이프생명 새창열림"
                        target="_blank"
                        className="icon icon05"
                        rel="noreferrer"
                    >
                        메트라이프생명
                    </a>
                </Action>
                <Action text="고객솔루션 안내장">
                    <a
                      href="https://insu.greenpio.com/customersolution"
                      title="고객솔루션 안내장 새창열림"
                      target="_blank"
                      className="icon icon09"
                      rel="noreferrer"
                    >
                        고객솔루션 안내장
                    </a>
                </Action>
                {/*<Action text="360Health">*/}
                {/*    <a*/}
                {/*        href="https://www.metlife.co.kr/benefit/360health/"*/}
                {/*        title="360Health 새창열림"*/}
                {/*        target="_blank"*/}
                {/*        className="icon icon06"*/}
                {/*        rel="noreferrer"*/}
                {/*    >*/}
                {/*        360Health*/}
                {/*    </a>*/}
                {/*</Action>*/}
            </Fab>
        </div>
    );
};

export default UI_09_02_CAMFS;
