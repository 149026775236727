import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Link, Outlet, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {FetchApi, formatDate, getContentImgSrc} from "../../common";


const UI_07_01 = function ({trackingMenu}) {

    /** 최초 마운트 */
    useEffect(() => {
        trackingMenu('A0000061');
            retrieveContentCategories()
            .then((result) => {
                let storyId = ''
                if(category === "story") {
                    storyId = result.find(e => e.categoryName === "메트라이프 연금STORY").categoryId
                    setSearchOption( {...searchOption, categoryId : result.find(e => e.categoryName === "메트라이프 연금STORY").categoryId})
                }
                if(category === "metlife") {
                    storyId = result.find(e => e.categoryName === "MetLife STORY").categoryId
                    setSearchOption( {...searchOption, categoryId : result.find(e => e.categoryName === "MetLife STORY").categoryId})
                }
                if(category === "newyork") {
                    storyId = result.find(e => e.categoryName === "1868 NEW YORK").categoryId
                    setSearchOption( {...searchOption ,categoryId : result.find(e => e.categoryName === "1868 NEW YORK").categoryId})
                }
                if(category === "foundation") {
                    storyId = result.find(e => e.categoryName === "사회공헌재단 STORY").categoryId
                    setSearchOption( {...searchOption, categoryId : result.find(e => e.categoryName === "사회공헌재단 STORY").categoryId})
                }
                return storyId
            }).then(categoryId => {
                retrieveContent(true, categoryId)
            })
            .catch((e) => console.log(e));
    }, []);

    /** 카테고리 파라미터 */
    const loc = useLocation();
    const params = new URLSearchParams(loc.search);
    const category = params.get('category');

    /** 조회 결과 */
    const [contents, setContents] = useState([]);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    /** 메타데이터 */
    let metadata = {
        total: 0,
        offset: 0,
        limit: 10,
    }
    /** 조회 조건 */
    const [searchOption, setSearchOption] = useState({
        offset: metadata.offset,
        limit: metadata.limit,
        titleText: "",
        categoryId: "",
        sort: "contentStartDate:desc,createTimestamp:desc",
        dateValidYn: 'Y'
    });

    const [contentCategories, setContentCategories] = useState([]);

    const handleSetOffset = (isNew) => {
        metadata = Object.assign(metadata, {offset: isNew ? 0 : offset});
    };
    const handleSetContents = (isNew, newContentsResult) => {
        setContents(isNew ? newContentsResult.item : [...contents, ...newContentsResult.item]);
        metadata = newContentsResult.metadata;
        setTotal(newContentsResult.metadata.total);
        setOffset(metadata.offset+1);
    };

    const handleSetSearchOption = (source) => {
        setSearchOption(Object.assign({}, searchOption, source));
    };

    /** 더보기 제어 */
        const [isAppendHidden, setIsAppendHidden] = useState(true);
        const handleSetIsAppendDisabled = () => {
            if (metadata.total === null || metadata.total > ((metadata.offset+1) * metadata.limit)) {
                setIsAppendHidden(false);
            } else {
                setIsAppendHidden(true);
            }
        };

    const retrieveContent = async (isNew, id, text) => {
        /** 메타 정보 설정 */
        handleSetOffset(isNew)

        /** API 호출 */
        const result = await FetchApi.get('/api/meton/channel/content', {...searchOption, ...metadata, categoryId: id !== undefined? id: searchOption.categoryId, titleText: text !== undefined? text: searchOption.titleText }, {});

        /** 신규 조회 */
        await handleSetContents(isNew, result)

        /** 더보기 버튼 제어 */
        await handleSetIsAppendDisabled()
    }

    const retrieveContentCategories = async () => {
        const result = await FetchApi.get('/api/meton/channel/category?sort=sortSequenceNumber:asc');
        setContentCategories(result.item);

        return result.item
    }

    /** 렌더 */
    return (
        <>
            <Form className="writeform search mb-4">

                <Form.Group className="mb-1" controlId="formGroupCity1">
                    <Form.Select value={searchOption.categoryId}
                                 onChange={(e) => {
                                     handleSetSearchOption({categoryId: e.target.value, titleText: ''});
                                     retrieveContent(true, e.target.value, '');
                                 }}
                    >
                        <option value="">전체</option>
                        {contentCategories.map((category, index) => <option key={`category-option-${index}`} value={category.categoryId}>{category.categoryName}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-1 zoom" controlId="formGroupName">
                    <Form.Control type="text" placeholder="검색어를 입력하세요."
                                  value={searchOption.titleText}
                                  onChange={(e) => {
                                      handleSetSearchOption({titleText: e.target.value});
                                  }}
                                  onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                          e.preventDefault()
                                          handleSetSearchOption({categoryId: ''})
                                          retrieveContent(true, '');
                                      }
                                  }}
                    />
                    <a onClick={() => {
                        handleSetSearchOption({categoryId: ''})
                        retrieveContent(true, '');
                    }}>검색</a>
                </Form.Group>
            </Form>
            <p className="total">
                전체 <strong>{total}</strong>건
            </p>
            <Row xs={2} md={2} className="g-4 news news_all">
                {contents.length > 0? contents.map((content, idx) => (
                            content.viewTypeText === 'CARD' ?
                                <Link to={`card/${content.contentId}`} key={`content-${idx}`}>
                                    <Col key={`content-${idx}`}>
                                        <Card>
                                            <Card.Img
                                                variant="top"
                                                src={getContentImgSrc(content.thumbnailStoredImageFileName)}
                                                className="content-card-image"
                                            />
                                            <Card.Body>
                                                <Card.Text>{formatDate(content.contentStartDate, 'yyyy.MM.DD')}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <strong>{content.titleText}</strong>
                                        <span>조회 : {content.readCount}</span>
                                    </Col>
                                </Link>
                                :
                                <Link to={`newsletter/${content.contentId}`} key={`content-${idx}`}>
                                    <Col>
                                        <Card>
                                            <Card.Img
                                                variant="top"
                                                src={getContentImgSrc(content.thumbnailStoredImageFileName)}
                                                className="content-card-image"
                                            />
                                            <Card.Body>
                                                <Card.Text>{formatDate(content.contentStartDate, 'yyyy.MM.DD')}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <strong>{content.titleText}</strong>
                                        <span>조회 : {content.readCount}</span>
                                    </Col>
                                </Link>
                    )
                ): <div className="noResult">등록된 내용이 없습니다.</div>}
            </Row>

            <Button className="button" bsPrefix="string" onClick={() => retrieveContent(false)} hidden={isAppendHidden}>
                + 더보기
            </Button>
            <Outlet/>
        </>
    );
};

export default UI_07_01;