import React from "react";
import {Share} from "../../common";

const UI_10_01_Start = ({onClickStart}) => {

    return (
        <div className="job">
            <Share />
            <div className="start jobbox">
                <button onClick={onClickStart}>
                    <img
                        src={require("../../assets/images/job/start.png")}
                        alt="나는 어떤 사업가 스타일일까?"
                    />
                </button>
            </div>
        </div>
    )
}

export default UI_10_01_Start;