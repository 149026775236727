import img from '../../assets/images/globalsalesprofessional.jpg'
import {Button} from "react-bootstrap";
import {useEffect} from "react";

const UI_07_05 = function ({trackingMenu}) {

    useEffect(() => {
        trackingMenu('A0000064');
    }, [])

    return (
        <>
            <div className="contents content-global-sales-pro">
                {/* 전용 className:content-health360*/}
                <p className="copy-area copy-infomation">
                    <img
                        src={img}
                        alt="Global Sales Professional"
                    />
                </p>
                <Button
                    className="button"
                    bsPrefix="string"
                    onClick={() => {

                        const agent = navigator.userAgent.toLocaleLowerCase();
                        if (
                            (agent.indexOf('iphone') > -1 ||
                                agent.indexOf('ipad') > -1 ||
                                agent.indexOf('ipod') > -1) &&
                            agent.indexOf('kakao') > -1
                        ) {
                            alert('파일 다운로드는 사파리 브라우저에서 진행해 주세요.');
                        } else {
                            window.open(process.env.REACT_APP_GLOBAL_SALES_PROFESSIONAL_PDF_URL);
                        }
                    }}
                >
                    Global Sales Professional 다운로드
                </Button>
            </div>
        </>
    );
};

export default UI_07_05;