import React, {useState} from "react";
import "./styles/common.css";
import "../node_modules/animate.css/animate.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-tiny-fab/dist/styles.css";
import {ErrorBoundary, RootContext} from "./common";
import RootLayout from "./RootLayout";


function App() {
    const [encodedAgentNumberForRoot, setEncodedAgentNumberForRoot] = useState('');
    const [agent, setAgent] = useState({
            "basicInfo": {
                "employeeName": "",
                "agentNumber": "",
                "mobilePhoneNumber": "",
                "headquarterOrganizationName": "",
                "branchOfficeOrganizationName": "",
                "businessDutyCode": "",
                "businessDutyName": "",
                "businessChannelDivisionCode": "",
            },
            "profile": {
                "id": "",
                "createUserID": null,
                "createTimestamp": null,
                "updateUserID": null,
                "updateTimestamp": null,
                "deletedIndicator": false,
                "agentProfileId": null,
                "agentNumber": null,
                "mainImageFileName": null,
                "mainStoredImageFileName": 'dummyValue',
                "profileImageFileName": null,
                "profileStoredImageFileName": 'dummyValue',
                "emailText": null,
                "kakaotalkId": null,
                "introduceText": null,
                "agentCertificateList": [],
                "agentAwardList": []
            },
            "agentUrl": [
                {
                    "agentNumber": "",
                    "host": "",
                    "url": "",
                    "typeCode": "",
                    "uriName": ""
                },
            ],
            "crossAgentInfo" : {
                "financialConsultantChannelCode": "",
                "businessPositionTypeCode": "",
            },
        });
    const [menuMap, setMenuMap] = useState(new Map());
    const [selectedNewsMainImage, setSelectedNewsMainImage] = useState('');
    const [selectedNewsTitle, setSelectedNewsTitle] = useState('');
    const [agentMultiProfiles, setAgentMultiProfiles] = useState([]); //시즈널 멀티 프로필 추가

    const isGAManager = (businessDutyCode) => {
        return ['1007', '3005', '3006', '5014', '5015', '6004', '6005'].includes(businessDutyCode);
    }

    const getValue = () => ({
        encodedAgentNumberForRoot,
        setEncodedAgentNumberForRoot,
        menuMap,
        setMenuMap,
        agent,
        setAgent,
        selectedNewsMainImage,
        setSelectedNewsMainImage,
        selectedNewsTitle,
        setSelectedNewsTitle,
        isGAManager,
        agentMultiProfiles,
        setAgentMultiProfiles
    })

    return (
        <div className="App">
            <ErrorBoundary>
                <RootContext.Provider value={getValue()}>
                    {/*<PublishedLayout/>*/}
                    <RootLayout/>
                </RootContext.Provider>
            </ErrorBoundary>
        </div>
    );
}

export default App;
