import {useEffect, useState} from "react";
import Truncate from "./Truncate";

const ShowMoreText = ({
                          lines = 3,
                          more = '더보기',
                          less = undefined,
                          anchorClass = 'show-more-less-clickable',
                          onClick = undefined,
                          expanded: expandedProps = false,
                          width,
                          keepNewLines = false,
                          onTruncate = undefined,
                          children,
                          className,
                      }) => {
    const [expanded, setExpanded] = useState(false);
    const [truncated, setTruncated] = useState(false);

    useEffect(() => {
        setExpanded(expandedProps)
    }, []);

    const handleTruncate = (trunc) => {
        if (trunc !== truncated) {
            setTruncated(trunc);
        }
        if (truncated) {
            // onResize();
        }
        onTruncate && onTruncate();
    }

    const toggleLines = (event) => {
        event.preventDefault();

        setExpanded(!expanded);
        if (onClick) {
            onClick(!expanded, event);
        }
    }

    return (
        <div className={className}>
            <Truncate
                width={width}
                lines={!expanded && lines}
                ellipsis={
                    <button
                        className={anchorClass}
                        onClick={toggleLines}
                    >
                        {more}
                    </button>
                }
                onTruncate={handleTruncate}
            >
                {keepNewLines ?
                    children.split('\n').map((line, i, arr) => {
                        line = <span key={i}>{line}</span>;
                        if (i === arr.length - 1) {
                            return line;
                        } else {
                            return [line, <br key={i + 'br'}/>]
                        }

                    }) : children
                }
            </Truncate>
            {!truncated && expanded && less && (
                <span>
                    {' '}
                    <span
                        className={anchorClass}
                        onClick={toggleLines}
                    >
                        {less}
                    </span>
                </span>
            )}
        </div>
    );
}

export default ShowMoreText;