import React from "react";
import {UI_09_03_500} from "../../ui_09";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(_error) {
        return {hasError: true}
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <UI_09_03_500/>
        }

        return this.props.children;

    }
}

export default ErrorBoundary;