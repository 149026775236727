import {useNavigate, useParams} from "react-router-dom";
import {useContext, useLayoutEffect} from "react";
import RootContext from "./RootContext";
import {FetchApi} from "../utils";

const AgentChecker = (props) => {
  const {setIsCertificated} = props;
  const {encodedAgentNumber} = useParams();
  const {setAgent, setEncodedAgentNumberForRoot, setAgentMultiProfiles} = useContext(RootContext);
  const navigate = useNavigate();
  /**
   * metON 링크 방문자 수
   * @returns {Promise<void>}
   */
  const countVisitor = async () => {
    await FetchApi.post('/api/meton/channel/meton-click-archive', {urlText: window.location.href});
  }

  /**
   * agent 식별자 검증 및 프로필 조회
   * @returns {Promise<*>}
   */
  const validateAgentNumber = async () => {
    return FetchApi.get('/api/meton/channel/profile', {urlText: encodedAgentNumber})
  }

  /**
   * 시즈널 멀티 프로필 이미지 조회
   * @returns {Promise<void>}
   */
  const onSearchSeasonalMultiProfiles = async (agentProfile) => {
    // if (!agentProfile?.agentProfileId) return;

    await FetchApi.get('/api/meton/channel/agent-multi-profile', {
      agentProfileId: agentProfile?.agentProfileId,
      dateValidYn: 'Y'
    }).then((rst) => {
      if (rst.item) {
        if (rst.item.some(x => Object.is(agentProfile.agentProfileId, x.multiProfileContentId))) {
          let idx = rst.item.findIndex(item => Object.is(agentProfile.agentProfileId, item.multiProfileContentId))

          if (agentProfile.mainImageFileName) {
            //메인 이미지가 있는 경우
            rst.item[idx].multiProfileStoredImageFileName = agentProfile.mainStoredImageFileName
            rst.item[idx].multiProfileImageFileName = agentProfile.mainImageFileName
          } else {
            //메인 이미지가 없는 경우 삭제
            rst.item = rst.item.filter((_, i) => i !== idx);
          }
        }
        setAgentMultiProfiles(rst.item);
      }
    });
  }

  const isAgent = () => {
    if (!encodedAgentNumber || encodedAgentNumber === 'undefined') {
      navigate("/");
    }

    setEncodedAgentNumberForRoot(encodedAgentNumber);
    validateAgentNumber().then((data) => {
      if (true === data.isSuccess) {
        //결과
        setAgent(data?.item);
        //멀티 프로필 조회
        onSearchSeasonalMultiProfiles(data?.item?.profile);
        //방문자 카운트
        countVisitor();
        setIsCertificated(true);
      } else {
        navigate("/");
      }
    }).catch(() => {
      navigate("/");
    })

    return true;
  };


  useLayoutEffect(() => {
    isAgent();
  }, []);
}

export default AgentChecker;
