import {Button, Form} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import moment from 'moment';
import {AgencyTerms, FetchApi, IdentifierTerms, MetOnModal, PrivacyTerms, Progress, ServiceTerms} from "../../common";
import {useNavigate, useParams} from "react-router-dom";

const ONE_SEC = 1000;
const ONE_MIN = 60000;

const UI_03_01 = function ({trackingMenu}) {

    const navigate = useNavigate();
    const {encodedAgentNumber} = useParams();
    const [ progressOpen, setProgressOpen ] = useState(false);

    const [termsCondition, setTermsCondition] = useState({telecom: 'SKT', type: 'Service'});
    const [termsModalShow, setTermsModalShow] = useState(false);
    const [infoDisabled, setInfoDisabled] = useState(false);
    const [certDisabled, setCertDisabled] = useState(false);
    const [checkRegistNumberDisabled, setCheckRegistNumberDisabled] = useState(false);
    const [registNumberSkip, setRegistNumberSkip] = useState(false);
    const [time, setTime] = useState(3 * ONE_MIN);
    const [timerOn, setTimerOn] = useState(false);
    const timeText = useMemo(() => moment(time).format('m:ss'), [time]);
    const [sidoNmAddress, setSidoNmAddress] = useState([]);
    const [GgNmAddress, setGgNmAddress] = useState([]);
    const [startTime, setStartTime] = useState(moment());
    const [name, setName] = useState('');
    const [residentRegistrationNumberFront, setResidentRegistrationNumberFront] = useState('')
    const [encodeResidentRegistrationNumberBack, setEncodeResidentRegistrationNumberBack] = useState('')//encode 주민등록번호 뒷자리
    const [selfAuthHistoryIdList, setSelfAuthHistoryIdList] = useState([]);

    const [consultationInfo, setConsultationInfo] = useState({
        name: '',
        type: 'domestic',
        birth: '',
        gender: '',
        telecom: '',
        telecomAccept: {
            all: false,
            agency: false,
            identifier: false,
            privacy: false,
            service: false,
        },
        mobile: '',
        certNo: '',
        certificated: false,
        region: {
            city1: '',
            city2: '',
        },
        metlifePrivacy: undefined,
    });

    const [illustrationAgreementInfo, setIllustrationAgreementInfo] = useState({
        gather:{
            all: false,
            identifier: undefined,
            sensitive: undefined,
            credit: undefined,
        },
        offer:{
            all: false,
            identifier: undefined,
            sensitive: undefined,
            credit: undefined,
        },
        inquiry:{
            all: false,
            identifier: undefined,
            sensitive: undefined,
            credit: undefined,
        },
    });

    useEffect(() => {
        trackingMenu('A0000020');
        findSidoNmAddress();
    }, [])

    useEffect(() => {
        window.addEventListener('click', initializeIdleTime);

        return (() => {
            window.removeEventListener('click', initializeIdleTime);
        })
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            if (moment.duration(moment().diff(startTime)).asMinutes() > 20) {
                alert('20분 동안 움직임이 없어 메인화면으로 이동합니다.')
                navigate(`/${encodedAgentNumber}`)
            }
        }, ONE_SEC);

        return () => {
            clearInterval(interval)
        }

    }, [startTime])

    useEffect(() => {
        let interval
        if (timerOn) {
            interval = setInterval(() => {
                if (time > 0) {
                    setTime(time - ONE_SEC);
                }
            }, ONE_SEC)
        }
        return () => {
            clearInterval(interval);
        }
    }, [timerOn, time]);

    const initializeIdleTime = () => {
        setStartTime(moment());
    }

    const requestConsult = async () => {
        await FetchApi.post('/api/meton/channel/consult', {
            encodedAgentNumber: encodedAgentNumber,
            name: consultationInfo.name,
            phoneNumber: consultationInfo.mobile,
            birthDate: consultationInfo.birth,
            genderCode: consultationInfo.gender,
            area: consultationInfo.region.city1,
            areaDetail: consultationInfo.region.city2,
            residentRegistrationNumberFront: residentRegistrationNumberFront,
            residentRegistrationNumberBack : encodeResidentRegistrationNumberBack,
            rrnoVldYn : checkRegistNumberDisabled && !registNumberSkip ? '1' : '0',
            selfAuthHistoryIdList,
        })
    }

    const validate = (info, testers) => {
        for (let j = 0; j < testers.length; j++) {
            const tester = testers[j];

            const names = tester[0].split('.');

            let target = Object.assign({}, info);

            for (let i = 0; i < names.length; i++) {
                let name = names[i];
                target = target[name];
            }

            if (Array.isArray(tester[1])) {
                if (tester[1].map((falseValvue) => target === falseValvue).includes(true)) {
                    alert(tester[2]);
                    return false;
                }
            } else if (typeof tester[1] === 'function') {
                if (!tester[1](target)) {
                    alert(tester[2]);
                    return false;
                }
            } else {
                if (target === tester[1]) {
                    alert(tester[2]);
                    return false;
                }
            }

        }
        return true;
    }

    const validateBirth = (value) => {
        const regex = new RegExp(/^[0-9]{8}$/)
        return regex.test(value);
    }

    const validateMobile = (value) => {
        const regex = new RegExp(/^01[0-9]([0-9]{7}|[0-9]{8})$/)
        return regex.test(value);
    }

    const validateRequestingCertification = () => {
        if (infoDisabled) {
            return true;
        }

        alert('먼저 인증번호를 받아야합니다');
        return false;
    }

    const validateConsultationInfoForCertNo = () => {
        return validate(consultationInfo, [
            ['name', '', '성명을 입력하세요'],
            ['birth', '', '생년월일을 확인해주세요'],
            ['birth', validateBirth, '생년월일을 8자리를 입력해주세요 예)20170101'],
            ['gender', '', '성별을 체크하세요'],
            ['mobile', '', '휴대폰번호를 입력하세요'],
            ['mobile', validateMobile, '휴대폰번호 11자리를 입력해주세요 예)01012345678'],
            ['telecomAccept.all', false, '통신사 약관에 모두 동의해주세요'],
        ]);
    }

    const validateConsultationInfoForRequest = () => {

        if(!checkRegistNumberDisabled) {alert('주민등록번호 확인을 해주세요'); return false;}

        let result = validate(illustrationAgreementInfo, [
            ['gather.all', false, '고객정보 관리 및 가입설계를 위하여 개인(신용)정보의 수집·이용 및 제공, 조회에 동의해 주세요'],
            ['offer.all', false, '고객정보 관리 및 가입설계를 위하여 개인(신용)정보의 수집·이용 및 제공, 조회에 동의해 주세요'],
            ['inquiry.all', false, '고객정보 관리 및 가입설계를 위하여 개인(신용)정보의 수집·이용 및 제공, 조회에 동의해 주세요'],
        ]);

        if(result === false) return;

        result = validate(consultationInfo, [
            ['certificated', false, '휴대폰 본인인증이 필요합니다'],
            ['region.city1', '', '지역을 입력하세요'],
            ['region.city2', (value) => {
                return !(consultationInfo.region.city1 !== '세종' && value === '');

            }, '지역을 입력하세요'],
            ['metlifePrivacy', [false, undefined], '개인정보 수집이용 약관에 동의해주세요'],
        ]);
        return result;
    }

    const changeInfo = (info, setInfo, key, value) => {
        const names = key.split('.');
        const length = names.length;
        const result = Object.assign({}, info);
        let target = result;

        for (let i = 0; i < length; i++) {
            let name = names[i];

            if (i === length - 1) {
                target[name] = value;
            } else {
                target = target[name];
            }
        }
        setInfo(Object.assign({}, result))
    }

    const findSidoNmAddress = async () => {
        const {item} = await FetchApi.get('/api/meton/channel/metdoadaptor/common/sidoNmAddress');
        setSidoNmAddress(item);
    }

    const findGgNmAddress = async (address) => {
        const {item} = await FetchApi.get('/api/meton/channel/metdoadaptor/common/ggNmAddress', {hnglSidoNm: address});
        setGgNmAddress(item)
    }

    const requestCertNo = async () => {
        return FetchApi.post('/api/meton/channel/mobile-auth', {
            customerName: consultationInfo.name,
            birthDate: consultationInfo.birth,
            genderCode: consultationInfo.gender,
            foreignerYn: consultationInfo.type === 'domestic' ? '0' : '1',
            cellularPhoneNumber: consultationInfo.mobile,
            cellularPhoneCompanyName: termsCondition.telecom,
        });
    }

    const certificateCertNo = async () => {
        return FetchApi.post('/api/meton/channel/mobile-auth-check', {
            customerName: consultationInfo.name,
            birthDate: consultationInfo.birth,
            genderCode: consultationInfo.gender,
            foreignerYn: consultationInfo.type === 'domestic' ? '0' : '1',
            cellularPhoneNumber: consultationInfo.mobile,
            cellularPhoneCompanyName: termsCondition.telecom,
            authorizationNumber: consultationInfo.certNo,
        });
    }

    const checkNewValue = (entries, key, value) => {
        return !Object.entries(entries).map((entry) => {
            if (entry[0] === 'all') {
                return true;
            }
            return entry[0] === key ? value : entry[1];
        }).some(i => [false,undefined].includes(i));
    }

    const handleOpenTerms = (type) => {
        setTermsCondition({...termsCondition, type})
        setTermsModalShow(true);
    }

    const handleRequestCertificationNumber = () => {
        if (validateConsultationInfoForCertNo()) {
            // 인증번호 받기
            requestCertNo()
              .then((data) => {
                  if (data.message) {
                      alert(data.message);
                      return;
                  }
                  alert('인증번호가 전송되었습니다');
                  setInfoDisabled(true);
                  setTimerOn(true);
              })
              .catch((e) => alert(e.description || '인증번호 전송에 실패하였습니다. 다시 시도해 주세요.'));
        }
    }

    const handleRequestConsultation = async () => {
        if (validateConsultationInfoForRequest()) {
            setProgressOpen(true);
            //상담 신청하기
            requestConsult().then(() => {
                setProgressOpen(false);
                alert('상담신청이 완료되었습니다.');
            }).catch((e) => {
                setProgressOpen(false);
                alert(e.description || '상담 신청에 실패하였습니다. 다시 시도해 주세요.')
            });
        }
    }

    const handleRequestCertificate = () => {
        if (validateRequestingCertification()) {
            certificateCertNo()
              .then((data) => {
                  if (data.message) {
                      alert(data.message);
                      return;
                  }
                  alert('본인인증이 완료되었습니다');
                  setTimerOn(false)
                  setCertDisabled(true)
                  changeInfo(consultationInfo , setConsultationInfo, 'certificated', true);
                  setName(consultationInfo.name);
                  setResidentRegistrationNumberFront(consultationInfo.birth.slice(2));
                  setSelfAuthHistoryIdList([...selfAuthHistoryIdList, data?.item?.selfAuthorizationHistoryId])
              })
              .catch((e) => alert(e.description || '인증 오류입니다. 다시 시도해주세요.'));
        }
    }

    const showTerms = () => {
        return (
          <MetOnModal
            show={termsModalShow}
            setShow={setTermsModalShow}
            body={getTermsBody(termsCondition.telecom, termsCondition.type)}
          />
        );
    }

    const getTermsBody = (telecom, type) => {

        switch (type) {
            case 'Agency':
                return (<AgencyTerms telecom={telecom}/>);
            case 'Identifier':
                return (<IdentifierTerms telecom={telecom}/>);
            case 'Privacy':
                return (<PrivacyTerms telecom={telecom}/>);
            case 'Service':
                return (<ServiceTerms telecom={telecom}/>);
            default:
                return null;
        }
    }

    const Agree3 = ({data, middle, end })=>{
        return (
          <div className="agree3">
              <Form.Check type="radio" label="동의하지 않음" checked={data[middle][end] === false}
                          onChange={(e) =>
                            changeInfo(illustrationAgreementInfo, setIllustrationAgreementInfo, middle, {
                                all: checkNewValue(data[middle],end, false),
                                identifier: 'identifier' === end ? false : data[middle]['identifier'],
                                sensitive: 'sensitive' === end ? false : data[middle]['sensitive'],
                                credit: 'credit' === end ? false : data[middle]['credit'],
                            })
                          }/>
              <Form.Check type="radio" label="동의함" checked={data[middle][end] === true}
                          onChange={(e) =>
                            changeInfo(illustrationAgreementInfo, setIllustrationAgreementInfo, middle, {
                                all: checkNewValue(data[middle],end, true),
                                identifier: 'identifier' === end ? true : data[middle]['identifier'],
                                sensitive: 'sensitive' === end ? true : data[middle]['sensitive'],
                                credit: 'credit' === end ? true : data[middle]['credit'],
                            })
                          }/>
          </div>
        )
    }

    //nFilter 보안 키패드
    useEffect(() => {nFilterInit()}, [])

    const nFilterInit = () => {
        if (veridationValue(typeof window.nshc)) return setTimeout(nFilterInit, 100, false);
        // ============================== 필수 설정 함목 =================================
        /**
         * 서버 웹루트 설정
         * 해당 URL을 통해 서버와 통신
         */
        window.nshc.contextPath = `${window.location.protocol}//${window.location.host}`;
        // 키패드 CSS 경로 설정
        window.nshc.CSSPath = window.nshc.contextPath + "/nfilter/css";
        // 키패드 image 경로 설정
        window.nshc.imgPath = window.nshc.contextPath + "/nfilter/image";

        window.nshc.randomIdLength = 10;
        window.nshc.serviceNameKeypadManager = "api/meton/nshcwebfilter/NFilterKeypadManager";
        /**
         * 암호화 알고리즘 설정 (RSA, ECDH)
         * 기본값 : RSA
         * 해당 설정은 서버의 nfilter-openweb.properties의 nshc.security.alg.name 항목의 값과 동일하게 맞춰 주셔야 합니다.
         */
        window.nshc.algName = 'ECDH';
        window.nshc.keyLength = 2048;
        /**
         * 서버고정 암호화키 사용 여부 설정 (true, false)
         * 기본값 : false
         * 해당 설정은 위 nshc.algName 설정값이 'ECDH'일 경우에만 사용 가능함
         */
        window.nshc.uuSessionUse = true;
        // ============================================================================

        // 키패드 초기화 시작
        window.nshc.setOnInitStart();

        window.nshc.setCommon(document.getElementById('residentNumberBack'), "mode=nCKpd");   // 숫자, cusNKpd

        window.nshc.ownCallback = function (msg, inputID) {
            if(msg === 'enter'){
                let encData = window.nshc.encrypted();
                encData = encodeURIComponent(encData);

                setEncodeResidentRegistrationNumberBack(encData)
            }
        }

        // Open Web nFilter 레이아웃 초기화
        window.nshc.setInit();
    }

    const veridationValue = (value) => {
        return value === 'null' ||
          value === null ||
          value === 'undefined' ||
          value === undefined;
    }

    // 주민등록번호 검증
    const checkRegistNumber = async () => {
        return  FetchApi.get('/api/meton/channel/common/checkRegistNumber', {
            residentRegistrationNumberFront: residentRegistrationNumberFront,
            residentRegistrationNumberBack : encodeResidentRegistrationNumberBack,
        })
    }

    const handleCheckRegistNumber = () => {
        if(!name || !residentRegistrationNumberFront) {
            alert('성명과 주민등록 번호를 입력해 주세요.');
            return;
        }

        if(document.getElementById('residentNumberBack').value !== '*******') {
            alert('주민등록번호 뒷자리 7개를 정확히 입력해주세요.');
            return;
        }

        checkRegistNumber()
          .then((res) =>{
              alert(res.message);
              setCheckRegistNumberDisabled(true);
          }).catch(err =>{
            if(window.confirm(err.description)){
                setCheckRegistNumberDisabled(true);
                setRegistNumberSkip(true);
            };
        })
    }

    useEffect(()=>{
        if(checkRegistNumberDisabled){
            document.getElementById('residentNumberBack').disabled = true;
        }else{
            document.getElementById('residentNumberBack').disabled = false;
        }
    },[checkRegistNumberDisabled])

    return (
      <>
          {/* 상담신청 */}
          <Form className="writeform consult">
              <h3>상담신청/가입설계동의를 위한 정보 입력</h3>

              <p className="essential">필수 입력 사항입니다.</p>
              <Form.Group
                className="mb-3 ess colgroup"
                controlid="formGroupName"
              >
                  <Form.Label>성명</Form.Label>
                  <p>
                      <Form.Control type="text" placeholder="성명을 입력하세요." value={consultationInfo.name}
                                    disabled={infoDisabled}
                                    onChange={(e) => changeInfo(consultationInfo , setConsultationInfo, 'name', e.target.value.trim())}/>
                      <Form.Select value={consultationInfo.type} disabled={infoDisabled}
                                   onChange={(e) => changeInfo(consultationInfo , setConsultationInfo, 'type', e.target.value)}>
                          <option value="domestic">내국인</option>
                          <option value="foreigner">외국인</option>
                      </Form.Select>
                  </p>
              </Form.Group>
              <Form.Group
                className="mb-3 ess colgroup2"
                controlid="formGroupBirthday"
              >
                  <Form.Label>생년월일</Form.Label>
                  <p>
                  <span>
                    <Form.Control type="number" pattern="\d*" placeholder="예) 19920101"
                                  value={consultationInfo.birth}
                                  disabled={infoDisabled}
                                  onChange={(e) => {
                                      changeInfo(consultationInfo , setConsultationInfo, 'birth', e.target.value)
                                  }}
                    />
                  </span>
                      <span>
                    <Form.Check type="radio" label="남" checked={consultationInfo.gender === '1'}
                                disabled={infoDisabled}
                                onClick={() => changeInfo(consultationInfo , setConsultationInfo, 'gender', '1')}
                                onChange={() => {
                                }}
                    />
                    <Form.Check type="radio" label="여" checked={consultationInfo.gender === '2'}
                                disabled={infoDisabled}
                                onClick={() => changeInfo(consultationInfo , setConsultationInfo, 'gender', '2')}
                                onChange={() => {
                                }}
                    />
                  </span>
                  </p>
              </Form.Group>

              <Form.Group className="mb-3 ess" controlid="formGroupPhone">
                  <Form.Label>통신사선택</Form.Label>
                  <Form.Select value={termsCondition.telecom}
                               disabled={infoDisabled}
                               onChange={(e) => {
                                   setTermsCondition({...termsCondition, telecom: e.target.value});
                                   changeInfo(consultationInfo , setConsultationInfo, 'telecomAccept', {
                                       all: false,
                                       agency: false,
                                       identifier: false,
                                       privacy: false,
                                       service: false,
                                   })
                               }}>
                      <option value="SKT">SKT</option>
                      <option value="KTF">KT</option>
                      <option value="LGT">LGU+</option>
                      <option value="SKM">SKT(알뜰폰)</option>
                      <option value="KTM">KT(알뜰폰)</option>
                      <option value="LGM">LGU+(알뜰폰)</option>
                  </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3 ess" controlid="formGroupPhone">
                  <Form.Label>휴대폰번호</Form.Label>
                  <Form.Control
                    type="number"
                    pattern="\d*"
                    placeholder="'-'제외하고 숫자만 입력"
                    value={consultationInfo.mobile}
                    disabled={infoDisabled}
                    onChange={(e) => {
                        changeInfo(consultationInfo , setConsultationInfo, 'mobile', e.target.value)
                    }}
                  />
              </Form.Group>
              <div className="dash">
                  <li>
                      본인 명의의 휴대폰 정보를 정확히 입력하여 주시기 바랍니다.
                  </li>
                  <li>본인인증 5회 실패 시 당일 휴대폰 인증이 제한됩니다.</li>
                  <li>
                      알뜰폰이란? 이동통신사(SKT, KT, LG U+)의 통신망을 임대하여
                      별도의 휴대폰 서비스를 제공하는 사업자를 말합니다.
                  </li>
              </div>
              <Form.Group controlid="yak" className="yak_area">
                  <Form.Group controlid="yak1">
                      <Form.Check
                        type="checkbox"
                        label="서비스 이용약관"
                        className="yak"
                        disabled={infoDisabled}
                        onChange={(e) => {
                            changeInfo(consultationInfo , setConsultationInfo, 'telecomAccept', {
                                all: checkNewValue(consultationInfo.telecomAccept,'service', e.target.checked),
                                agency: consultationInfo.telecomAccept.agency,
                                identifier: consultationInfo.telecomAccept.identifier,
                                privacy: consultationInfo.telecomAccept.privacy,
                                service: e.target.checked,
                            });
                        }}
                        checked={consultationInfo.telecomAccept.service}
                      />
                      <Button
                        className="button3"
                        bsPrefix="string"
                        onClick={() => handleOpenTerms('Service')}
                      >
                          전문보기
                      </Button>
                      {/*서비스 이용약관*/}
                  </Form.Group>
                  <Form.Group controlid="yak2">
                      <Form.Check
                        type="checkbox"
                        label="개인정보 이용 동의"
                        className="yak"
                        checked={consultationInfo.telecomAccept.privacy}
                        disabled={infoDisabled}
                        onChange={(e) =>
                          changeInfo(consultationInfo , setConsultationInfo, 'telecomAccept', {
                              all: checkNewValue(consultationInfo.telecomAccept,'privacy', e.target.checked),
                              agency: consultationInfo.telecomAccept.agency,
                              identifier: consultationInfo.telecomAccept.identifier,
                              privacy: e.target.checked,
                              service: consultationInfo.telecomAccept.service,
                          })
                        }
                      />
                      <Button
                        className="button3"
                        bsPrefix="string"
                        onClick={() => handleOpenTerms('Privacy')}
                      >
                          전문보기
                      </Button>
                      {/* 개인정보 이용 동의*/}
                  </Form.Group>
                  <Form.Group controlid="yak3">
                      <Form.Check
                        type="checkbox"
                        label="고유식별정보 처리 동의"
                        className="yak"
                        checked={consultationInfo.telecomAccept.identifier}
                        disabled={infoDisabled}
                        onChange={(e) =>
                          changeInfo(consultationInfo , setConsultationInfo, 'telecomAccept', {
                              all: checkNewValue(consultationInfo.telecomAccept,'identifier', e.target.checked),
                              agency: consultationInfo.telecomAccept.agency,
                              identifier: e.target.checked,
                              privacy: consultationInfo.telecomAccept.privacy,
                              service: consultationInfo.telecomAccept.service,
                          })
                        }
                      />
                      <Button
                        className="button3"
                        bsPrefix="string"
                        onClick={() => handleOpenTerms('Identifier')}
                      >
                          전문보기
                      </Button>
                      {/*고유식별정보 처리 동의*/}
                  </Form.Group>
                  <Form.Group controlid="yak4">
                      <Form.Check
                        type="checkbox"
                        label="통신사 이용약관 동의"
                        className="yak"
                        checked={consultationInfo.telecomAccept.agency}
                        disabled={infoDisabled}
                        onChange={(e) => {
                            changeInfo(consultationInfo , setConsultationInfo, 'telecomAccept', {
                                all: checkNewValue(consultationInfo.telecomAccept,'agency', e.target.checked),
                                agency: e.target.checked,
                                identifier: consultationInfo.telecomAccept.identifier,
                                privacy: consultationInfo.telecomAccept.privacy,
                                service: consultationInfo.telecomAccept.service,
                            })

                        }}
                      />
                      <Button
                        className="button3"
                        bsPrefix="string"
                        onClick={() => handleOpenTerms('Agency')}
                      >
                          전문보기
                      </Button>
                      {/*고유식별정보 처리 동의*/}
                  </Form.Group>
                  <Form.Group controlid="yak5">
                      <Form.Check
                        type="checkbox"
                        label="위 내용을 모두 확인하였으며, 전체 동의합니다."
                        className="yak yak_all"
                        checked={consultationInfo.telecomAccept.all}
                        disabled={infoDisabled}
                        onChange={(e) => changeInfo(consultationInfo , setConsultationInfo, 'telecomAccept', {
                            all: e.target.checked,
                            agency: e.target.checked,
                            identifier: e.target.checked,
                            privacy: e.target.checked,
                            service: e.target.checked,
                        })
                        }
                      />
                  </Form.Group>
              </Form.Group>
              <Button className="button button2" bsPrefix="string" disabled={infoDisabled}
                      onClick={handleRequestCertificationNumber}>
                  인증번호 받기
              </Button>
              <Form.Group className="mb-4" controlid="formGroupConfirm">
                  <Form.Label>인증번호</Form.Label>
                  <Form.Control type="number" pattern="\d*" placeholder="인증번호 6자리" value={consultationInfo.certNo}
                                onChange={(e) => changeInfo(consultationInfo , setConsultationInfo, 'certNo', e.target.value)}
                                disabled={certDisabled}/>
                  <span className="intime">{timeText}</span>
                  <Button className="button" bsPrefix="string" onClick={handleRequestCertificate}
                          disabled={certDisabled}>
                      인증하기
                  </Button>
              </Form.Group>
              <h3>본인정보</h3>
              <Form.Group className="mb-3 ess" controlid="formGroupPhone">
                  <Form.Label>성명</Form.Label>
                  <Form.Control type="text" value={name} readOnly />
              </Form.Group>
              <Form.Group className="mb-3 ess" controlid="formGroupPhone">
                  <Form.Label>주민등록번호</Form.Label>
                  <div className="write_in2">
                      <Form.Control type="number" value={residentRegistrationNumberFront} readOnly />
                      <span>-</span>
                      <Form.Control id='residentNumberBack' type="text" maxLength='7'/>
                  </div>
                  <Button className="button" bsPrefix="string" onClick={handleCheckRegistNumber}
                          disabled={checkRegistNumberDisabled}>
                      주민등록번호 확인
                  </Button>
              </Form.Group>
              <Form.Group className="mb-3 ess" controlid="formGroupCity1">
                  <Form.Label>지역</Form.Label>
                  <Form.Select value={consultationInfo.region.city1}
                               onChange={(e) => {
                                   findGgNmAddress(e.target.value);
                                   changeInfo(consultationInfo , setConsultationInfo, 'region.city1', e.target.value);
                               }}>
                      <option value="">시 도 광역시</option>
                      {sidoNmAddress.map((address, idx) => <option key={`sidonm-${idx}`}
                                                                   value={address.hnglSidoNm}>{address.hnglSidoNm}</option>)}
                  </Form.Select>
                  {consultationInfo.region.city1 !== '세종' &&
                    <Form.Select controlid="formGroupCity2" value={consultationInfo.region.city2}
                                 onChange={(e) => changeInfo(consultationInfo , setConsultationInfo, 'region.city2', e.target.value)}>
                        <option value="">시군구</option>
                        {GgNmAddress.map((address, idx) => <option key={`ggnm-${idx}`}
                                                                   value={address.hnglGgnm}>{address.hnglGgnm}</option>)}
                    </Form.Select>}
              </Form.Group>
              <Form.Group className="mb-3" controlid="formGroupAgree">
                  <Form.Label>[필수] 개인정보 수집&#183;이용에 관한 동의</Form.Label>
                  <p className="agree">
                      당사는 <strong>가입상담 및 보험상품 소개</strong> 등을 위하여
                      『개인정보 보호법』에 따라 귀하의 개인 정보를 다음과 같이
                      수집·이용하고자 합니다.
                      <br/>
                      <br/>
                      개인정보의 수집·이용 목적
                      <br/>- <strong>가입상담 안내 및 보험상품 소개</strong>
                      <br/>
                      <br/>
                      수집하려는 개인정보의 항목 <br/>
                      - 성명, 휴대전화번호, 지역
                      <br/>
                      <br/>
                      개인정보의 보유·이용 기간
                      <br/>- <strong>동의일로부터 1년</strong>
                      <br/>
                      <br/> 고객님께서는 동의를 거부할 수 있으며, 이 경우 가입상담
                      안내 및 보험상품 소개 등의 서비스제공이 불가능 합니다. 또한
                      동의하시더라도 당사 홈페이지 및 콜센터(1588-9600)를 통해
                      철회하거나 가입 권유 목적의 연락에 대한 중단을 요청하실 수
                      있으며 연락중지청구시스템
                      <a href="http://www.donotcall.or.kr/" target="_blank" rel="noreferrer">
                          (www.donotcall.or.kr)
                      </a>
                      을 통해 언제든지 마케팅 활동에 대한 중지를 요청할 수 있습니다.
                  </p>
              </Form.Group>
              <Form.Group controlid="agree1" className="mb-3 gender consult_form">
                  <Form.Check type="radio" label="동의하지 않음" checked={consultationInfo.metlifePrivacy === false}
                              onClick={() => changeInfo(consultationInfo , setConsultationInfo, 'metlifePrivacy', false)} onChange={() => {
                  }}/>
              </Form.Group>
              <Form.Group controlid="agree2" className="mb-3 gender consult_form">
                  <Form.Check type="radio" label="동의함" checked={consultationInfo.metlifePrivacy === true}
                              onClick={() => changeInfo(consultationInfo , setConsultationInfo, 'metlifePrivacy', true)} onChange={() => {
                  }}/>
              </Form.Group>
              <p className="agree_txt">
                  <strong>
                      본 동의는 당사와 모집위탁계약을 체결한 업무수탁자(당사 전속
                      보험설계사 및 메트라이프금융서비스)가 수집·이용하는 것을
                  </strong>
                  &nbsp;포함합니다.
                  <br />
                  <br />
              </p>
              <Form.Group className="mb-3" controlid="formGroupAgree">
                  <Form.Label>
                      [필수] 고객정보 관리 및 가입 설계를 위한 동의(상세)
                  </Form.Label>
                  <p className="agree2">
                      <ul className="dash">
                          <li>
                              귀하는 개인(신용)정보의 수집·이용 및 제공, 조회에 관한 동의를
                              거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의
                              개인(신용)정보 제공동의를 철회할 수 있습니다.
                          </li>
                          <li>
                              다만, 본 동의는 '고객정보 관리 및 가입설계'를 위한
                              필수사항이므로 동의를 거부하신 경우 관련 업무수행이
                              불가능합니다.
                          </li>
                          <li>
                              동의하시는 경우 아래 항목을 눌러 동의함에 체크하여 주시고,
                              동의자가 미성년자인 경우 법정대리인이 동의의 의사 표시를
                              하여주시기 바랍니다.
                          </li>
                      </ul>
                      <strong>
                          고객정보 관리 및 가입설계를 위하여 개인(신용)정보의 수집·이용 및
                          제공, 조회에 동의해 주세요.(필수)
                          <br />
                          <br />
                      </strong>
                      <h4>1. 개인(신용)정보의 수집 · 이용에 관한 사항</h4>
                      <p className="allCheck">
                          <Form.Check type="checkbox" label="전체 동의"
                                      checked={illustrationAgreementInfo.gather.all === true}
                                      onChange={(e) =>
                                        changeInfo(illustrationAgreementInfo, setIllustrationAgreementInfo, 'gather', {
                                            all: e.target.checked,
                                            identifier: e.target.checked,
                                            sensitive: e.target.checked,
                                            credit: e.target.checked,
                                        })
                                      }/>
                      </p>
                      <div className="grayBox">
                          <h5>1） 수집·이용 목적</h5>
                          <ul className="dash">
                              <li>
                                  보험계약 상담, 재무설계서비스, 보험계약 인수여부 판단(건강진단
                                  및 계약 적부조사 포함)
                              </li>
                          </ul>
                          <h5>2） 보유 및 이용기간</h5>
                          <ul className="dash">
                              <li className="big2">동의일로부터 1년까지</li>
                          </ul>
                          <h5>3） 수집·이용 항목</h5>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      고유식별정보 :{" "}
                                      <span className="big2">
                        주민등록번호, 외국인등록번호, 여권번호, 운전면허번호
                        </span>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 고유식별정보 <span className="under">수집·이용</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='gather' end = 'identifier'/>
                          </div>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      민감정보 :{" "}
                                      <span className="big2">
                        피보험자의 질병·상해에 관한 정보(진료기록, 상병명,
                        흡연여부 등)
                        </span>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 민감정보 <span className="under">수집·이용</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='gather' end = 'sensitive'/>
                          </div>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      개인(신용)정보 :{" "}
                                      <ul className="dash">
                                          <li>
                                              일반개인정보 : 성명, 주소, 생년월일, 이메일,
                                              유·무선전화번호, 국적, 직업, 운전여부, 신용관리번호,
                                              국내거소신고번호
                                          </li>
                                          <li>
                                              신용거래정보 : 보험계약정보(상품종류, 기간, 보험가입금액
                                              등), 보험금정보(보험금 지급사유, 지급금액 등)
                                          </li>
                                          <li>신용능력정보 : 소득 및 재산정보</li>
                                      </ul>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 개인신용정보 <span className="under">수집·이용</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='gather' end = 'credit'/>
                          </div>
                      </div>
                      <h4>2. 개인(신용)정보의 제공에 관한 사항</h4>
                      <p className="allCheck">
                          <Form.Check type="checkbox" label="전체 동의" checked={illustrationAgreementInfo.offer.all === true}
                                      onChange={(e) =>
                                        changeInfo(illustrationAgreementInfo, setIllustrationAgreementInfo, 'offer', {
                                            all: e.target.checked,
                                            identifier: e.target.checked,
                                            sensitive: e.target.checked,
                                            credit: e.target.checked,
                                        })
                                      }/>
                      </p>
                      <div className="grayBox">
                          <h5>1） 제공 받는 자</h5>
                          <ul className="dash">
                              <li className="big2">
                                  국내외 재보험사, 종합신용정보집중기관, 보험요율산출기관,
                                  통신사(신용정보회사)
                              </li>
                          </ul>
                          <h5>2） 제공 받는 자의 이용목적</h5>
                          <ul className="dash">
                              <li className="big2">
                                  국내외 재보험사 : 보험계약 인수여부 판단
                              </li>
                              <li className="big2">
                                  종합신용정보집중기관, 보험요율산출기관 : 개인(신용)정보 조회
                              </li>
                              <li className="big2">
                                  통신사(신용정보회사) : 본인인증 정보 조회
                              </li>
                          </ul>
                          <h5>3） 보유 및 이용기간</h5>
                          <ul className="dash">
                              <li className="big2">동의일로부터 1년까지</li>
                          </ul>
                          <span className="small_text short">
                *외국 재보험사의 국내 지점이 보험계약 인수여부 판단 지원 등
                업무를 위탁하기 위한 경우 별도 동의없이 외국 소개 본점에 귀하의
                정보를 이전할 수 있습니다
              </span>
                          <h5>4） 제공 항목</h5>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      고유식별정보 :{" "}
                                      <span className="big2">
                      주민등록번호, 외국인등록번호, 여권번호, 운전면허번호
                    </span>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 고유식별정보 <span className="under">제공</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='offer' end = 'identifier'/>
                          </div>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      민감정보 :{" "}
                                      <span className="big2">
                      피보험자의 질병·상해에 관한 정보(진료기록, 상병명, 기왕증
                      등)
                    </span>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 민감정보 <span className="under">제공</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='offer' end = 'sensitive'/>
                          </div>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      개인(신용)정보 :{" "}
                                      <ul className="dash">
                                          <li>
                                              일반개인정보 : 성명, 신용정보회사/통신사의 실명인증 및
                                              본인 인증을 위한 정보, 신용관리번호, 국내거소신고번호
                                          </li>
                                          <li>
                                              신용거래정보 : 보험계약정보(상품종류, 기간, 보험가입금액
                                              등), 보험금정보(보험금 지급사유, 지급금액 등) 위
                                              개인신용정보 조회에 동의하십니까?
                                          </li>
                                      </ul>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 개인신용정보 <span className="under">제공</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='offer' end = 'credit'/>
                              <span className="small_text">
                  *업무위탁을 목적으로 개인(신용)정보처리하는 경우 별도의
                  동의없이 업무 수탁자에게 귀하의 정보를 제공할 수
                  있습니다.(홈페이지 www.metlife.co.kr에서 확인가능)
                </span>
                          </div>
                      </div>
                      <h4>3. 개인(신용)정보의 조회에 관한 사항</h4>
                      <p className="allCheck">
                          <Form.Check type="checkbox" label="전체 동의" checked={illustrationAgreementInfo.inquiry.all === true}
                                      onChange={(e) =>
                                        changeInfo(illustrationAgreementInfo, setIllustrationAgreementInfo, 'inquiry', {
                                            all: e.target.checked,
                                            identifier: e.target.checked,
                                            sensitive: e.target.checked,
                                            credit: e.target.checked,
                                        })
                                      }/>
                      </p>
                      <div className="grayBox">
                          <h5>1） 조회대상 기관과 조회목적</h5>
                          <ul className="dash">
                              <li>
                                  종합신용정보집중기관 : 보험계약 인수여부 판단, 가입한도 조회,
                                  실손보험 중복확인
                              </li>
                              <li>
                                  보험요율산출기관 : 보험계약 인수여부 판단, 기타 법령에 의한
                                  업무수행
                              </li>
                              <li>통신사(신용정보회사) : 본인 인증 정보 조회</li>
                          </ul>
                          <h5>2） 조회동의 효력기간</h5>
                          <ul className="dash">
                              <li className="big2">
                                  동의일로부터 보험계약의 청약 시까지(최대1년)
                              </li>
                          </ul>

                          <h5>3） 조회 항목</h5>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      고유식별정보 :{" "}
                                      <span className="big2">
                      주민등록번호, 외국인등록번호, 여권번호, 운전면허번호
                    </span>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 고유식별정보 <span className="under">조회</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='inquiry' end = 'identifier'/>
                          </div>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      민감정보 :{" "}
                                      <span className="big2">
                      피보험자의 질병·상해에 관한 정보(진료기록, 상병명 등)
                    </span>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 민감정보 <span className="under">조회</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='inquiry' end = 'sensitive'/>
                          </div>
                          <div className="agreebox">
                              <ul className="arrow">
                                  <li>
                                      개인(신용)정보 :{" "}
                                      <ul className="dash">
                                          <li>
                                              일반개인정보 : 성명, 신용정보회사/통신사의 실명인증 및
                                              본인 인증을 위한 정보, 신용관리번호, 국내거소신고번호
                                          </li>
                                          <li>
                                              신용거래정보 : 보험계약정보(상품종류, 기간, 보험가입금액
                                              등), 보험금정보(보험금 지급사유, 지급금액 등)
                                          </li>
                                      </ul>
                                  </li>
                              </ul>
                              <p className="agreeText">
                                  위 개인신용정보 <span className="under">조회</span>에
                                  동의하십니까?
                              </p>
                              <Agree3 data = {illustrationAgreementInfo} middle='inquiry' end = 'credit'/>
                          </div>
                      </div>
                      <strong className="right simsa">
                          (심사승인번호: DIS007-2105-6003-001)
                      </strong>
                  </p>
              </Form.Group>
          </Form>

          <Button className="button" bsPrefix="string" onClick={handleRequestConsultation}>
              상담신청 완료
          </Button>
          {showTerms()}
          <Progress progresOpen={progressOpen}/>
      </>
    );
};

export default UI_03_01;