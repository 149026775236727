import {Dropdown} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FetchApi} from "../../common";

const UI_09_04 = function () {

    useEffect(() => {
        retrieveFooterMenu();
    }, []);

    const [menus, setMenus] = useState([]);

    const retrieveFooterMenu = async () => {
        const {item} = await FetchApi.get('/api/meton/channel/menu', {
            sort: 'sortSequenceNumber:asc,menuCode:asc,menuLevelValue:asc',
            showIndicator: 1,
            parentMenuCode: 'A0000080'
        });

        setMenus(item)
    }

    return (
        <div className="met_bottom">
            <div className="footer">
                <div className="link">
                    <li className="icon icon01">
                        <a
                            href="https://www.instagram.com/metlife_korea/"
                            title="인스타그램 새창열림"
                            target="_blank"
                            rel="noreferrer"
                        >
                            인스타그램
                        </a>
                    </li>
                    <li className="icon icon02">
                        <a
                            href="https://www.facebook.com/koreametlife/"
                            title="페이스북 새창열림"
                            target="_blank"
                            rel="noreferrer"
                        >
                            페이스북
                        </a>
                    </li>
                    <li className="icon icon03">
                        <a
                            href="https://post.naver.com/metlife_kr"
                            title="네이버 새창열림"
                            target="_blank"
                            rel="noreferrer"
                        >
                            네이버
                        </a>
                    </li>
                    <li className="icon icon04">
                        <a
                            href="https://www.youtube.com/channel/UCPW_JIdOxM0yGuFZZ6KqQJw"
                            title="유튜브 새창열림"
                            target="_blank"
                            rel="noreferrer"
                        >
                            유튜브
                        </a>
                    </li>
                    <li className="icon icon05">
                        <a
                            href="https://pf.kakao.com/_eYbxiu"
                            title="카카오톡 새창열림"
                            target="_blank"
                            rel="noreferrer"
                        >
                            카카오톡
                        </a>

                    </li>
                </div>
                <div className="familysite">
                    <Dropdown className="met-Dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Family Site
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {menus.map((menu, index) =>
                                <Dropdown.Item key={`footer-menu-${index}`} href={menu.screenPathText} target="_blank">
                                    {menu.menuName}
                                </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="copyright">
                    서울시 강남구 테헤란로 316(역삼2동, 메트라이프타워)
                    <br/>
                    콜센터 : 국내 <span><a href="tel:1588-9600">1588-9600</a> </span>, 해외
                    <span><a href="tel:+82-2-2017-6700">+82-2-2017-6700</a></span>
                    <br/>
                    홈페이지 <span><a href="http://www.metlife.co.kr">www.metlife.co.kr</a></span>
                    <br/>
                    <br/>ⓒ 메트라이프생명보험(주) All Rights Reserved.
                </div>
            </div>
        </div>
    );
};

export default UI_09_04;