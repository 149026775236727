import moment from "moment";
import copy from "copy-to-clipboard";

export function formatDate(date, format) {
    return moment(date).format(format);
}

export function getContentImgSrc(fileName) {
    return `${process.env.REACT_APP_IMAGE}/content/${fileName}&viewYn=Y`
}

export function getBannerImgSrc(fileName) {
    return `${process.env.REACT_APP_IMAGE}/banner/${fileName}&viewYn=Y`
}

export function getProfileImgSrc(fileName) {
    return `${process.env.REACT_APP_IMAGE}/profile/${fileName}&viewYn=Y`
}

export function copyOnClipboard(value, name) {
    copy(value, {
        onCopy: () => {
            alert(`${name}가 복사되었습니다.`);
        },
        format: 'text/plain'
    });
}

export function getMenuHierarchy(menuList) {
    const sortedByLevelList = [];

    menuList.forEach((menu) => {
        if (typeof sortedByLevelList[menu.menuLevelValue - 1] === 'undefined') {
            sortedByLevelList[menu.menuLevelValue - 1] = {[menu.menuCode]: menu};
        } else {
            sortedByLevelList[menu.menuLevelValue - 1] = {
                ...sortedByLevelList[menu.menuLevelValue - 1], [menu.menuCode]: menu
            };
        }
    });
    const reversed = sortedByLevelList.reverse();
    const result = [];

    reversed.forEach((menuMap, index) => {
        Object.values(menuMap).forEach((menu) => {
            if (index === (reversed.length - 1)) {
                result.push(menu);
            } else {
                const parent = reversed[index + 1][menu.parentMenuCode];
                if (typeof parent['children'] === 'undefined') {
                    parent['children'] = [menu]
                } else {
                    parent['children'] = [...parent['children'], menu]
                }
            }
        })
    });

    return result;
}

export function getMenuMap(menuList) {
    const result = new Map();

    menuList.forEach((menu) => {
        result.set(menu.menuCode, menu.menuId);
    });

    return result;
}

export function getDeviceType() {
    const varUA = window.navigator.userAgent.toLowerCase();

    let osType;
    if (varUA.indexOf('android') > -1) {
        osType = 'android';
    } else if (
        varUA.indexOf('iphone') > -1 ||
        varUA.indexOf('ipad') > -1 ||
        varUA.indexOf('ipod') > -1
    ) {
        osType = 'ios';
    } else {
        osType = 'other';
    }

    return osType;
}

export function renderRouterElement(children) {

    return children;
}

export function createSemmURL(agent){
    const { agentUrl, basicInfo } = agent;
    const { businessChannelDivisionCode } = basicInfo;

    const semmUrlInfo = agentUrl.find(url => url.typeCode === '01');

    if (semmUrlInfo) {
        const host =  process.env.REACT_APP_SEMM
        /** 암호화 된 agentNumber */
        const { encAgentNumber } = semmUrlInfo

        /** + 같은 기호들을 url encoding 함 */
        const encodedAndEncryptedAgentNumber = encodeURIComponent(encAgentNumber);
        const campaignId = 'OP100';
        const eventCode = campaignId + '_01';
        const source = 'meton';

        return host
            + '?'
            + 'campaignId=' + campaignId
            + '&eventCode=' + eventCode
            + '&source=' + source
            + '&channel=' + businessChannelDivisionCode
            + '&agentNumber=' + encodedAndEncryptedAgentNumber
    }
}