import Fade from "react-reveal/Fade";
import React, {useContext, useLayoutEffect, useState} from "react";
import {getProfileImgSrc, MetOnModal, RootContext, Share, ShowMoreText,} from "../../common";
import miniProfileLogo from "../../assets/images/sub/profile_img_no.png"
import UI_LD_01_01_MainProfileSlide from "./UI_LD_01_01_MainProfileSlide";

const UI_01_01_GA = function () {
  const {agent, agentMultiProfiles} = useContext(RootContext);
  const [showMiniProfilePop, setShowMiniProfilePop] = useState(false);
  const [profileAttr, setProfileAttr] = useState({});
  const [isCrossAgent, setIsCrossAgent] = useState(false); //교차 설계서 여부

  useLayoutEffect(() => {
    const {financialConsultantChannelCode, businessPositionTypeCode} = agent?.crossAgentInfo;

    // 설계사 채널 구분코드가 '0111' 이고 직책 구분코드가 '1010' 인 경우 표시
    if (Object.is('0111', financialConsultantChannelCode)
      && Object.is('1010', businessPositionTypeCode)) {
      setIsCrossAgent(true);
    } else {
      setIsCrossAgent(false);
    }

    if (agent.profile.profileStoredImageFileName) {
      setProfileAttr({
        style: {
          background: `url("${getProfileImgSrc(
            agent.profile.profileStoredImageFileName
          )}") 0% 0% / cover`,
        },
      });
    } else {
      setProfileAttr({});
    }
  }, [agent]);

  function handleShowMiniProfilePop() {
    setShowMiniProfilePop(true);
  }

  return (
    <div>
      {/* PROFILE : START */}
      {/* 프로필 이미지 영역 */}
      <UI_LD_01_01_MainProfileSlide>
        <div
          className={agentMultiProfiles && 0 < agentMultiProfiles.length ? "visual_info visual_seasonal_info" : "visual_info"}>
          <li>
            오늘부터 당신의 금융 파트너,
            <br/>
            {agent.basicInfo.businessChannelDivisionCode === "06"
              ? "메트라이프금융서비스"
              : "메트라이프"}
          </li>
        </div>
      </UI_LD_01_01_MainProfileSlide>
      {/* PROFILE : END */}
      <div className="profile_area">
        <div className="profile_name">
          {/* 20221006 이미지를 배경으로 처리함 */}
          <button onClick={() => handleShowMiniProfilePop()}>
            <p {...profileAttr} />
          </button>
          <strong>
            {agent.basicInfo.employeeName} {isCrossAgent && "교차설계사"}
            <Share/>
            <br/>
            <span>
              {`${agent.basicInfo.branchOfficeOrganizationName}`}
            </span>
          </strong>
        </div>
        <div className="profile_connect">
          <dl>
            <dt>연락처</dt>
            <dd>
              <a
                href={`tel:${agent.basicInfo.mobilePhoneNumber}`}
                title="Agent에게 전화하기"
                style={{color: "inherit"}}
              >
                {agent.basicInfo.mobilePhoneNumber &&
                  agent.basicInfo.mobilePhoneNumber
                    .replace(/[^0-9]/g, "")
                    .replace(/^(\d{3})(\d{4})(\d{4})$/, `$1-$2-$3`)}
              </a>
            </dd>
          </dl>
          <dl>
            <dt>카카오톡</dt>
            <dd>{agent.profile.kakaotalkId}</dd>
          </dl>
          <dl>
            <dt>이메일</dt>
            <dd>{agent.profile.emailText}</dd>
          </dl>
        </div>
      </div>
      <div className="met_mainarea">
        <div className="oneline">
          <p>한줄소개</p>
          <Fade bottom>
              <span style={{display: "block"}}>
                <ShowMoreText className="onelinebody" keepNewLines anchorClass="show-more-less-clickable moreText">
                  {agent.profile.introduceText
                    ? agent.profile.introduceText
                    : `고객의 일상을 함께하는 동반자가 되어 드리겠습니다.`}
              </ShowMoreText>
            </span>
          </Fade>
        </div>
      </div>
      {/* 20230926 프로필이미지 확대팝업 */}
      <MetOnModal
        show={showMiniProfilePop}
        setShow={setShowMiniProfilePop}
        title="mini프로필이미지"
        className="profile_big"
        body={
          <div>
            <img
              src={agent.profile.profileStoredImageFileName !== null ?
                getProfileImgSrc(agent.profile.profileStoredImageFileName)
                : miniProfileLogo}
              alt=""
              className="photo"
            />
          </div>
        }
      />
    </div>
  );
};

export default UI_01_01_GA;
