import React, { useContext } from "react";
import { Action, Fab } from "./UI_09_02_Patch";
import { RootContext } from "../../common";

const UI_09_02_GA = function () {

    const { agent } = useContext(RootContext);

    return (
        <div className="tinyFab">
            <Fab alwaysShowTitle bsPrefix="string">
                <Action text="지점장에게 전화하기">
                    <a
                        href={`tel:${agent.basicInfo.mobilePhoneNumber}`}
                        title="지점장에게 전화하기"
                        className="icon icon01"
                    >
                        지점장에게 전화하기
                    </a>
                </Action>
                <Action text="업무지원센터 전화하기">
                    <a
                        href="tel:02-1899-0751"
                        title="업무지원센터 전화하기"
                        className="icon icon02"
                    >
                        업무지원센터 전화하기
                    </a>
                </Action>
                <Action text="TIP">
                    <a
                        href="https://tip.metlife.co.kr"
                        title="TIP 새창열림"
                        target="_blank"
                        className="icon icon08"
                        rel="noreferrer"
                    >
                        TIP
                    </a>
                </Action>
            </Fab>
        </div>
    );
};

export default UI_09_02_GA;
